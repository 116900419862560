<template>
    <div>
        <div class="text-center" v-if="!loadDataPage">
            <b-spinner variant="primary" label="Text Centered" />
            <h4 class="mt-1">LOADING...</h4>
        </div>

        <div v-else>
            <!-- Edit client activity -->
                <h3 class="mb-2 px-2" v-if="clinicianUsers">Client Activity <span style="color: #476282; font-size: 1.2rem;">· {{ nameClient }}</span></h3>
                <validation-observer ref="editClinicianRules">
                    <b-form
                        class="px-2"
                    >
                        <h4>Engagement</h4>
                        <hr>
                        <b-row cols="12">
                            <b-col md="8">
                                <b-col md="12" class="mb-1" v-if="activityStatus === 'Closed - Referred to OASAS Program' || activityStatus === 'External Referral' || activityStatus === 'Closed - Received information'">
                                    <b-alert
                                        variant="danger"
                                        class="mb-0"
                                        show
                                    >
                                        <div class="alert-body">
                                            <feather-icon
                                                icon="InfoIcon"
                                                class="mr-50"
                                            />
                                            Clinician and Initial Appointment Date/Time will be disabled if Activity Status is Closed - Referred to OASAS Program, External Referral or Closed - Received information.
                                        </div>
                                    </b-alert>
                                </b-col>
                                <b-col md="12" v-if="activityStatus === 'Closed - Attended First Session' || activityStatus === 'Closed - Did not attend first session'">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Clinician"
                                        rules="required"
                                    >
                                        <b-form-group
                                            label-for="Clinician"
                                        >
                                        <template v-slot:label>
                                            Clinician 
                                            <span class="text-danger">*</span>
                                        </template>
                                            <v-select
                                                v-model="clinician"
                                                placeholder="Clinician"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="optionsClinician"
                                                label="agencyAndName"
                                                multiple
                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                            />
                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                {{ errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="12" v-else-if="activityStatus !== 'Closed - Referred to OASAS Program' && activityStatus !== 'External Referral' && activityStatus !== 'Closed - Received information'">
                                    <b-form-group
                                        label-for="Clinician"
                                    >
                                    <template v-slot:label>
                                        Clinician
                                    </template>
                                        <v-select
                                            v-model="clinician"
                                            placeholder="Clinician"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :options="optionsClinician"
                                            item-text="uid"
                                            item-value="uid"
                                            name="uid"
                                            label="agencyAndName"
                                            multiple
                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        label-for="Date of Contact"
                                    >
                                    <template v-slot:label>
                                        Date of Contact <span class="text-danger">*</span>
                                    </template>
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Date of Contact"
                                            rules="required"
                                        >
                                            <flat-pickr
                                                v-model="dateOfContact"
                                                class="form-control"
                                                placeholder="Select a Date"
                                                :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'm-d-Y',}"
                                                :state="errors.length > 0 ? false:null"
                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="12">
                                    <b-form-group
                                        label="Method of Contact"
                                    >
                                        <v-select
                                            v-model="methodOfContact"
                                            placeholder="Method of Contact"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :options="optionsMethodOfContact"
                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col md="12">
                                    <b-form-group
                                        label="Caller Type"
                                    >
                                        <v-select
                                            v-model="contactStatus"
                                            placeholder="Caller Type"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :options="optionsContactStatus"
                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-row class="px-1" v-if="activityStatus === 'Closed - Attended First Session' || activityStatus === 'Closed - Did not attend first session'">
                                    <b-col md="8">
                                        <b-form-group
                                            label-for="Initial Appointment Date"
                                        >
                                        <template v-slot:label>
                                            Initial Appointment Date <span class="text-danger">*</span>
                                        </template>
                                            <validation-provider
                                                #default="{ errors }"
                                                name="Initial Appointment Date"
                                                rules="required"
                                            >
                                                <flat-pickr
                                                    v-model="initialAppointmentDate"
                                                    class="form-control"
                                                    placeholder="Initial Appointment Date"
                                                    :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'm-d-Y',}"
                                                    :state="errors.length > 0 ? false:null"
                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="4">
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Time"
                                            rules="required"
                                        >
                                            <b-form-group
                                                label-for="Time"
                                            >
                                            <template v-slot:label>
                                                Time <span class="text-danger">*</span>
                                            </template>
                                                <v-select
                                                    v-model="time"
                                                    placeholder="Time"
                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                    :options="optionsTime"
                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                />
                                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                    {{ errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                                <b-row class="px-1" v-else-if="activityStatus !== 'Closed - Referred to OASAS Program' && activityStatus !== 'External Referral' && activityStatus !== 'Closed - Received information'">
                                    <b-col md="8">
                                        <b-form-group
                                            label="Initial Appointment Date"
                                            class="pb-0"
                                        >
                                            <flat-pickr
                                                v-model="initialAppointmentDate"
                                                class="form-control pb-0"
                                                placeholder="Initial Appointment Date"
                                                :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'm-d-Y H:i',}"
                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="4">
                                        <b-form-group
                                            label="Time"
                                        >
                                            <v-select
                                                v-model="time"
                                                placeholder="Time"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="optionsTime"
                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row class="px-1">
                                    <b-col :md="referredBy === 'Family member/Self' || referredBy === 'Problem Gambling Treatment Services' || referredBy === 'Problem Gambling Self-Help Group Meetings' || referredBy === 'Substance Use Treatment Services' || referredBy === 'Recovery Support Services' || referredBy === 'Other' || referredBy === 'HOPEline' ? '6' : '12'">
                                        <!-- <validation-provider
                                            #default="{ errors }"
                                            name="How was client referred for services?"
                                            rules="required"
                                        > -->
                                            <b-form-group
                                                label-for="How was client referred for services?"
                                            >
                                            <template v-slot:label>
                                                How was client referred for services?
                                            </template>
                                                <v-select
                                                    v-model="referredBy"
                                                    placeholder="How was client referred for services?"
                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                    :options="optionsReferredBy"
                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                />
                                                <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                    {{ errors[0] }}
                                                </b-form-invalid-feedback> -->
                                            </b-form-group>
                                        <!-- </validation-provider> -->
                                    </b-col>

                                    <b-col md="6" v-if="referredBy === 'Family member/Self'">
                                        <!-- <validation-provider
                                            #default="{ errors }"
                                            name="Family member/Self"
                                            rules="required"
                                        > -->
                                            <b-form-group
                                                label-for="Family member/Self"
                                            >
                                            <template v-slot:label>
                                                <b>Family member/Self</b>
                                            </template>
                                                <v-select
                                                    v-model="familyMemberSelf"
                                                    placeholder="Family member/Self"
                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                    :options="optionsFamilyMemberSelf"
                                                    style=""
                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                />
                                                <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                    {{ errors[0] }}
                                                </b-form-invalid-feedback> -->
                                            </b-form-group>
                                        <!-- </validation-provider> -->
                                    </b-col>
                                    <b-col md="6" v-if="referredBy === 'Problem Gambling Treatment Services'">
                                        <!-- <validation-provider
                                            #default="{ errors }"
                                            name="Problem Gambling Treatment Services"
                                            rules="required"
                                        > -->
                                            <b-form-group
                                                label-for="Problem Gambling Treatment Services"
                                            >
                                            <template v-slot:label>
                                                <b>Problem Gambling Treatment Services</b>
                                            </template>
                                                <v-select
                                                    v-model="problemGamblingTreatmentServices"
                                                    placeholder="Problem Gambling Treatment Services"
                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                    :options="optionsProblemGamblingTreatmentServices"
                                                    style=""
                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                />
                                                <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                    {{ errors[0] }}
                                                </b-form-invalid-feedback> -->
                                            </b-form-group>
                                        <!-- </validation-provider> -->
                                    </b-col>
                                    <b-col md="6" v-if="referredBy === 'Problem Gambling Self-Help Group Meetings'">
                                        <!-- <validation-provider
                                            #default="{ errors }"
                                            name="Problem Gambling Self-Help Group Meetings"
                                            rules="required"
                                        > -->
                                            <b-form-group
                                                label-for="Problem Gambling Self-Help Group Meetings"
                                            >
                                            <template v-slot:label>
                                                <b>Problem Gambling Self-Help Group Meetings</b>
                                            </template>
                                                <v-select
                                                    v-model="problemGamblingSelfHelpGroupMeetings"
                                                    placeholder="Problem Gambling Self-Help Group Meetings"
                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                    :options="optionsProblemGamblingSelfHelpGroupMeetings"
                                                    style=""
                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                />
                                                <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                    {{ errors[0] }}
                                                </b-form-invalid-feedback> -->
                                            </b-form-group>
                                        <!-- </validation-provider> -->
                                    </b-col>
                                    <b-col md="6" v-if="referredBy === 'Substance Use Treatment Services'">
                                        <!-- <validation-provider
                                            #default="{ errors }"
                                            name="Substance Use Treatment Services"
                                            rules="required"
                                        > -->
                                            <b-form-group
                                                label-for="Substance Use Treatment Services"
                                            >
                                            <template v-slot:label>
                                                <b>Substance Use Treatment Services</b>
                                            </template>
                                                <v-select
                                                    v-model="substanceUseTreatmentServices"
                                                    placeholder="Substance Use Treatment Services"
                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                    :options="optionsSubstanceUseTreatmentServices"
                                                    style=""
                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                />
                                                <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                    {{ errors[0] }}
                                                </b-form-invalid-feedback> -->
                                            </b-form-group>
                                        <!-- </validation-provider> -->
                                    </b-col>
                                    <b-col md="6" v-if="referredBy === 'Recovery Support Services'">
                                        <!-- <validation-provider
                                            #default="{ errors }"
                                            name="Recovery Support Services"
                                            rules="required"
                                        > -->
                                            <b-form-group
                                                label-for="Recovery Support Services"
                                            >
                                            <template v-slot:label>
                                                <b>Recovery Support Services</b>
                                            </template>
                                                <v-select
                                                    v-model="recoverySupportServices"
                                                    placeholder="Recovery Support Services"
                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                    :options="optionsRecoverySupportServices"
                                                    style=""
                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                />
                                                <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                    {{ errors[0] }}
                                                </b-form-invalid-feedback> -->
                                            </b-form-group>
                                        <!-- </validation-provider> -->
                                    </b-col>
                                    <b-col md="6" v-if="referredBy === 'Other'">
                                        <b-form-group
                                            label-for="Other"
                                        >
                                        <template v-slot:label>
                                            <b>Other</b>
                                        </template>
                                            <!-- <validation-provider
                                                #default="{ errors }"
                                                name="Other"
                                                rules="required"
                                            > -->
                                                <b-form-textarea
                                                    placeholder="Other"
                                                    rows="3"
                                                    v-model="otherReferredBy"
                                                    style=""
                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                />
                                                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                                            <!-- </validation-provider> -->
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="6" v-if="referredBy === 'HOPEline'">
                                        <b-form-group
                                            label-for="Warm Transfer"
                                        >
                                        <template v-slot:label>
                                            <b>Warm Transfer</b>
                                        </template>
                                            <v-select
                                                v-model="warmTransfer"
                                                placeholder="Warm Transfer"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="optionsWarmTransfer"
                                                style=""
                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row class="px-1">
                                    <b-col :md="informationKitAccepted === 'Yes' ? '12' : '12'">
                                        <b-form-group
                                            label="Information Kit Accepted"
                                        >
                                            <v-select
                                                v-model="informationKitAccepted"
                                                placeholder="Information Kit Accepted"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="optionsInformationKitAccepted"
                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <!-- <b-col :md="informationKitAccepted === 'Yes' ? '6' : '12'" v-if="informationKitAccepted === 'Yes'">
                                        <b-form-group
                                            label="Information Kit Option"
                                        >
                                            <v-select
                                                v-model="informationKitOption"
                                                placeholder="Information Kit Option"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="optionsInformationKitOption"
                                                multiple
                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                            />
                                        </b-form-group>
                                    </b-col> -->
                                </b-row>
                                <b-row class="px-1">
                                    <b-col :md="regionalCFEReceivingTheCall === 'NYC' ? '6' : '12'">
                                        <b-form-group
                                            label="Regional PGRC Receiving the Call"
                                        >
                                            <v-select
                                                v-model="regionalCFEReceivingTheCall"
                                                placeholder="Regional PGRC Receiving the Call"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="optionsRegionalCFEReceivingTheCall"
                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="6" v-if="regionalCFEReceivingTheCall === 'NYC'">
                                        <b-form-group
                                            label="Borough"
                                        >
                                            <v-select
                                                v-model="borough"
                                                placeholder="Borough"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="optionsBorough"
                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-col md="12">
                                    <b-form-group
                                        label="Readmission"
                                    >
                                        <v-select
                                            v-model="readmission"
                                            placeholder="Readmission"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :options="optionsReadmission"
                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col md="12">
                                    <!-- Connected to Peer Advocate -->
                                    <b-form-group
                                        label="Connected to Peer Advocate"
                                        label-for="Connected to Peer Advocate"
                                    >
                                        <v-select
                                            v-model="connectedToPeerAdvocate"
                                            placeholder="Connected to Peer Advocate"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :options="optionsConnectedToPeerAdvocate"
                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col md="12">
                                    <!-- Connected to VSE -->
                                    <b-form-group
                                        label="Connected to VSE"
                                        label-for="Connected to VSE"
                                    >
                                        <v-select
                                            v-model="connectedToVSE"
                                            placeholder="Connected to VSE"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :options="optionsConnected"
                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col md="12">
                                    <!-- Connected to peer education group -->
                                    <b-form-group
                                        label="Connected to peer education group"
                                        label-for="Connected to peer education group"
                                    >
                                        <v-select
                                            v-model="connectedToPeerEducationGroup"
                                            placeholder="Connected to peer education group"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :options="optionsConnected"
                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col md="12">
                                    <!-- Connected to family education group -->
                                    <b-form-group
                                        label="Connected to family education group"
                                        label-for="Connected to family education group"
                                    >
                                        <v-select
                                            v-model="connectedToFamilyEducationGroup"
                                            placeholder="Connected to family education group"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :options="optionsConnected"
                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col md="12">
                                    <!-- Connected to Asian Services Program -->
                                    <b-form-group
                                        label="Connected to Asian Services Program"
                                        label-for="Connected to Asian Services Program"
                                    >
                                        <v-select
                                            v-model="connectedToAsianServicesProgram"
                                            placeholder="Connected to Asian Services Program"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :options="optionsConnected"
                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col md="12">
                                    <b-form-group
                                        label-for="In the past few months, how often have you had 4 or more alcoholic drinks (woman)/5 or more drinks (man) on one occasion?"
                                    >
                                    <template v-slot:label>
                                        In the past few months, how often have you had 4 or more alcoholic drinks (woman)/5 or more drinks (man) on one occasion?
                                    </template>
                                            <b-form-textarea
                                                placeholder="In the past few months, how often have you had 4 or more alcoholic drinks (woman)/5 or more drinks (man) on one occasion?"
                                                rows="3"
                                                v-model="inThePastFewMonthsDrinks"
                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                            />
                                    </b-form-group>
                                </b-col>

                                <b-row class="px-1" :style="referralOptions ? 'background: #f3f2f7; border: 1px solid #9b9b9b; border-radius: 10px; padding-top: 15px; padding-bottom: 15px;' : ''">
                                    <b-col :md="referralOptions ? '12' : '12'">
                                        <b-form-group
                                            label="Referral Options"
                                            label-for="Referral Options"
                                        >
                                            <v-select
                                                v-model="referralOptions"
                                                placeholder="Referral Options"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="optionsReferralOptions"
                                                style="background: #fff;"
                                                multiple
                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <div :style="referralOptions.includes('OASAS Program') && OASASProgram.length >= 1 ? 'background: rgb(219, 219, 219); border: 1px solid #9b9b9b; border-radius: 10px; padding-top: 15px; padding-bottom: 15px; width: 100%; margin-left: 50px; margin-right: 15px; margin-bottom: 20px;' : 'width: 100%; padding-left: 2.5rem !important;'">
                                        <b-col md="12" v-if="referralOptions.includes('OASAS Program')">
                                            <b-form-group
                                                label="Please select OASAS Program"
                                                label-for="OASAS Program"
                                            >
                                                <v-select
                                                    v-model="OASASProgram"
                                                    placeholder="OASAS Program"
                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                    :options="optionsOASASProgram"
                                                    style="background: #fff;"
                                                    multiple
                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                />
                                            </b-form-group>
                                        </b-col>
                                        <!-- <b-col md="6" v-if="referralOptions === 'OASAS Program' && OASASProgram">
                                        </b-col> -->
                                        <b-col md="12" v-if="referralOptions.includes('OASAS Program') && OASASProgram.includes('Inpatient')">
                                            <b-form-group
                                                label-for="Inpatient"
                                            >
                                            <template v-slot:label>
                                                Inpatient
                                            </template>
                                                    <b-form-textarea
                                                        placeholder="Inpatient"
                                                        rows="3"
                                                        v-model="OASASProgramOptInpatient"
                                                        style="background: #fff;"
                                                        :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                    />
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="12" v-if="referralOptions.includes('OASAS Program') && OASASProgram.includes('Residential')">
                                            <b-form-group
                                                label-for="Residential"
                                            >
                                            <template v-slot:label>
                                                Residential
                                            </template>
                                                    <b-form-textarea
                                                        placeholder="Residential"
                                                        rows="3"
                                                        v-model="OASASProgramOptResidential"
                                                        style="background: #fff;"
                                                        :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                    />
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="12" v-if="referralOptions.includes('OASAS Program') && OASASProgram.includes('Outpatient')">
                                            <b-form-group
                                                label-for="Outpatient"
                                            >
                                            <template v-slot:label>
                                                Outpatient
                                            </template>
                                                    <b-form-textarea
                                                        placeholder="Outpatient"
                                                        rows="3"
                                                        v-model="OASASProgramOptOutpatient"
                                                        style="background: #fff;"
                                                        :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                    />
                                            </b-form-group>
                                        </b-col>
                                    </div>

                                    <b-col md="12" v-if="referralOptions.includes('Private Practitioner')" class="pl-4">
                                        <b-form-group
                                            label-for="Private practitioner"
                                        >
                                        <template v-slot:label>
                                            Private practitioner
                                        </template>
                                                <b-form-input
                                                    placeholder="Private practitioner"
                                                    rows="3"
                                                    v-model="privatePractitioner"
                                                    style="background: #fff;"
                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                />
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="12" v-if="referralOptions.includes('Recovery Support Services')" class="pl-4">
                                        <b-form-group
                                            label="Recovery Support Services"
                                            label-for="Recovery Support Services"
                                        >
                                            <v-select
                                                v-model="recoverySupportServices"
                                                placeholder="Recovery Support Services"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="optionsRecoverySupportServices"
                                                style="background: #fff;"
                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>

                            <!-- RIGHT CONTENT -->
                            <b-col md="4">
                                <b-form-group
                                    label="Special Issues or Other Comments"
                                >
                                    <b-form-textarea
                                        id="textarea-default"
                                        placeholder="Special Issues or Other Comments"
                                        rows="3"
                                        v-model="specialIssuesOrOtherComments"
                                        :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                    />
                                </b-form-group>
                                <b-form-group
                                    label="Call Notes"
                                >
                                    <b-form-textarea
                                        id="textarea-default"
                                        placeholder="Call Notes"
                                        rows="3"
                                        v-model="callNotes"
                                        :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                    />
                                </b-form-group>

                                <!-- Activity Status -->
                                <validation-provider
                                    #default="{ errors }"
                                    name="Activity Status"
                                    rules="required"
                                >
                                    <b-form-group
                                        label-for="Activity Status"
                                        :state="errors.length > 0 ? false:null"
                                    >
                                    <template v-slot:label>
                                        Activity Status <span class="text-danger">*</span>
                                    </template>
                                        <v-select
                                            v-model="activityStatus"
                                            placeholder="Activity Status"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :options="optionsActivityStatus"
                                            :clearable="false"
                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                        />
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>

                                <b-form-group
                                    label="Close Notes"
                                    v-if="activityStatus && activityStatus.includes('Closed')"
                                >
                                    <b-form-textarea
                                        id="textarea-default"
                                        placeholder="Close Notes"
                                        rows="3"
                                        v-model="closeNotes"
                                        :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        
                        <b-row cols="12" class="mt-2">
                            <!-- Admission -->
                            <b-col md="12" v-if="activityStatus.includes('Engaged')">
                                <b-button
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    :variant="enterAdmissionInformation ? 'outline-primary' : 'outline-success'"
                                    @click="enterAdmissionInformation ? enterAdmissionInformation = false : enterAdmissionInformation = true"
                                    class="p-50"
                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                >
                                Enter Admission information
                                </b-button>
                            </b-col>

                            <b-col md="12" v-if="enterAdmissionInformation && activityStatus.includes('Engaged') || activityStatus.includes('Admitted')">
                                <b-button
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    variant="flat-danger"
                                    class="float-right p-0"
                                    v-if="!clinicianOrClinicianViewer && !removeRecordAdmissionData && !clinicianUsers"
                                    @click="removeRecordAdmission"
                                    :disabled="staffOrClinician ? true : dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                >
                                    <feather-icon
                                        icon="XIcon"
                                        class="mr-50"
                                    />
                                    <span class="align-middle">Remove Record</span>
                                </b-button>
                                <h4 class="mt-2" v-if="!removeRecordAdmissionData">Admission</h4>
                                <hr v-if="!removeRecordAdmissionData">
                                <b-row v-if="!removeRecordAdmissionData">
                                    <b-col md="8">
                                        <b-col md="12">
                                            <b-col md="6">
                                                <b-form-group
                                                    label-for="Date of Admission"
                                                >
                                                <template v-slot:label>
                                                    Date of Admission <span class="text-danger">*</span>
                                                </template>
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Date of Admission"
                                                        rules="required"
                                                    >
                                                        <flat-pickr
                                                            v-model="dateOfAdmission"
                                                            class="form-control"
                                                            placeholder="Select a Date"
                                                            :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'm-d-Y',}"
                                                            :state="errors.length > 0 ? false:null"
                                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="New or Returning Client"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="New or Returning Client"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            New or Returning Client <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="newOrReturningClient"
                                                                placeholder="New or Returning Client"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsNewOrReturningClient"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Client Status"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Client Status"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Client Status <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="clientStatusAdmission"
                                                                placeholder="Client Status"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsClientStatusAdmission"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>
                                            <b-col md="12">
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="Presenting Problems at Admission"
                                                    rules="required"
                                                >
                                                    <b-form-group
                                                        label-for="Presenting Problems at Admission"
                                                        :state="errors.length > 0 ? false:null"
                                                    >
                                                    <template v-slot:label>
                                                        Presenting Problems at Admission <span class="text-danger">*</span>
                                                    </template>
                                                        <v-select
                                                            v-model="presentingProblemsAtAdmission"
                                                            placeholder="Presenting Problems at Admission"
                                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                            :options="optionsPresentingProblemsAtAdmission"
                                                            multiple
                                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                        />
                                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                            {{ errors[0] }}
                                                        </b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-col md="12">
                                                <b-form-group
                                                    label="Other Problems"
                                                >
                                                <template v-slot:label>
                                                    Other Problems <span class="text-danger">*</span>
                                                    <feather-icon
                                                        icon="HelpCircleIcon"
                                                        size="16"
                                                        style="color: #656b85 !important;"
                                                        class="ml-75"
                                                        v-b-tooltip.hover.right="'Please write N/A if it doesn´t apply'"
                                                    />
                                                </template>
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Other Problems"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            v-model="otherProblemsAdmission"
                                                            trim
                                                            placeholder="Other Problems"
                                                            :state="errors.length > 0 ? false:null"
                                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="12">
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="Diagnostic Criteria Met at Admission"
                                                    rules="required"
                                                >
                                                    <b-form-group
                                                        label-for="Diagnostic Criteria Met at Admission"
                                                        :state="errors.length > 0 ? false:null"
                                                    >
                                                    <template v-slot:label>
                                                        Diagnostic Criteria Met at Admission <span class="text-danger">*</span>
                                                    </template>
                                                        <v-select
                                                            v-model="diagnosticCriteriaMetAtAdmission"
                                                            placeholder="Diagnostic Criteria Met at Admission"
                                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                            :options="optionsDiagnosticCriteriaMetAtAdmission"
                                                            multiple
                                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                        />
                                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                            {{ errors[0] }}
                                                        </b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Client Involved with Legal System"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Client Involved with Legal System"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Client Involved with Legal System <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="clientInvolvedWithLegalSystem"
                                                                placeholder="Client Involved with Legal System"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsClientInvolvedWithLegalSystem"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6" v-if="clientInvolvedWithLegalSystem === 'Yes'">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Legal System"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Legal System"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Legal System <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="legalSystem"
                                                                placeholder="Legal System"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsLegalSystem"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col :md="clientInvolvedWithLegalSystem === 'Yes' ? '12' : '6'">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Client Release Signed"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Client Release Signed"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Client Release Signed <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="clientReleaseSigned"
                                                                placeholder="Client Release Signed"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsClientReleaseSigned"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>

                                            <!-- race and household income -->
                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Race"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Race"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Race <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="race"
                                                                placeholder="Race"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsRace"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Hispanic Origin"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Hispanic Origin"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Hispanic Origin <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="ethnicity"
                                                                placeholder="Hispanic Origin"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsEthnicity"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>

                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Asian Origin"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Asian Origin"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Asian Origin <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="asianOrigin"
                                                                placeholder="Asian Origin"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsAsianOrigin"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Pacific Islander Origin"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Pacific Islander Origin"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Pacific Islander Origin <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="pacificIslanderOrigin"
                                                                placeholder="Pacific Islander Origin"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsPacificIslanderOrigin"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>

                                            <b-row class="px-1">
                                                <b-col md="12">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Household Income"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Household Income"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Household Income <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="householdIncome"
                                                                placeholder="Household Income"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsHouseholdIncome"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>

                                            <!-- Primary Gambling Type and Employment Status -->
                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Types of Gambling Engaged In"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Types of Gambling Engaged In"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Types of Gambling Engaged In <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="gamblingTypes"
                                                                placeholder="Types of Gambling Engaged In"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsGamblingTypes"
                                                                multiple
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Employment Status"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Employment Status"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Employment Status <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="employmentStatus"
                                                                placeholder="Employment Status"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsEmploymentStatus"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>

                                            <!-- Education Levels and Involved With Recovery Groups? -->
                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Highest Grade Completed"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Highest Grade Completed"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Highest Grade Completed <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="educationLevels"
                                                                placeholder="Highest Grade Completed"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsEducationLevels"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Veteran"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Veteran"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Veteran <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="veteran"
                                                                placeholder="Veteran"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsVeteran"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>

                                            <!-- Ethnicity and veteran  -->
                                            <!-- <b-row class="px-1">
                                            </b-row> -->

                                            <!-- Zip code and Industry of employment  -->
                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <!-- Zip code -->
                                                    <b-form-group
                                                        label-for="Zip code"
                                                    >
                                                    <template v-slot:label>
                                                        Zip code <span class="text-danger">*</span>
                                                        <feather-icon
                                                            icon="HelpCircleIcon"
                                                            size="16"
                                                            style="color: #656b85 !important;"
                                                            class="ml-75"
                                                            v-b-tooltip.hover.right="'Please write N/A if it doesn´t apply'"
                                                        />
                                                    </template>
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Zip code"
                                                            rules="required"
                                                        >
                                                            <b-form-input
                                                                v-model="zipCode"
                                                                trim
                                                                placeholder="Zip code"
                                                                :state="errors.length > 0 ? false:null"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>

                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Industry of employment"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Industry of employment"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Industry of employment <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="industryOfEmployment"
                                                                placeholder="Industry of employment"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsIndustryOfEmployment"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>

                                            <!-- Marital status and Religious preference  -->
                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Marital status"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Marital status"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Marital status <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="maritalStatus"
                                                                placeholder="Marital status"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsMaritalStatus"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Religious preference"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Religious preference"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Religious preference <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="religiousPreference"
                                                                placeholder="Religious preference"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsReligiousPreference"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>

                                            <b-row class="px-1">
                                                <b-col :md="primaryICD10 === 'Z72.6 Gambling and Betting' ? '6' : '12'">
                                                    <b-form-group
                                                        label-for="Primary ICD-10 Diagnosis Code"
                                                        v-if="clientStatusAdmission === 'Family Member'"
                                                    >
                                                    <template v-slot:label>
                                                        Primary ICD-10 Diagnosis Code
                                                    </template>
                                                        <v-select
                                                            v-model="primaryICD10"
                                                            placeholder="Primary ICD-10 Diagnosis Code"
                                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                            :options="optionsPrimaryICD10"
                                                            :disabled="true"
                                                        />
                                                    </b-form-group>
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Primary ICD-10 Diagnosis Code"
                                                        rules="required"
                                                        v-else
                                                    >
                                                        <b-form-group
                                                            label-for="Primary ICD-10 Diagnosis Code"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Primary ICD-10 Diagnosis Code <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="primaryICD10"
                                                                placeholder="Primary ICD-10 Diagnosis Code"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsPrimaryICD10"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6" v-if="primaryICD10 === 'Z72.6 Gambling and Betting'">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Z72.6 Gambling and Betting"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Z72.6 Gambling and Betting"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Z72.6 Gambling and Betting <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-textarea
                                                                v-model="Z72GamblingAndBetting"
                                                                id="textarea-default"
                                                                placeholder="Z72.6 Gambling and Betting"
                                                                rows="3"
                                                                :state="errors.length > 0 ? false:null"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>

                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <b-form-group
                                                        label="Number of Assessment Visits"
                                                    >
                                                    <template v-slot:label>
                                                        Number of Assessment Visits <span class="text-danger">*</span>
                                                    </template>
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Number of Assessment Visits"
                                                            rules="required"
                                                        >
                                                            <b-form-input
                                                                v-model="numberofAssessmentVisits"
                                                                trim
                                                                placeholder="Number of Assessment Visits"
                                                                :state="errors.length > 0 ? false:null"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>

                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Age"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Age"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Age <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="age"
                                                                placeholder="Age"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsAge"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>

                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Sexual Orientation"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Sexual Orientation"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Sexual Orientation <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="sexualOrientation"
                                                                placeholder="Sexual Orientation"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsSexualOrientation"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Gender Identity"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Gender Identity"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Gender Identity <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="genderIdentity"
                                                                placeholder="Gender Identity"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsGenderIdentity"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>

                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Transgender"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Transgender"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Transgender <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="transgender"
                                                                placeholder="Transgender"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsTransgender"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>

                                            <b-row class="px-1">
                                                <b-col :md="referredByAdmission === 'Other' || referredByAdmission === 'HOPEline' || referredByAdmission === 'Family member/Self' || referredByAdmission === 'Problem Gambling Treatment Services' || referredByAdmission === 'Substance Use Treatment Services' || referredByAdmission === 'Recovery Support Services' ? '12' : '12'">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="How was client referred for services?"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="How was client referred for services?"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            How was client referred for services? <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="referredByAdmission"
                                                                placeholder="How was client referred for services?"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsReferredBy"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>

                                            <b-row class="px-1">
                                                <b-col md="6" v-if="referredByAdmission === 'Family member/Self'">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Family member/Self"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Family member/Self"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            <b>Family member/Self</b> <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="familyMemberSelfAdmission"
                                                                placeholder="Family member/Self"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsFamilyMemberSelf"
                                                                style="border: 1px solid red; border-radius: 5px;"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6" v-if="referredByAdmission === 'Problem Gambling Treatment Services'">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Problem Gambling Treatment Services"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Problem Gambling Treatment Services"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            <b>Problem Gambling Treatment Services</b> <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="problemGamblingTreatmentServicesAdmission"
                                                                placeholder="Problem Gambling Treatment Services"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsProblemGamblingTreatmentServices"
                                                                style="border: 1px solid red; border-radius: 5px;"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6" v-if="referredByAdmission === 'Problem Gambling Self-Help Group Meetings'">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Problem Gambling Self-Help Group Meetings"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Problem Gambling Self-Help Group Meetings"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            <b>Problem Gambling Self-Help Group Meetings</b> <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="problemGamblingSelfHelpGroupMeetingsAdmission"
                                                                placeholder="Problem Gambling Self-Help Group Meetings"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsProblemGamblingSelfHelpGroupMeetings"
                                                                style="border: 1px solid red; border-radius: 5px;"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6" v-if="referredByAdmission === 'Substance Use Treatment Services'">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Substance Use Treatment Services"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Substance Use Treatment Services"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            <b>Substance Use Treatment Services</b> <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="substanceUseTreatmentServicesAdmission"
                                                                placeholder="Substance Use Treatment Services"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsSubstanceUseTreatmentServices"
                                                                style="border: 1px solid red; border-radius: 5px;"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6" v-if="referredByAdmission === 'Recovery Support Services'">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Recovery Support Services"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Recovery Support Services"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            <b>Recovery Support Services</b> <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="recoverySupportServicesAdmission"
                                                                placeholder="Recovery Support Services"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsRecoverySupportServices"
                                                                style="border: 1px solid red; border-radius: 5px;"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6" v-if="referredByAdmission === 'Other'">
                                                    <b-form-group
                                                        label-for="Other"
                                                    >
                                                    <template v-slot:label>
                                                        <b>Other</b> <span class="text-danger">*</span>
                                                    </template>
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Other"
                                                            rules="required"
                                                        >
                                                            <b-form-textarea
                                                                placeholder="Other"
                                                                rows="3"
                                                                v-model="otherReferredByAdmission"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                                style="border: 1px solid red; border-radius: 5px;"
                                                                :state="errors.length > 0 ? false:null"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col md="6" v-if="referredByAdmission === 'HOPEline'">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Warm Transfer"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Warm Transfer"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            <b>Warm Transfer</b> <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="warmTransferAdmission"
                                                                placeholder="Warm Transfer"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsWarmTransfer"
                                                                style="border: 1px solid red; border-radius: 5px;"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Intellectual Disability/Developmental Disability"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Intellectual Disability/Developmental Disability"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Intellectual Disability/Developmental Disability <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="developmentalDisability"
                                                                placeholder="Intellectual Disability/Developmental Disability"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsYesNoPreferNotToAnswer"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>

                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Co-existing Psychiatric Disorder"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Co-existing Psychiatric Disorder"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Co-existing Psychiatric Disorder <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="coexistingPsychiatricDisorder"
                                                                placeholder="Co-existing Psychiatric Disorder"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsYesNoPreferNotToAnswer"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Treated for Mental Illness"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Treated for Mental Illness"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Treated for Mental Illness <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="treatedForMentalIllness"
                                                                placeholder="Treated for Mental Illness"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsYesNoPreferNotToAnswer"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>

                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Hospitalized for Mental Illness"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Hospitalized for Mental Illness"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Hospitalized for Mental Illness <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="hospitalizedForMentalIllness"
                                                                placeholder="Hospitalized for Mental Illness"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsYesNoPreferNotToAnswer"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Hospitalized 30 or more days for Mental Illness"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Hospitalized 30 or more days for Mental Illness"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Hospitalized 30 or more days for Mental Illness <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="hospitalized30OrMoreDaysForMentalIllness"
                                                                placeholder="Hospitalized 30 or more days for Mental Illness"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsYesNoPreferNotToAnswer"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>

                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Smoked tobacco in the past week"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Smoked tobacco in the past week"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Smoked tobacco in the past week <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="smokedTobaccoInThePastWeek"
                                                                placeholder="Smoked tobacco in the past week"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsYesNoPreferNotToAnswer"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Used smokeless tobacco in the past week"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Used smokeless tobacco in the past week"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Used smokeless tobacco in the past week <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="usedSmokelessTobaccoInThePastWeek"
                                                                placeholder="Used smokeless tobacco in the past week"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsYesNoPreferNotToAnswer"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>

                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Substances Used"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Substances Used"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Substances Used <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="substancesUsed"
                                                                placeholder="Substances Used"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsSubstancesUsed"
                                                                multiple
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Substance Use Route of Administration"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Substance Use Route of Administration"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Substance Use Route of Administration <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="substanceUseRouteOfAdministration"
                                                                placeholder="Substance Use Route of Administration"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsSubstanceUseRouteOfAdministration"
                                                                multiple
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>

                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Frequency of Substance Use"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Frequency of Substance Use"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Frequency of Substance Use <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="frequencyOfSubstanceUse"
                                                                placeholder="Frequency of Substance Use"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsFrequencyOfSubstanceUse"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <b-form-group
                                                        label-for="Age of First Substance Use"
                                                    >
                                                    <template v-slot:label>
                                                        Age of First Substance Use <span class="text-danger">*</span>
                                                    </template>
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Age of First Substance Use"
                                                            rules="required"
                                                        >
                                                            <b-form-textarea
                                                                placeholder="Age of First Substance Use"
                                                                rows="3"
                                                                v-model="ageOfFirstSubstanceUse"
                                                                :state="errors.length > 0 ? false:null"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>

                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Frequency of Gambling"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Frequency of Gambling"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Frequency of Gambling <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="frequencyofGambling"
                                                                placeholder="Frequency of Gambling"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsFrequencyofGambling"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="6">
                                                    <b-form-group
                                                        label-for="Age First Gambled"
                                                    >
                                                    <template v-slot:label>
                                                        Age First Gambled <span class="text-danger">*</span>
                                                    </template>
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Age First Gambled"
                                                            rules="required"
                                                        >
                                                            <b-form-input
                                                                v-model="ageFirstGambled"
                                                                trim
                                                                placeholder="Age First Gambled"
                                                                :state="errors.length > 0 ? false:null"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>

                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <b-form-group
                                                        label-for="During the past 30 days, what amount of money did you spend on a typical day of gambling?"
                                                    >
                                                    <template v-slot:label>
                                                        During the past 30 days, what amount of money did you spend on a typical day of gambling? <span class="text-danger">*</span>
                                                    </template>
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="During the past 30 days, what amount of money did you spend on a typical day of gambling?"
                                                            rules="required"
                                                        >
                                                            <b-form-input
                                                                v-model="past30DaysAmountOfMoney"
                                                                trim
                                                                placeholder="During the past 30 days, what amount of money did you spend on a typical day of gambling?"
                                                                :state="errors.length > 0 ? false:null"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>

                                                <b-col md="6">
                                                    <b-form-group
                                                        label-for="During the past 30 days, how much time did you usually spend on a typical day of gambling?"
                                                    >
                                                    <template v-slot:label>
                                                        During the past 30 days, how much time did you usually spend on a typical day of gambling? <span class="text-danger">*</span>
                                                    </template>
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="During the past 30 days, how much time did you usually spend on a typical day of gambling?"
                                                            rules="required"
                                                        >
                                                            <b-form-input
                                                                v-model="past30DaysHowMuchTime"
                                                                trim
                                                                placeholder="During the past 30 days, how much time did you usually spend on a typical day of gambling?"
                                                                :state="errors.length > 0 ? false:null"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>

                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <b-form-group
                                                        label-for="During the past 30 days, on how many days did you gamble?"
                                                    >
                                                    <template v-slot:label>
                                                        During the past 30 days, on how many days did you gamble? <span class="text-danger">*</span>
                                                    </template>
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="During the past 30 days, on how many days did you gamble?"
                                                            rules="required"
                                                        >
                                                            <b-form-input
                                                                v-model="past30DaysHowManyDays"
                                                                trim
                                                                placeholder="During the past 30 days, on how many days did you gamble?"
                                                                :state="errors.length > 0 ? false:null"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>

                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Gambling locations during the past 12 months"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Gambling locations during the past 12 months"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Gambling locations during the past 12 months <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="gamblinglocationspast12months"
                                                                placeholder="Gambling locations during the past 12 months"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsGamblinglocationspast12months"
                                                                multiple
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>

                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Has client attended or received services for any reason from"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Has client attended or received services for any reason from"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Has client attended or received services for any reason from <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="hasClientAttendedReceivedServices"
                                                                placeholder="Has client attended or received services for any reason from"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsHasClientAttendedReceivedServices"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>

                                            <hr>

                                            <b-row class="px-1" v-if="clientStatusAdmission === 'Family Member'">
                                                <b-col md="6">
                                                    <b-form-group
                                                        label-for="LOCADTR-G Completed"
                                                    >
                                                    <template v-slot:label>
                                                        LOCADTR-G Completed
                                                    </template>
                                                        <date-picker
                                                            v-model="locadtrg"
                                                            placeholder="Select a Date"
                                                            type="date"
                                                            format="MMM DD, Y"
                                                            value-type="MM-DD-YYYY"
                                                            style="max-width: 100% !important; width: 100% !important;"
                                                            :disabled="true"
                                                        ></date-picker>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col md="6">
                                                    <b-form-group
                                                        label-for="LOCADTR-G Assessment ID"
                                                    >
                                                    <template v-slot:label>
                                                        LOCADTR-G Assessment ID
                                                    </template>
                                                        <b-form-input
                                                            v-model="LOCADTRAssessmentID"
                                                            trim
                                                            placeholder="LOCADTR-G Assessment ID"
                                                            style="cursor: not-allowed;"
                                                            :disabled="true"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row class="px-1" v-else>
                                                <b-col md="6">
                                                    <b-form-group
                                                        label-for="LOCADTR-G Completed"
                                                    >
                                                    <template v-slot:label>
                                                        LOCADTR-G Completed <span class="text-danger">*</span>
                                                    </template>
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="LOCADTR-G Completed"
                                                            rules="required"
                                                        >
                                                            <date-picker
                                                                v-model="locadtrg"
                                                                placeholder="Select a Date"
                                                                type="date"
                                                                format="MMM DD, Y"
                                                                value-type="MM-DD-YYYY"
                                                                style="max-width: 100% !important; width: 100% !important;"
                                                                :state="errors.length > 0 ? false:null"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            ></date-picker>
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col md="6">
                                                    <b-form-group
                                                        label-for="LOCADTR-G Assessment ID"
                                                    >
                                                    <template v-slot:label>
                                                        LOCADTR-G Assessment ID <span class="text-danger">*</span>
                                                    </template>
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="LOCADTR-G Assessment ID"
                                                            rules="required"
                                                        >
                                                            <b-form-input
                                                                v-model="LOCADTRAssessmentID"
                                                                trim
                                                                placeholder="LOCADTR-G Assessment ID"
                                                                :state="errors.length > 0 ? false:null"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-col>

                                    <!-- RIGHT CONTENT -->
                                    <b-col md="4">
                                        <b-form-group
                                            label="Notes"
                                        >
                                            <b-form-textarea
                                                id="textarea-default"
                                                placeholder="Notes"
                                                rows="3"
                                                v-model="notesAdmission"
                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>

                        <b-row cols="12" class="mt-2">
                            <!-- Discharge -->
                            <b-col md="12" v-if="activityStatus.includes('Engaged')">
                                <b-button
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    :variant="enterDischargeInformation ? 'outline-primary' : 'outline-success'"
                                    @click="enterDischargeInformation ? enterDischargeInformation = false : enterDischargeInformation = true"
                                    class="p-50"
                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                >
                                Enter Discharge information
                                </b-button>
                            </b-col>

                            <b-col md="12" v-if="enterDischargeInformation && activityStatus.includes('Engaged') || activityStatus.includes('Discharged')">
                                <b-button
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    variant="flat-danger"
                                    class="float-right p-0 mt-2"
                                    v-if="!clinicianOrClinicianViewer && !removeRecordDischargeData && !clinicianUsers"
                                    @click="removeRecordDischarge"
                                    :disabled="staffOrClinician ? true : dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                >
                                    <feather-icon
                                        icon="XIcon"
                                        class="mr-50"
                                    />
                                    <span class="align-middle">Remove Record</span>
                                </b-button>
                                <h4 class="mt-2" v-if="!removeRecordDischargeData">Discharge</h4>
                                <hr v-if="!removeRecordDischargeData">
                                <b-row v-if="!removeRecordDischargeData">
                                    <b-col md="8">
                                        <b-col md="12">
                                            <b-col md="6">
                                                <b-form-group
                                                    label-for="Date of Discharge"
                                                >
                                                <template v-slot:label>
                                                    Date of Discharge <span class="text-danger">*</span>
                                                </template>
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Date of Discharge"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            v-model="dateOfDischarge"
                                                            :state="errors.length > 0 ? false:null"
                                                            placeholder="Select a Date"
                                                            class="form-control"
                                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            v-if="dateOfDischarge === 'N/A'"
                                                        />
                                                        <flat-pickr
                                                            v-model="dateOfDischarge"
                                                            class="form-control"
                                                            placeholder="Select a Date"
                                                            :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'm-d-Y', allowInput: true, onKeyDown: (selectedDates, dateStr, instance, event) => {
                                                                filtChange(event)
                                                            }}"
                                                            :state="errors.length > 0 ? false:null"
                                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            v-else
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>

                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Discharge Status"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Discharge Status"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Discharge Status <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="dischargeStatus"
                                                                placeholder="Discharge Status"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsDischargeStatus"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6" v-if="dischargeStatus">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Planned discharge reasons"
                                                        rules="required"
                                                        v-if="dischargeStatus === 'Planned'"
                                                    >
                                                        <b-form-group
                                                            label-for="Planned discharge reasons"
                                                            v-if="dischargeStatus === 'Planned'"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Planned discharge reasons <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="plannedDischargeReasons"
                                                                placeholder="Planned discharge reasons"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsPlannedDischargeReasons"
                                                                multiple
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>

                                                    <b-form-group
                                                        label-for="Unplanned discharge reason"
                                                        v-else-if="dischargeStatus === 'Dropout'"
                                                    >
                                                    <template v-slot:label>
                                                        Unplanned discharge reason <span class="text-danger">*</span>
                                                    </template>
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Unplanned discharge reason"
                                                            rules="required"
                                                        >
                                                            <b-form-textarea
                                                                placeholder="Unplanned discharge reason"
                                                                rows="3"
                                                                v-model="unplannedDischargeReason"
                                                                :state="errors.length > 0 ? false:null"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-col md="12">
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="Discharge Disposition"
                                                    rules="required"
                                                >
                                                    <b-form-group
                                                        label-for="Discharge Disposition"
                                                        :state="errors.length > 0 ? false:null"
                                                    >
                                                    <template v-slot:label>
                                                        Discharge Disposition <span class="text-danger">*</span>
                                                    </template>
                                                        <v-select
                                                            v-model="dischargeDisposition"
                                                            placeholder="Discharge Disposition"
                                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                            :options="optionsDischargeDisposition"
                                                            multiple
                                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                        />
                                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                            {{ errors[0] }}
                                                        </b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-col md="12">
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="Referral Disposition"
                                                    rules="required"
                                                >
                                                    <b-form-group
                                                        label-for="Referral Disposition"
                                                        :state="errors.length > 0 ? false:null"
                                                    >
                                                    <template v-slot:label>
                                                        Referral Disposition <span class="text-danger">*</span>
                                                    </template>
                                                        <v-select
                                                            v-model="referralDisposition"
                                                            placeholder="Referral Disposition"
                                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                            :options="optionsReferralDisposition"
                                                            multiple
                                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                        />
                                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                            {{ errors[0] }}
                                                        </b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-col md="12">
                                                <b-form-group
                                                    label-for="Number of Sessions Attended"
                                                >
                                                <template v-slot:label>
                                                    Number of Sessions Attended <span class="text-danger">*</span>
                                                </template>
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Number of Sessions Attended"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            v-model="numberOfSessionsAttended"
                                                            :state="errors.length > 0 ? false:null"
                                                            placeholder="Number of Sessions Attended"
                                                            class="form-control"
                                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            v-if="optionsStringsNumberSessions.includes(numberOfSessionsAttended)"
                                                        />
                                                        <cleave
                                                            v-model="numberOfSessionsAttended"
                                                            class="form-control"
                                                            :raw="false"
                                                            :options="options.block"
                                                            placeholder="Number of Sessions Attended"
                                                            :state="errors.length > 0 ? false:null"
                                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            v-else
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="12">
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="Diagnostic Criteria Met at Admission was Reduced"
                                                    rules="required"
                                                >
                                                    <b-form-group
                                                        label-for="Diagnostic Criteria Met at Admission was Reduced"
                                                        :state="errors.length > 0 ? false:null"
                                                    >
                                                    <template v-slot:label>
                                                        Diagnostic Criteria Met at Admission was Reduced <span class="text-danger">*</span>
                                                    </template>
                                                        <v-select
                                                            v-model="diagnosticCriteriaMetAtAdmissionWasReduced"
                                                            placeholder="Diagnostic Criteria Met at Admission was Reduced"
                                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                            :options="optionsDiagnosticCriteriaMetAtAdmissionWasReduced"
                                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                        />
                                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                            {{ errors[0] }}
                                                        </b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-col md="12" v-if="diagnosticCriteriaMetAtAdmissionWasReduced === 'Yes'">
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="Diagnostic Criteria Met at Discharge"
                                                    rules="required"
                                                >
                                                    <b-form-group
                                                        label-for="Diagnostic Criteria Met at Discharge"
                                                        :state="errors.length > 0 ? false:null"
                                                    >
                                                    <template v-slot:label>
                                                        Diagnostic Criteria Met at Discharge <span class="text-danger">*</span>
                                                    </template>
                                                        <v-select
                                                            v-model="diagnosticCriteriaMetAtDischarge"
                                                            placeholder="Diagnostic Criteria Met at Discharge"
                                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                            :options="optionsDiagnosticCriteriaMetAtAdmission"
                                                            multiple
                                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                        />
                                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                            {{ errors[0] }}
                                                        </b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-col md="12">
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="Current Gambling Compared to Admission"
                                                    rules="required"
                                                >
                                                    <b-form-group
                                                        label-for="Current Gambling Compared to Admission"
                                                        :state="errors.length > 0 ? false:null"
                                                    >
                                                    <template v-slot:label>
                                                        Current Gambling Compared to Admission <span class="text-danger">*</span>
                                                    </template>
                                                        <v-select
                                                            v-model="currentGamblingComparedToAdmission"
                                                            placeholder="Current Gambling Compared to Admission"
                                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                            :options="optionsCurrentGamblingComparedToAdmission"
                                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                        />
                                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                            {{ errors[0] }}
                                                        </b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-col md="12">
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="Client Involved with Self-Help at Discharge"
                                                    rules="required"
                                                >
                                                    <b-form-group
                                                        label-for="Client Involved with Self-Help at Discharge"
                                                        :state="errors.length > 0 ? false:null"
                                                    >
                                                    <template v-slot:label>
                                                        Client Involved with Self-Help at Discharge <span class="text-danger">*</span>
                                                    </template>
                                                        <v-select
                                                            v-model="clientInvolvedWithSelfHelpAtDischarge"
                                                            placeholder="Client Involved with Self-Help at Discharge"
                                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                            :options="optionsClientInvolvedWithSelfHelpAtDischarge"
                                                            :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                        />
                                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                            {{ errors[0] }}
                                                        </b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-row class="px-1">
                                                <b-col :md="clientReferredForContinuedServices === 'Problem Gambling Treatment Services' || clientReferredForContinuedServices === 'Substance Use Treatment Services' || clientReferredForContinuedServices === 'Recovery Support Services' || clientReferredForContinuedServices === 'Other' ? '6' : '12'">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Client Referred for Continued Services"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Client Referred for Continued Services"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Client Referred for Continued Services <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="clientReferredForContinuedServices"
                                                                placeholder="Client Referred for Continued Services"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsClientReferredForContinuedServices"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col :md="'6'" v-if="clientReferredForContinuedServices === 'Problem Gambling Treatment Services'">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Problem Gambling Treatment Services"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Problem Gambling Treatment Services"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Problem Gambling Treatment Services <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="problemGamblingTreatmentServices"
                                                                placeholder="Problem Gambling Treatment Services"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsProblemGamblingTreatmentServices"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col :md="'6'" v-if="clientReferredForContinuedServices === 'Substance Use Treatment Services'">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Substance Use Treatment Services"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Substance Use Treatment Services"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Substance Use Treatment Services <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="substanceUseTreatmentServices"
                                                                placeholder="Substance Use Treatment Services"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsSubstanceUseTreatmentServices"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col :md="'6'" v-if="clientReferredForContinuedServices === 'Recovery Support Services'">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Recovery Support Services"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Recovery Support Services"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Recovery Support Services <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="recoverySupportServices"
                                                                placeholder="Recovery Support Services"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsRecoverySupportServices"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6" v-if="clientReferredForContinuedServices === 'Other'">
                                                    <b-form-group
                                                        label-for="Other (please describe)"
                                                    >
                                                    <template v-slot:label>
                                                        Other (please describe) <span class="text-danger">*</span>
                                                    </template>
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Other Problems"
                                                            rules="required"
                                                        >
                                                            <b-form-input
                                                                v-model="otherClientReferredforContinuedServices"
                                                                placeholder="please describe"
                                                                trim
                                                                :state="errors.length > 0 ? false:null"
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>

                                                <!-- <b-col :md="'6'" v-if="clientReferredForContinuedServices === 'Yes'">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="If so, what services were recommended"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="If so, what services were recommended"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            If so, what services were recommended <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="ifSoWhatServicesWereRecommended"
                                                                placeholder="If so, what services were recommended"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsIfSoWhatServicesWereRecommended"
                                                                multiple
                                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col> -->
                                            </b-row>

                                            <b-row class="px-1">
                                                <h6 class="mt-2">Status of Gambling Related Problems</h6>
                                                <hr style="width: 100%;">
                                                <b-row class="px-1">
                                                    <b-col :md="'6'">
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Gambling"
                                                            rules="required"
                                                        >
                                                            <b-form-group
                                                                label-for="Gambling"
                                                                :state="errors.length > 0 ? false:null"
                                                            >
                                                            <template v-slot:label>
                                                                Gambling <span class="text-danger">*</span>
                                                            </template>
                                                                <v-select
                                                                    v-model="relatedProblemsGambling"
                                                                    placeholder="Gambling"
                                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                    :options="optionsGamblingRelatedProblems"
                                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                                />
                                                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                    {{ errors[0] }}
                                                                </b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    </b-col>
                                                    <b-col :md="'6'">
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Drug Use"
                                                            rules="required"
                                                        >
                                                            <b-form-group
                                                                label-for="Drug Use"
                                                                :state="errors.length > 0 ? false:null"
                                                            >
                                                            <template v-slot:label>
                                                                Drug Use <span class="text-danger">*</span>
                                                            </template>
                                                                <v-select
                                                                    v-model="relatedProblemsDrugUse"
                                                                    placeholder="Drug Use"
                                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                    :options="optionsGamblingRelatedProblems"
                                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                                />
                                                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                    {{ errors[0] }}
                                                                </b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    </b-col>
                                                    <b-col :md="'6'">
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Alcohol Use"
                                                            rules="required"
                                                        >
                                                            <b-form-group
                                                                label-for="Alcohol Use"
                                                                :state="errors.length > 0 ? false:null"
                                                            >
                                                            <template v-slot:label>
                                                                Alcohol Use <span class="text-danger">*</span>
                                                            </template>
                                                                <v-select
                                                                    v-model="relatedProblemsAlcoholUse"
                                                                    placeholder="Alcohol Use"
                                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                    :options="optionsGamblingRelatedProblems"
                                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                                />
                                                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                    {{ errors[0] }}
                                                                </b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    </b-col>
                                                    <b-col :md="'6'">
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Financial"
                                                            rules="required"
                                                        >
                                                            <b-form-group
                                                                label-for="Financial"
                                                                :state="errors.length > 0 ? false:null"
                                                            >
                                                            <template v-slot:label>
                                                                Financial <span class="text-danger">*</span>
                                                            </template>
                                                                <v-select
                                                                    v-model="relatedProblemsFinancial"
                                                                    placeholder="Financial"
                                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                    :options="optionsGamblingRelatedProblems"
                                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                                />
                                                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                    {{ errors[0] }}
                                                                </b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    </b-col>
                                                    <b-col :md="'6'">
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Legal"
                                                            rules="required"
                                                        >
                                                            <b-form-group
                                                                label-for="Legal"
                                                                :state="errors.length > 0 ? false:null"
                                                            >
                                                            <template v-slot:label>
                                                                Legal <span class="text-danger">*</span>
                                                            </template>
                                                                <v-select
                                                                    v-model="relatedProblemsLegal"
                                                                    placeholder="Legal"
                                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                    :options="optionsGamblingRelatedProblems"
                                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                                />
                                                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                    {{ errors[0] }}
                                                                </b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    </b-col>
                                                    <b-col :md="'6'">
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Vocational/Educational"
                                                            rules="required"
                                                        >
                                                            <b-form-group
                                                                label-for="Vocational/Educational"
                                                                :state="errors.length > 0 ? false:null"
                                                            >
                                                            <template v-slot:label>
                                                                Vocational/Educational <span class="text-danger">*</span>
                                                            </template>
                                                                <v-select
                                                                    v-model="relatedProblemsVocationalEducational"
                                                                    placeholder="Vocational/Educational"
                                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                    :options="optionsGamblingRelatedProblems"
                                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                                />
                                                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                    {{ errors[0] }}
                                                                </b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    </b-col>
                                                    <b-col :md="'6'">
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Medical/Health"
                                                            rules="required"
                                                        >
                                                            <b-form-group
                                                                label-for="Medical/Health"
                                                                :state="errors.length > 0 ? false:null"
                                                            >
                                                            <template v-slot:label>
                                                                Medical/Health <span class="text-danger">*</span>
                                                            </template>
                                                                <v-select
                                                                    v-model="relatedProblemsMedicalHealth"
                                                                    placeholder="Medical/Health"
                                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                    :options="optionsGamblingRelatedProblems"
                                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                                />
                                                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                    {{ errors[0] }}
                                                                </b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    </b-col>
                                                    <b-col :md="'6'">
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Lethality: Self"
                                                            rules="required"
                                                        >
                                                            <b-form-group
                                                                label-for="Lethality: Self"
                                                                :state="errors.length > 0 ? false:null"
                                                            >
                                                            <template v-slot:label>
                                                                Lethality: Self <span class="text-danger">*</span>
                                                            </template>
                                                                <v-select
                                                                    v-model="relatedProblemsLethalitySelf"
                                                                    placeholder="Lethality: Self"
                                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                    :options="optionsGamblingRelatedProblems"
                                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                                />
                                                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                    {{ errors[0] }}
                                                                </b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    </b-col>
                                                    <b-col :md="'6'">
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Lethality: Other"
                                                            rules="required"
                                                        >
                                                            <b-form-group
                                                                label-for="Lethality: Other"
                                                                :state="errors.length > 0 ? false:null"
                                                            >
                                                            <template v-slot:label>
                                                                Lethality: Other <span class="text-danger">*</span>
                                                            </template>
                                                                <v-select
                                                                    v-model="relatedProblemsLethalityOther"
                                                                    placeholder="Lethality: Other"
                                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                    :options="optionsGamblingRelatedProblems"
                                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                                />
                                                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                    {{ errors[0] }}
                                                                </b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    </b-col>
                                                    <b-col :md="'6'">
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Mental Health"
                                                            rules="required"
                                                        >
                                                            <b-form-group
                                                                label-for="Mental Health"
                                                                :state="errors.length > 0 ? false:null"
                                                            >
                                                            <template v-slot:label>
                                                                Mental Health <span class="text-danger">*</span>
                                                            </template>
                                                                <v-select
                                                                    v-model="relatedProblemsMentalHealth"
                                                                    placeholder="Mental Health"
                                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                    :options="optionsGamblingRelatedProblems"
                                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                                />
                                                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                    {{ errors[0] }}
                                                                </b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    </b-col>
                                                    <b-col :md="'6'">
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Family"
                                                            rules="required"
                                                        >
                                                            <b-form-group
                                                                label-for="Family"
                                                                :state="errors.length > 0 ? false:null"
                                                            >
                                                            <template v-slot:label>
                                                                Family <span class="text-danger">*</span>
                                                            </template>
                                                                <v-select
                                                                    v-model="relatedProblemsFamily"
                                                                    placeholder="Family"
                                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                    :options="optionsGamblingRelatedProblems"
                                                                    :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                                />
                                                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                    {{ errors[0] }}
                                                                </b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    </b-col>
                                                </b-row>
                                            </b-row>
                                        </b-col>
                                    </b-col>

                                    <!-- RIGHT CONTENT -->
                                    <b-col md="4">
                                        <b-form-group
                                            label="Notes"
                                        >
                                            <b-form-textarea
                                                id="textarea-default"
                                                placeholder="Notes"
                                                rows="3"
                                                v-model="notesDischarge"
                                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>

                        <b-row cols="12" class="mt-2">
                            <!-- Payment/Insurance Utilization Form -->
                            <b-col md="12" v-if="activityStatus.includes('Engaged')">
                                <b-button
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    :variant="enterSurveyInformation ? 'outline-primary' : 'outline-success'"
                                    @click="enterSurveyInformation ? enterSurveyInformation = false : enterSurveyInformation = true"
                                    class="p-50"
                                    :disabled="clinicianUsers ? false : isClinicianStaff || !adminOrManageUsers ? true : dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                >
                                Enter Payment/Insurance Utilization Form
                                </b-button>
                            </b-col>

                            <b-col md="12" v-if="enterSurveyInformation && activityStatus.includes('Engaged') || activityStatus.includes('Admitted') || activityStatus.includes('Discharged')">
                                <h4 class="mt-2">Payment/Insurance Utilization Form</h4>
                                <hr>
                                <b-form
                                    ref="formRepeaterSessionsEng"
                                    class="repeater-form"
                                    @submit.prevent="repeateAgain"
                                >
                                    <b-row style="display: block;">
                                        <b-button
                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                            variant="outline-primary"
                                            class="mb-1 p-50"
                                            style="float: right; margin-right: 40px;"
                                            @click="repeateAgain"
                                            :disabled="clinicianUsers ? false : dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                        >
                                            <feather-icon
                                                icon="PlusIcon"
                                                class=""
                                            />
                                        </b-button>
                                        <b-col
                                            md="10"
                                            v-for="(item, index) in itemsFormRepeater"
                                            :id="item.id"
                                            :key="item.id"
                                            ref="row"
                                        >
                                            <b-row class="px-1">
                                                <b-col md="6">
                                                    <b-form-group
                                                        label-for="Payment Assessment Date"
                                                    >
                                                    <template v-slot:label>
                                                        Payment Assessment Date <span class="text-danger">*</span>
                                                    </template>
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="Payment Assessment Date"
                                                            rules="required"
                                                        >
                                                            <date-picker
                                                                v-model="item.paymentAssessmentDate"
                                                                placeholder="Select a Date"
                                                                type="date"
                                                                format="MMM DD, Y"
                                                                value-type="MM-DD-YYYY"
                                                                style="max-width: 100% !important; width: 100% !important;"
                                                                :state="errors.length > 0 ? false:null"
                                                                :style="!validFormRepeater && !item.paymentAssessmentDate ? 'border: 1px solid #ea5455 !important; border-radius: 0.357rem !important; max-width: 100% !important; width: 100% !important;' : 'max-width: 100% !important; width: 100% !important;'"
                                                                :disabled="clinicianUsers ? false : dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            ></date-picker>
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row class="px-1">
                                                <b-col md="12" class="mt-2 mb-75">
                                                    <span>Select the payment arrangement you and client have decided to utilize for treatment services. A text box is provided for a brief explanation. Should the payment arrangement need to be changed at any point in the treatment process, please update this form and include an explanation in the text box. A financial assessment should be completed every six months as part of the treatment process.</span>
                                                </b-col>
                                            </b-row>
                                            <b-row class="px-1">
                                                <b-col md="7" class="mt-2">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="Payment Arrangement"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="Payment Arrangement"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            Payment Arrangement <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="item.paymentArrangement"
                                                                placeholder="Payment Arrangement"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                :options="optionsPaymentArrangement"
                                                                class="select-utilization-form"
                                                                :style="!validFormRepeater && !item.paymentArrangement ? 'border: 1px solid #ea5455 !important; border-radius: 0.357rem !important;' : ''"
                                                                :disabled="clinicianUsers ? false : dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="5" class="mt-2" v-if="item.paymentArrangement && item.paymentArrangement.includes('The client has insurance that I do not accept. I was provided with this referral due to the circumstances selected below. NYCPG will provide reimbursement.')">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="The client has insurance that I do not accept"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            label-for="The client has insurance that I do not accept"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            The client has insurance that I do not accept <span class="text-danger">*</span>
                                                        </template>
                                                            <v-select
                                                                v-model="item.theClientInsuranceIdonotAccept"
                                                                placeholder="The client has insurance that I do not accept"
                                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                                class="select-utilization-form"
                                                                :options="optionsTheClientInsuranceIdonotAccept"
                                                                multiple
                                                                :style="!validFormRepeater && !item.theClientInsuranceIdonotAccept.length ? 'border: 1px solid #ea5455 !important; border-radius: 0.357rem !important;' : ''"
                                                                :disabled="clinicianUsers ? false : dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                                {{ errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="5" class="mt-2" v-if="conditionsPArrangement.filter(ele => ele.cond === item.paymentArrangement).length">
                                                    <validation-provider
                                                        v-for="(itemCondition, index) in conditionsPArrangement.filter(ele => ele.cond === item.paymentArrangement)"
                                                        :key="index"
                                                        #default="{ errors }"
                                                        :name="itemCondition.label"
                                                        rules="required"
                                                    >
                                                        <b-form-group
                                                            :label-for="itemCondition.label"
                                                            :state="errors.length > 0 ? false:null"
                                                        >
                                                        <template v-slot:label>
                                                            {{ itemCondition.label }} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-textarea
                                                                v-model="item.explanation"
                                                                id="textarea-default"
                                                                :placeholder="itemCondition.label"
                                                                rows="3"
                                                                :state="errors.length > 0 ? false:null"
                                                                :style="!item.explanation ? 'border: 1px solid #ea5455 !important; border-radius: 0.357rem !important;' : ''"
                                                                :disabled="clinicianUsers ? false : dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12" class="mt-2 mb-2">
                                                    <b-form-checkbox
                                                        v-model="item.providingReimbursement"
                                                        :value="true"
                                                        class="custom-control-primary"
                                                        :disabled="clinicianUsers ? false : dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                    >
                                                    <b>(Check only if NYCPG will be providing reimbursement) I have worked with this client to determine financial payment for treatment and have determined that it is clinically appropriate to use NYS OASAS funding as a payor of last resort for services.</b>
                                                    </b-form-checkbox>
                                                </b-col>
                                                <!-- Remove Button -->
                                                <b-col
                                                    cols="12"
                                                    class="mb-1"
                                                >
                                                    <b-button
                                                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                                        variant="outline-danger"
                                                        class="mt-0 mt-md-2"
                                                        @click="removeItem(index)"
                                                        :disabled="staffOrClinician ? true : clinicianUsers ? false : dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                                                    >
                                                        <feather-icon
                                                            icon="XIcon"
                                                            class="mr-25"
                                                        />
                                                        <span>Delete</span>
                                                    </b-button>
                                                </b-col>
                                                <b-col cols="12" style="" class="mt-2 mb-2">
                                                    <hr>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-col>
                        </b-row>

                        <download-csv
                            :data="json_data"
                            class="mt-2 ml-1 btn btn-default"
                            name="nycpg.csv"
                            style="background: #94221e; color: #fff;"
                            v-if="dataSpecialist"
                        >
                            CSV Export
                        </download-csv>

                        <!-- Form Actions -->
                        <div class="d-flex mt-2 float-right">
                            <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="flat-primary"
                                @click="backList"
                            >
                            Back to list
                            </b-button>
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="mr-2"
                                type="submit"
                                @click.prevent="validationForm"
                                v-if="(clinicianUsers && $route.name === 'edit-client')"
                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                            >
                            Save
                            </b-button>
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="mr-2"
                                type="submit"
                                @click.prevent="validationForm"
                                v-else-if="!clinicianOrClinicianViewer"
                                :disabled="dataSpecialist ? true : staffOrClinician && $route.name === 'edit-clinician' ? true : !clinicianUsers ? false : clinicianUsers && $route.name === 'edit-client' ? false : true"
                            >
                            Save
                            </b-button>
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="mr-2"
                                type="submit"
                                @click.prevent="validationForm"
                                v-else-if="clinicianUsers"
                                :disabled="false"
                            >
                            Save
                            </b-button>
                        </div>
                    </b-form>
                </validation-observer>

            <b-popover
                ref="popover"
                target="popover-reactive-1"
                triggers="click"
                :show.sync="popoverShowDischargeDate"
                placement="auto"
                container="my-container"
                @show="onShowDischargeDate"
                @hidden="onHiddenDischargeDate"
            >
                <div class="mb-1">
                    <span>
                        Please clic N/A if it doesn´t apply
                    </span>
                </div>
                <div>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        size="sm"
                        variant="danger"
                        class="mr-1"
                        @click="onCloseDischargeDate"
                    >
                    Cancel
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        size="sm"
                        variant="primary"
                        @click="onOkDischargeDate"
                    >
                    N/A
                    </b-button>
                </div>
            </b-popover>
            <b-popover
                ref="popover"
                target="popover-reactive-2"
                triggers="click"
                :show.sync="popoverShow"
                placement="auto"
                container="my-container"
                @show="onShow"
                @hidden="onHidden"
            >
                <div class="mb-1">
                    <span>
                        Please clic N/A if it doesn´t apply
                    </span>
                </div>
                <div>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        size="sm"
                        variant="danger"
                        class="mr-1"
                        @click="onClose"
                    >
                    Cancel
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        size="sm"
                        variant="primary"
                        @click="onOk"
                    >
                    N/A
                    </b-button>
                </div>
            </b-popover>
        </div>
    </div>
    <!-- </div> -->
</template>

<script>
import { BForm, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BTable, BFormInvalidFeedback, BFormCheckbox, BAlert, BSpinner, VBTooltip, BPopover, } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import { required, email } from '@validations'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapState, mapActions } from 'vuex'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            // validation
            required,
            email,
            options: {
                block: {
                    numeral: true,
                    numeralThousandsGroupStyle: 'false',
                    delimiter: ''
                },
            },
            userData: '',
            clinicianUsers: false,
            viewClinicianCLienActivity: false,
            clinicianOrClinicianViewer: false,
            staffOrClinician: false,
            dataSpecialist: false,
            adminOrManageUsers: false,
            loadDataPage: false,
            client: '',
            nameClient: '',
            requiredDischargeForm: false,
            popoverShow: false,
            popoverShowDischargeDate: false,
            isClinicianStaff: false,

            // configuration remove record admission/discharge
            removeRecordAdmissionData: false,
            removeRecordDischargeData: false,

            // settings table and edit view
            statusEditClientActivity: true,
            pushEditButton: false,
            itemsActivity: [],
            fieldsTable: [
                { key: 'statusLogical', label: 'status', sortable: false },
                { key: 'contactDate', sortable: false },
                { key: 'admissionDate', sortable: false },
                { key: 'dischargeDate', sortable: false },
                { key: 'actions', sortable: false },
            ],

            // form
            clinician: [],
            dateOfContact: null,
            methodOfContact: '',
            contactStatus: '',
            referredBy: '',
            otherReferredBy: '',
            warmTransfer: '',
            initialAppointmentDate: null,
            time: '',
            wasTheCallHelpful: '',
            wasTheCallHelpfulIfNotWhy: '',
            informationKitAccepted: '',
            informationKitOption: '',
            regionalCFEReceivingTheCall: '',
            borough: '',
            readmission: '',
            race: '',
            householdIncome: '',
            gamblingTypes: '',
            employmentStatus: '',
            educationLevels: '',

            ethnicity: '',
            veteran: '',
            zipCode: '',
            industryOfEmployment: '',
            maritalStatus: '',
            religiousPreference: '',
            connectedToPeerAdvocate: '',
            connectedToVSE: '',
            connectedToPeerEducationGroup: '',
            connectedToFamilyEducationGroup: '',
            connectedToAsianServicesProgram: '',
            // enter adminission/discharge information
            enterAdmissionDischargeInformation: false,
            enterAdmissionInformation: false,
            enterDischargeInformation: false,
            enterSurveyInformation: false,
            // admission form
            dateOfAdmission: null,
            newOrReturningClient: '',
            clientStatusAdmission: '',
            presentingProblemsAtAdmission: '',
            diagnosticCriteriaMetAtAdmission: '',
            otherProblemsAdmission: '',
            clientInvolvedWithLegalSystem: '',
            clientReleaseSigned: '',
            referredByAdmission: '',
            familyMemberSelfAdmission: '',
            problemGamblingTreatmentServicesAdmission: '',
            problemGamblingSelfHelpGroupMeetingsAdmission: '',
            substanceUseTreatmentServicesAdmission: '',
            recoverySupportServicesAdmission: '',
            otherReferredByAdmission: '',
            warmTransferAdmission: '',
            locadtrg: '',
            LOCADTRAssessmentID: '',
            dateOfBirthAdmission: '',
            sexAtBirth: '',
            last4SSN: '',
            firstTwoLettersLastName: '',

            frequencyofGambling: '',
            ageFirstGambled: '',
            past30DaysAmountOfMoney: '',
            past30DaysHowMuchTime: '',
            past30DaysHowManyDays: '',
            gamblinglocationspast12months: '',
            hasClientAttendedReceivedServices: '',
            legalSystem: '',

            notesAdmission: '',
            // discharge form
            dateOfDischarge: null,
            dischargeStatus: '',
            plannedDischargeReasons: '',
            unplannedDischargeReason: '',
            numberOfSessionsAttended: null,
            diagnosticCriteriaMetAtAdmissionWasReduced: '',
            diagnosticCriteriaMetAtDischarge: '',
            goalsMet: '',
            currentGamblingComparedToAdmission: '',
            clientInvolvedWithSelfHelpAtDischarge: '',
            clientReferredForContinuedServices: '',
            ifSoWhatServicesWereRecommended: '',
            relatedProblemsGambling: '',
            relatedProblemsDrugUse: '',
            relatedProblemsAlcoholUse: '',
            relatedProblemsFinancial: '',
            relatedProblemsLegal: '',
            relatedProblemsVocationalEducational: '',
            relatedProblemsMedicalHealth: '',
            relatedProblemsLethalitySelf: '',
            relatedProblemsLethalityOther: '',
            relatedProblemsMentalHealth: '',
            relatedProblemsFamily: '',
            notesDischarge: '',
            // survey
            isClientInsured: '',
            isClientMatchedToaClinicianWhoAcceptsTheirInsurance: '',
            isTheClinicianBillingTheClientsInsurance: '',
            whyInsuranceWasNotBilled: '',
            whyInsuranceWasNotBilledOther: '',
            reasonWhyClientWasReferredToClinician: '',
            reasonWhyClientWasReferredToClinicianOther: '',

            // content right
            specialIssuesOrOtherComments: '',
            callNotes: '',
            activityStatus: '',
            closeNotes: '',

            // "contactDate","admissionDate","dischargeDate","clientId","clinicianId","clinicianEngagementId","callWasHelpful",
            // "callWasNotHelpfulReason","comments","dateCreated","eligibleForServices","externalReferenceLocation","informationKitAccepted",
            // "initialAppointmentDate","notes","referencedExternally","referredByOther","clientStatusCloseNotes","readmission","clientStatusId",
            // "clientStatus","contactMethodId","callerTypeId","referredByTypeId","regionId"
            json_data: [],

            // get data from db
            optionsClient: [],
            optionsClinician: [],
            optionsRace: [],
            optionsHouseholdIncome: [],
            optionsGamblingTypes: [],
            optionsEmploymentStatus: [],
            optionsEducationLevels: [],
            
            optionsClientStatusAdmission: [
                'Problem Gambler',
                'Family Member'
            ],
            optionsGamblingRelatedProblems: [
                'Achieved',
                'Partial Achievement',
                'Not Achieved',
                'Not Applicable'
            ],
            optionsFrequencyofGambling: [
                'None in the last 30 days',
                '1 - 3 times in the last 30 days',
                '1 - 2 times per week',
                '3 - 6 times per week',
                'Daily',
                'Prefer not to answer'
            ],
            optionsGamblinglocationspast12months: [
                'None',
                'Casino',
                'Race track',
                'Grocery/Convenience Store',
                'Internet',
                'Off-Track Betting (OTB)',
                'Bookie',
                'Bar/Restaurant',
                'Work',
                'School',
                'Home',
                'Church',
                'Community Site',
                'Other',
                'Prefer not to answer'
            ],
            optionsHasClientAttendedReceivedServices: [
                'Self-Help',
                'Other Gambling Program',
                'Financial and/or Credit Counseling Service',
                'N/A',
                'Prefer not to answer'
            ],
            optionsLegalSystem: [
                'Pre-Court Sentence (no Alternative to Incarceration)',
                'Pre-Court Sentence (Alternative to Incarceration)',
                'Probation (no Alternative to Incarceration)',
                'Probation (Alternative to Incarceration)',
                'Correctional-based setting',
                'Post-correctional supervision',
                'Other Alternative to Incarceration'
            ],
            optionsMethodOfContact: ['Call', 'Email', 'Text'],
            optionsContactStatus: ['Family Member', 'Other', 'Problem Gambler'],
            optionsReferredBy: [
                // 'Existing or past CFE client',
                // 'Family member/Self',
                // 'GA/GamAnon',
                // 'HOPEline',
                // 'Internet Search',
                // 'Legal System',
                // 'Friends or Other Individual',
                // 'Problem Gambling Self-Help Group Meetings',
                // 'Substance Use Self-Help Group Meetings',
                // 'Financial Counseling',
                // 'Crisis Services',
                // 'Problem Gambling Treatment Services',
                // 'Substance Use Treatment Services',
                // 'Recovery Support Services',
                // 'Other service provider',
                // 'Other (please describe)'

                // 'Self-Referral',
                // 'Spouse',
                // 'Family, Friends. Other Individual',
                // 'Problem Gambling Self-Help Group Meetings',
                // 'Substance Use Self-Help Group Meetings',
                // 'HOPEline',
                // 'Financial Counseling',
                // 'Crisis Services',
                // 'Problem Gambling Treatment Services',
                // 'Substance Use Treatment Services',
                // 'Recovery Support Services',
                // 'Other'

                'HOPEline',
                'Family member/Self',
                'Friends or Other Individual',
                'Problem Gambling Self-Help Group Meetings',
                'Substance Use Self-Help Group Meetings',
                'Financial Counseling',
                'Crisis Services',
                'Problem Gambling Treatment Services',
                'Substance Use Treatment Services',
                'Recovery Support Services',
                'Other'
            ],
            familyMemberSelf: '',
            optionsFamilyMemberSelf: [
                'Self-Referral',
                'Spouse',
                'Family member'
            ],
            problemGamblingSelfHelpGroupMeetings: '',
            optionsProblemGamblingSelfHelpGroupMeetings: [
                'GA',
                'GamAnon',
                'Smart Recovery',
                'Other'
            ],
            problemGamblingTreatmentServices: '',
            optionsProblemGamblingTreatmentServices: [
                'Inpatient Rehabilitation',
                'Residential',
                'Outpatient'
            ],
            substanceUseTreatmentServices: '',
            optionsSubstanceUseTreatmentServices: [
                'Inpatient Rehabilitation',
                'Residential',
                'Outpatient'
            ],
            recoverySupportServices: '',
            optionsRecoverySupportServices: [
                'Recovery Community and Outreach Center',
                'Youth Clubhouse',
                'Peer Advocate',
                'Open Access Center',
                'Family Navigator',
                'Regional Addiction Resource Center'
            ],
            optionsWarmTransfer: ['Yes', 'No'],
            optionsTime: [
                '12:00 AM', '12:15 AM', '12:30 AM', '12:45 AM',
                '01:00 AM', '01:15 AM', '01:30 AM', '01:45 AM',
                '02:00 AM', '02:15 AM', '02:30 AM', '02:45 AM',
                '03:00 AM', '03:15 AM', '03:30 AM', '03:45 AM',
                '04:00 AM', '04:15 AM', '04:30 AM', '04:45 AM',
                '05:00 AM', '05:15 AM', '05:30 AM', '05:45 AM',
                '06:00 AM', '06:15 AM', '06:30 AM', '06:45 AM',
                '07:00 AM', '07:15 AM', '07:30 AM', '07:45 AM',
                '08:00 AM', '08:15 AM', '08:30 AM', '08:45 AM',
                '09:00 AM', '09:15 AM', '09:30 AM', '09:45 AM',
                '10:00 AM', '10:15 AM', '10:30 AM', '10:45 AM',
                '11:00 AM', '11:15 AM', '11:30 AM', '11:45 AM',
                '12:00 PM', '12:15 PM', '12:30 PM', '12:45 PM',
                '01:00 PM', '01:15 PM', '01:30 PM', '01:45 PM',
                '02:00 PM', '02:15 PM', '02:30 PM', '02:45 PM',
                '03:00 PM', '03:15 PM', '03:30 PM', '03:45 PM',
                '04:00 PM', '04:15 PM', '04:30 PM', '04:45 PM',
                '05:00 PM', '05:15 PM', '05:30 PM', '05:45 PM',
                '06:00 PM', '06:15 PM', '06:30 PM', '06:45 PM',
                '07:00 PM', '07:15 PM', '07:30 PM', '07:45 PM',
                '08:00 PM', '08:15 PM', '08:30 PM', '08:45 PM',
                '09:00 PM', '09:15 PM', '09:30 PM', '09:45 PM',
                '10:00 PM', '10:15 PM', '10:30 PM', '10:45 PM',
                '11:00 PM', '11:15 PM', '11:30 PM', '11:45 PM',
            ],
            optionsWasTheCallHelpful: ['Yes', 'No'],
            optionsInformationKitAccepted: ['Yes', 'No'],
            optionsInformationKitOption: [
                'Client Resources Link',
                'Online Gambling Blocker',
                'GA, GamAnon',
                'Smart Recovery',
                'VSE',
                'Financial counseling'
            ],
            optionsRegionalCFEReceivingTheCall: [],
            optionsBorough: [
                'Bronx',
                'Brooklyn',
                'Manhattan',
                'Queens',
                'Staten Island'
            ],
            // optionsRegionalCFEReceivingTheCall: [
            //     'Bronx',
            //     'Brooklyn',
            //     'Central',
            //     'Finger Lakes',
            //     'Long Island',
            //     'Manhattan',
            //     'Mid-Hudson',
            //     'Northeast',
            //     'Queens',
            //     'Staten Island',
            //     'Western'
            // ],
            optionsReadmission: ['Yes', 'No'],

            optionsEthnicity: [
                // 'Hispanic',
                // 'Not Hispanic',
                // 'N/A'
                'Cuban',
                'Mexican',
                'Puerto Rican',
                'Hispanic/Latino/a/x, Not Specified',
                'Not of Hispanic Origin',
                'Prefer not to answer'
            ],
            optionsVeteran: ['Yes', 'No', 'N/A'],
            optionsIndustryOfEmployment: [
                'Business',
                'Computer',
                'Telecommunications',
                'Financial Services',
                'Manufacturing',
                'Wholesale/Retail/Distribution',
                'Transportation',
                'Travel/Hospitality',
                'Government',
                'Military',
                'Aerospace',
                'Health Care/Medical',
                'Insurance/Legal',
                'Education',
                'Utilities',
                'Architecture/Construction/Real Estate',
                'Agriculture',
                'Religious',
                'Social Services',
                'Sales',
                'Gambling Industry',
                'None',
                'N/A'
            ],
            optionsMaritalStatus: [
                'Married',
                'Never married',
                'Living as married',
                'Separated',
                'Divorced',
                'Widowed',
                'N/A'
            ],
            optionsReligiousPreference: [
                'Catholic',
                'Protestant',
                'Other Christian',
                'Jewish',
                'Muslim',
                'Buddhist',
                'Atheist/Agnostic',
                'No preference',
                'Other',
                'N/A'
            ],
            primaryICD10: '',
            Z72GamblingAndBetting: '',
            numberofAssessmentVisits: '',
            age: '',
            sexualOrientation: '',
            genderIdentity: '',
            transgender: '',
            asianOrigin: '',
            pacificIslanderOrigin: '',
            developmentalDisability: '',
            coexistingPsychiatricDisorder: '',
            treatedForMentalIllness: '',
            hospitalizedForMentalIllness: '',
            hospitalized30OrMoreDaysForMentalIllness: '',
            smokedTobaccoInThePastWeek: '',
            usedSmokelessTobaccoInThePastWeek: '',
            substancesUsed: '',
            substanceUseRouteOfAdministration: '',
            frequencyOfSubstanceUse: '',
            ageOfFirstSubstanceUse: '',
            inThePastFewMonthsDrinks: '',
            dischargeDisposition: '',
            referralDisposition: '',
            optionsDischargeDisposition: [
                'Additional treatment at this level of care no longer necessary',
                'Further treatment at this level of care unlikely to yield additional clinical gains',
                'Left against clinical advice, Formal referral made/offered',
                'Left against clinical advice, Lost contact, No referral possible',
                'Left against clinical advice, Termination of third party funds',
                'Discharged due to non-compliance, Program Rules',
                'Discharged due to non-compliance, Violence',
                'Discharged due to non-compliance, Possession of contraband',
                'Client arrested/incarcerated',
                'Client could no longer participate for medical/psychiatric reasons',
                'Client death',
                'Client relocated',
                'Program closed',
            ],
            optionsReferralDisposition: [
                'No referral made',
                'Client not in need of additional services 12',
                'Client referred back to SUD program',
                'Client referred to other SUD program',
                'Client referred to mental health program',
                'Client referred to other problem gambling program',
                'Client refused referral',
            ],
            optionsPrimaryICD10: [
                'F 63.0 Pathological Gambling',
                'Z72.6 Gambling and Betting'
            ],
            optionsAge: [
                'Under 18',
                '18 - 24',
                '25 - 34',
                '35 - 44',
                '45 - 54',
                '55 - 64',
                '65+',
                'Prefer not to answer'
            ],
            optionsSexualOrientation: [
                'Bisexual',
                'Lesbian or Gay',
                'Pansexual',
                'Straight',
                'I use a different term',
                'Questioning/Unsure',
                'Prefer not to answer'
            ],
            optionsGenderIdentity: [
                'Intersex',
                'Man',
                'Non-Binary',
                'Two-Spirit',
                'Woman',
                'I use a different term',
                'Questioning/Unsure',
                'Prefer not to answer'
            ],
            optionsTransgender: [
                'Yes',
                'No',
                'Questioning/Unsure',
                'Prefer not to answer'
            ],
            optionsAsianOrigin: [
                'Asian Indian',
                'Bangladeshi',
                'Burmese',
                'Cambodian',
                'Chinese',
                'Filipino',
                'Hmong',
                'Indonesian',
                'Japanese',
                'Korean',
                'Laotian',
                'Malaysian',
                'Nepalese',
                'Pakistani',
                'Sri Lankan',
                'Taiwanese',
                'Thai',
                'Tibetan',
                'Vietnamese',
                'Asian, Not Specified',
                'Not of Asian Origin',
                'Prefer not to answer'
            ],
            optionsPacificIslanderOrigin: [
                'Fijian',
                'Guamanian',
                'Hawaiian',
                'Samoan',
                'Tongan',
                'Pacific Islander, Not Specified',
                'Not of Pacific Islander Origin',
                'Prefer not to answer'
            ],
            // optionsHowWasClientReferredForServices: [
            //     'Self-Referral',
            //     'Spouse',
            //     'Family, Friends. Other Individual',
            //     'Problem Gambling Self-Help Group Meetings',
            //     'Substance Use Self-Help Group Meetings',
            //     'HOPEline',
            //     'Financial Counseling',
            //     'Crisis Services',
            //     'Problem Gambling Treatment Services',
            //     'Substance Use Treatment Services',
            //     'Recovery Support Services',
            //     'Other'
            // ],
            optionsYesNoPreferNotToAnswer: [
                'No',
                'Yes',
                'Prefer not to answer'
            ],
            optionsSubstancesUsed: [
                'None',
                'Prefer not to answer',
                'Alcohol',
                'Cocaine',
                'Crack',
                'Marijuana/Hashish',
                'Heroin',
                'Buprenorphine',
                'Non-Rx Methadone',
                'OxyContin',
                'Other Opiate/Synthetic',
                'Alprazolam (Xanax)',
                'Barbiturate',
                'Benzodiazepine (Klonopin)',
                'Catapres (Clonidine)',
                'Other Sedative/Hypnotic',
                'Elavil',
                'GHB',
                'Khat',
                'Other Tranquilizer',
                'Methamphetamine',
                'Other Amphetamine',
                'Other Stimulant',
                'PCP',
                'Ecstasy',
                'Other Hallucinogen',
                'Ephedrine',
                'Inhalant',
                'Ketamine',
                'Rohypnol',
                'Over the counter',
                'Other'
            ],
            optionsSubstanceUseRouteOfAdministration: [
                'N/A',
                'Prefer not to answer',
                'Inhalation',
                'Injection',
                'Oral',
                'Smoking',
                'Vaping',
                'Other'
            ],
            optionsFrequencyOfSubstanceUse: [
                'N/A',
                'Prefer not to answer',
                'No use in past 30 days',
                '1 - 3 times in the past 30 days',
                '1 - 2 times per week',
                '3 - 6 times per week',
                'Daily'
            ],
            optionsConnectedToPeerAdvocate: ['Yes','No'],

            optionsActivityStatus: [
                'Engaged',
                'Closed - Attended First Session',
                'Closed - Did not attend first session',
                'Closed - Received information',
                'Closed - Referred to OASAS Program',
                'External Referral',
                'On Hold'
            ],

            // admission options
            optionsNewOrReturningClient: [
                'Returning Client',
                'New Client',
                'N/A'
            ],
            optionsPresentingProblemsAtAdmission: [
                'Co-occurring mental health disorders',
                'Co-occurring substance use disorders',
                'Employment difficulties',
                'Financial difficulties',
                'Physical health problems',
                'Relationship difficulties',
                'Education difficulties',
                'Marital difficulties',
                'Bankruptcy',
                'Borrowing or theft from family/friends',
                'Losing savings/retirement',
                'Significant debt',
                'Embezzlement',
                'Suicidal Ideation/Thoughts/Attempts',
                'Legal Issues',
                'Arrest',
                'Incarceration',
                'N/A'
            ],
            optionsDiagnosticCriteriaMetAtAdmission: [
                'N/A',
                'After losing money gambling, often returns another day to get even ("chasing" ones losses)',
                'Has jeopardized or lost a significant relationship, job, or educational or career opportunity because of gambling.',
                'Has made repeated unsuccesful efforts to control, cut back, or stop gambling',
                'Is often preoccupied with (e.g., having persistent thoughts of reliving past gambling experiences, handicapping or planning the next venture, thinking of ways to get money with which to gamble).',
                'Is restless or irritable when attempting to cut down or stop gambling.',
                'Lies to conceal the extent of involvement with gambling.',
                'Often gambles when feeling distressed (e.g., helpless, guilty, anxious, depressed).',
                'Needs to gamble with increasing amounts of money in order to achieve the desired excitement.',
                'Relies on others to provide money to relieve desperate financial situations caused by gambling.'
            ],
            optionsClientInvolvedWithLegalSystem: ['Yes', 'No', 'N/A'],
            optionsClientReleaseSigned: ['Yes', 'No', 'N/A'],
            
            // discharge options
            optionsStringsDischargeDate: ['n/a', 'N/A'],
            optionsStringsNumberSessions: [null, '', 'n/a', 'N/A', 'N', 'n', 'N/', 'n/'],
            optionsDischargeStatus: [
                'Completed Treatment, All Goals Met',
                'Completed Treatment, Half or More Goals Met',
                'Treatment Not Completed, Max Benefit/Clinical Decision',
                'Treatment Not Completed, Some Goals Met',
                'Treatment Not Completed, No Goals Met'
            ],
            // if DischargeStatus is Planned
            optionsPlannedDischargeReasons: [
                'Barriers to accessing services',
                'Client not ready for treatment',
                'Client reports improvement',
                'Completed treatment process (as determined by Clinician)',
                'Mandatory evaluation completed',
                'Other'
            ],
            optionsDiagnosticCriteriaMetAtAdmissionWasReduced: ['N/A','Yes', 'No'],
            optionsGoalsMet: ['N/A','Achieved','Not Achieved','Partially Achieved'],
            optionsCurrentGamblingComparedToAdmission: [
                'N/A',
                'No longer gambling',
                'Reduced gambling',
                'Same gambling',
                'Worse gambling'
            ],
            optionsClientInvolvedWithSelfHelpAtDischarge: ['Yes', 'No', 'N/A'],
            optionsClientReferredForContinuedServices: [
                'Problem Gambling Self-Help Group Meetings',
                'Substance Use Self-Help Group Meetings',
                'Financial Counseling',
                'Crisis Services',
                'Problem Gambling Treatment Services',
                'Substance Use Treatment Services',
                'Recovery Support Services',
                'Other'
            ],
            problemGamblingTreatmentServices: '',
            optionsProblemGamblingTreatmentServices: [
                'Inpatient Rehabilitation',
                'Residential',
                'Outpatient'
            ],
            substanceUseTreatmentServices: '',
            optionsSubstanceUseTreatmentServices: [
                'Inpatient Rehabilitation',
                'Residential',
                'Outpatient'
            ],
            recoverySupportServices: '',
            optionsRecoverySupportServices: [
                'Recovery Community and Outreach Center',
                'Youth Clubhouse',
                'Peer Advocate',
                'Open Access Center',
                'Family Navigator',
                'Regional Addiction Resource Center'
            ],
            otherClientReferredforContinuedServices: '',
            optionssexAtBirth: [
                'Male',
                'Female'
            ],

            optionsIfSoWhatServicesWereRecommended: [
                'GA',
                'GamAnon',
                'Smart Recovery',
                'Other Mutual Aid Support',
                'NYCPG Maintenance of Alumni Group',
                '1:1 Peer Support',
                'VSE'
            ],
            optionsConnected: ['Yes', 'No'],
            optionsYesNoSurvey: ['Yes', 'No'],
            optionsWhyInsuranceWasNotBilled: [
                'Cost of deductible',
                'Doesn’t want employer to find out',
                'Doesn’t want family member to find out',
                'Other'
            ],
            optionsReasonWhyClientWasReferredToClinician: [
                'Language',
                'Distance',
                'Religion',
                'Culture',
                'Gender',
                'Remote care services',
                'Fear/Stigma',
                'Availability',
                'Client requested specific clinician',
                'Other'
            ],
            nextTodoId: 1,
            validFormRepeater: true,
            itemsFormRepeater: [],
            optionsPaymentArrangement: [
                // 'I will bill the client’s insurance and the client will provide the required copay. NYCPG will not provide reimbursement.',
                // 'The client is uninsured. NYCPG will provide reimbursement.',
                // 'The client is uninsured and will utilize sliding scale. NYCPG will provide reimbursement.',
                // 'The client has insurance, but there is a high deductible and the client is unable to pay. NYCPG will provide reimbursement.',
                // 'The client has insurance, but is not able to provide the required copay. NYCPG will provide reimbursement.',
                // 'I will not bill the client’s insurance at the request of the client. NYCPG will provide reimbursement.',
                // 'The client has insurance that I do not accept. I was provided with this referral due to the circumstances selected below. NYCPG will provide reimbursement.',
                // 'The client will utilize sliding scale for reasons not specified on this form. NYCPG will provide reimbursement.'
            ],
            conditionsPArrangement: [],
            optionsTheClientInsuranceIdonotAccept: [
                'Language preference',
                'Religious preference',
                'Cultural preference',
                'Clinician specialty area',
                'Client requested specific clinician',
                'Other'
            ],

            // edit client activity
            // statusEdit: false,
            // idClientActivity: '',
            activityStatusAtomic: '',
            counterChangesActivityStatus: 0,
            referralOptions: '',
            recoverySupportServices: '',
            optionsReferralOptions: [],
            optionsRecoverySupportServices: [],
            OASASProgram: '',
            optionsOASASProgram: [
                'Inpatient',
                'Residential',
                'Outpatient'
            ],
            OASASProgramOptInpatient: '',
            OASASProgramOptResidential: '',
            OASASProgramOptOutpatient: '',
            privatePractitioner: '',

            // survey condition
            logicisClientInsured: false,
            logicisClientMatchedToaClinicianWhoAcceptsTheirInsurance: false,
            logicisTheClinicianBillingTheClientsInsurance: false,
            logicisTheClinicianBillingTheClientsInsuranceOptionYes: false,

            // date of dicharge listener
            dodListener: '',
        }
    },
    components: {
        BForm,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BTable,
        BFormInvalidFeedback,
        BFormCheckbox,
        BAlert,
        BSpinner,
        BPopover,
        Cleave,
        
        // select
        vSelect,

        // date
        flatPickr,
        DatePicker,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    mixins: [heightTransition],
    props: ['idClientActivity'],
    computed: {
        ...mapState(['usuario'])
    },
    watch: {
        // enterAdmissionDischargeInformation(val) {
        //     console.log(val)
        // },
        client(val) {
            // console.log(val)
            if(val) {
                db.collection('clients').doc(val).get()
                .then(item => {
                    this.nameClient = item.data().firstName + ' ' + item.data().lastName
                })
            }
        },
        idClientActivity(val) {
            this.statusEditClientActivity = true
            this.dodListener = ''
            db.collection('clientsActivity').doc(val).get()
            .then(dataClient => {
                db.collection('usuarios').get()
                .then(docsClinicians => {
                    // db.collection('clients').get()
                    // .then(docsClients => {
                        // get clinician
                        var objClinician = []
                        if(dataClient.data().clinician.length >= 1) {
                            dataClient.data().clinician.forEach(activityClinician => {
                                docsClinicians.forEach(item => {
                                    if(activityClinician === item.id) {
                                        var obj = item.data()
                                        obj.agencyAndName = item.data().firstName + ' ' + item.data().lastName + ' · ' + item.data().agency  + ' (#' + item.data().codigo + ')'
                                        objClinician.push(obj)
                                    }
                                })
                            })
                        }
                        // console.log('third')

                        // get client
                        var objClient = dataClient.data().client
                        // console.log(dataClient.data().client)
                        // if(dataClient.data().client) {
                        //     docsClients.forEach(item => {
                        //         if(dataClient.data().client === item.id) {
                        //             objClient = item.data()
                        //         }
                        //     })
                        // }

                        // get data
                        this.clinician = objClinician
                        this.dateOfContact = dataClient.data().dateOfContact
                        this.methodOfContact = dataClient.data().methodOfContact
                        this.contactStatus = dataClient.data().contactStatus
                        this.referredBy = dataClient.data().referredBy
                        this.familyMemberSelf = dataClient.data().familyMemberSelf ? dataClient.data().familyMemberSelf : ''
                        this.problemGamblingSelfHelpGroupMeetings = dataClient.data().problemGamblingSelfHelpGroupMeetings ? dataClient.data().problemGamblingSelfHelpGroupMeetings : ''
                        this.problemGamblingTreatmentServices = dataClient.data().problemGamblingTreatmentServices ? dataClient.data().problemGamblingTreatmentServices : ''
                        this.substanceUseTreatmentServices = dataClient.data().substanceUseTreatmentServices ? dataClient.data().substanceUseTreatmentServices : ''
                        this.recoverySupportServices = dataClient.data().recoverySupportServices ? dataClient.data().recoverySupportServices : ''
                        this.otherReferredBy = dataClient.data().otherReferredBy
                        this.warmTransfer = dataClient.data().warmTransfer ? dataClient.data().warmTransfer : ''
                        this.initialAppointmentDate = dataClient.data().initialAppointmentDate
                        this.time = dataClient.data().time
                        this.wasTheCallHelpful = dataClient.data().wasTheCallHelpful
                        this.wasTheCallHelpfulIfNotWhy = dataClient.datA().wasTheCallHelpfulIfNotWhy ? dataClient.data().wasTheCallHelpfulIfNotWhy : ''
                        this.informationKitAccepted = dataClient.data().informationKitAccepted
                        this.informationKitOption = dataClient.data().informationKitOption ? dataClient.data().informationKitOption : ''
                        this.regionalCFEReceivingTheCall = dataClient.data().regionalCFEReceivingTheCall
                        this.borough = dataClient.data().borough ? dataClient.data().borough : ''
                        this.readmission = dataClient.data().readmission
                        this.race = dataClient.data().race ? dataClient.data().race : ''
                        this.householdIncome = dataClient.data().householdIncome ? dataClient.data().householdIncome : ''
                        this.gamblingTypes = dataClient.data().gamblingTypes ? dataClient.data().gamblingTypes : ''
                        this.employmentStatus = dataClient.data().employmentStatus ? dataClient.data().employmentStatus : ''
                        this.educationLevels = dataClient.data().educationLevels ? dataClient.data().educationLevels : ''
                        this.ethnicity = dataClient.data().ethnicity ? dataClient.data().ethnicity : ''
                        this.veteran = dataClient.data().veteran ? dataClient.data().veteran : ''
                        this.zipCode = dataClient.data().zipCode ? dataClient.data().zipCode : ''
                        this.industryOfEmployment = dataClient.data().industryOfEmployment ? dataClient.data().industryOfEmployment : ''
                        this.maritalStatus = dataClient.data().maritalStatus ? dataClient.data().maritalStatus : ''
                        this.religiousPreference = dataClient.data().religiousPreference ? dataClient.data().religiousPreference : ''
                        this.connectedToPeerAdvocate = dataClient.data().connectedToPeerAdvocate ? dataClient.data().connectedToPeerAdvocate : ''
                        this.connectedToVSE = dataClient.data().connectedToVSE ? dataClient.data().connectedToVSE : ''
                        this.connectedToPeerEducationGroup = dataClient.data().connectedToPeerEducationGroup ? dataClient.data().connectedToPeerEducationGroup : ''
                        this.connectedToFamilyEducationGroup = dataClient.data().connectedToFamilyEducationGroup ? dataClient.data().connectedToFamilyEducationGroup : ''
                        this.connectedToAsianServicesProgram = dataClient.data().connectedToAsianServicesProgram ? dataClient.data().connectedToAsianServicesProgram : ''
                        // enter adminission/discharge information
                        this.enterAdmissionDischargeInformation = dataClient.data().enterAdmissionDischargeInformation
                        this.enterAdmissionInformation = dataClient.data().enterAdmissionInformation === undefined ? dataClient.data().enterAdmissionDischargeInformation : dataClient.data().enterAdmissionInformation ? dataClient.data().enterAdmissionInformation : false
                        this.enterDischargeInformation = dataClient.data().enterDischargeInformation === undefined ? dataClient.data().enterAdmissionDischargeInformation : dataClient.data().enterDischargeInformation ? dataClient.data().enterDischargeInformation : false
                        this.enterSurveyInformation = dataClient.data().enterSurveyInformation ? dataClient.data().enterSurveyInformation : false
                        // admission form
                        this.dateOfAdmission = dataClient.data().dateOfAdmission
                        this.newOrReturningClient = dataClient.data().newOrReturningClient
                        this.clientStatusAdmission = dataClient.data().clientStatusAdmission ? dataClient.data().clientStatusAdmission : ''
                        this.presentingProblemsAtAdmission = dataClient.data().presentingProblemsAtAdmission
                        this.diagnosticCriteriaMetAtAdmission = dataClient.data().diagnosticCriteriaMetAtAdmission
                        this.otherProblemsAdmission = dataClient.data().otherProblemsAdmission
                        this.clientInvolvedWithLegalSystem = dataClient.data().clientInvolvedWithLegalSystem
                        this.clientReleaseSigned = dataClient.data().clientReleaseSigned
                        this.referredByAdmission = dataClient.data().referredByAdmission ? dataClient.data().referredByAdmission : ''
                        this.familyMemberSelfAdmission = dataClient.data().familyMemberSelfAdmission ? dataClient.data().familyMemberSelfAdmission : ''
                        this.problemGamblingTreatmentServicesAdmission = dataClient.data().problemGamblingTreatmentServicesAdmission ? dataClient.data().problemGamblingTreatmentServicesAdmission : ''
                        this.problemGamblingSelfHelpGroupMeetingsAdmission = dataClient.data().problemGamblingSelfHelpGroupMeetingsAdmission ? dataClient.data().problemGamblingSelfHelpGroupMeetingsAdmission : ''
                        this.substanceUseTreatmentServicesAdmission = dataClient.data().substanceUseTreatmentServicesAdmission ? dataClient.data().substanceUseTreatmentServicesAdmission : ''
                        this.recoverySupportServicesAdmission = dataClient.data().recoverySupportServicesAdmission ? dataClient.data().recoverySupportServicesAdmission : ''
                        this.otherReferredByAdmission = dataClient.data().otherReferredByAdmission ? dataClient.data().otherReferredByAdmission : ''
                        this.warmTransferAdmission = dataClient.data().warmTransferAdmission ? dataClient.data().warmTransferAdmission : ''
                        this.primaryICD10 = dataClient.data().primaryICD10 ? dataClient.data().primaryICD10 : ''
                        this.Z72GamblingAndBetting = dataClient.data().Z72GamblingAndBetting ? dataClient.data().Z72GamblingAndBetting : ''
                        this.numberofAssessmentVisits = dataClient.data().numberofAssessmentVisits ? dataClient.data().numberofAssessmentVisits : ''
                        this.age = dataClient.data().age ? dataClient.data().age : ''
                        this.sexualOrientation = dataClient.data().sexualOrientation ? dataClient.data().sexualOrientation : ''
                        this.genderIdentity = dataClient.data().genderIdentity ? dataClient.data().genderIdentity : ''
                        this.transgender = dataClient.data().transgender ? dataClient.data().transgender : ''
                        this.asianOrigin = dataClient.data().asianOrigin ? dataClient.data().asianOrigin : ''
                        this.pacificIslanderOrigin = dataClient.data().pacificIslanderOrigin ? dataClient.data().pacificIslanderOrigin : ''
                        this.developmentalDisability = dataClient.data().developmentalDisability ? dataClient.data().developmentalDisability : ''
                        this.coexistingPsychiatricDisorder = dataClient.data().coexistingPsychiatricDisorder ? dataClient.data().coexistingPsychiatricDisorder : ''
                        this.treatedForMentalIllness = dataClient.data().treatedForMentalIllness ? dataClient.data().treatedForMentalIllness : ''
                        this.hospitalizedForMentalIllness = dataClient.data().hospitalizedForMentalIllness ? dataClient.data().hospitalizedForMentalIllness : ''
                        this.hospitalized30OrMoreDaysForMentalIllness = dataClient.data().hospitalized30OrMoreDaysForMentalIllness ? dataClient.data().hospitalized30OrMoreDaysForMentalIllness : ''
                        this.smokedTobaccoInThePastWeek = dataClient.data().smokedTobaccoInThePastWeek ? dataClient.data().smokedTobaccoInThePastWeek : ''
                        this.usedSmokelessTobaccoInThePastWeek = dataClient.data().usedSmokelessTobaccoInThePastWeek ? dataClient.data().usedSmokelessTobaccoInThePastWeek : ''
                        this.substancesUsed = dataClient.data().substancesUsed ? dataClient.data().substancesUsed : ''
                        this.substanceUseRouteOfAdministration = dataClient.data().substanceUseRouteOfAdministration ? dataClient.data().substanceUseRouteOfAdministration : ''
                        this.frequencyOfSubstanceUse = dataClient.data().frequencyOfSubstanceUse ? dataClient.data().frequencyOfSubstanceUse : ''
                        this.ageOfFirstSubstanceUse = dataClient.data().ageOfFirstSubstanceUse ? dataClient.data().ageOfFirstSubstanceUse : ''
                        this.inThePastFewMonthsDrinks = dataClient.data().inThePastFewMonthsDrinks ? dataClient.data().inThePastFewMonthsDrinks : ''
                        this.referralOptions = dataClient.data().referralOptions ? dataClient.data().referralOptions : ''
                        this.OASASProgram = dataClient.data().OASASProgram ? dataClient.data().OASASProgram : ''
                        this.OASASProgramOptInpatient = dataClient.data().OASASProgramOptInpatient ? dataClient.data().OASASProgramOptInpatient : ''
                        this.OASASProgramOptResidential = dataClient.data().OASASProgramOptResidential ? dataClient.data().OASASProgramOptResidential : ''
                        this.OASASProgramOptOutpatient = dataClient.data().OASASProgramOptOutpatient ? dataClient.data().OASASProgramOptOutpatient : ''
                        this.privatePractitioner = dataClient.data().privatePractitioner ? dataClient.data().privatePractitioner : ''
                        this.recoverySupportServices = dataClient.data().recoverySupportServices ? dataClient.data().recoverySupportServices : ''
                        this.locadtrg = dataClient.data().locadtrg ? dataClient.data().locadtrg : ''
                        this.LOCADTRAssessmentID = dataClient.data().LOCADTRAssessmentID ? dataClient.data().LOCADTRAssessmentID : ''
                        this.dateOfBirthAdmission = dataClient.data().dateOfBirthAdmission ? dataClient.data().dateOfBirthAdmission : ''
                        this.sexAtBirth = dataClient.data().sexAtBirth ? dataClient.data().sexAtBirth : ''
                        this.last4SSN = dataClient.data().last4SSN ? dataClient.data().last4SSN : ''
                        this.firstTwoLettersLastName = dataClient.data().firstTwoLettersLastName ? dataClient.data().firstTwoLettersLastName : ''
                        this.frequencyofGambling = dataClient.data().frequencyofGambling ? dataClient.data().frequencyofGambling : ''
                        this.ageFirstGambled = dataClient.data().ageFirstGambled ? dataClient.data().ageFirstGambled : ''
                        this.past30DaysAmountOfMoney = dataClient.data().past30DaysAmountOfMoney ? dataClient.data().past30DaysAmountOfMoney : ''
                        this.past30DaysHowMuchTime = dataClient.data().past30DaysHowMuchTime ? dataClient.data().past30DaysHowMuchTime : ''
                        this.past30DaysHowManyDays = dataClient.data().past30DaysHowManyDays ? dataClient.data().past30DaysHowManyDays : ''
                        this.gamblinglocationspast12months = dataClient.data().gamblinglocationspast12months ? dataClient.data().gamblinglocationspast12months : ''
                        this.hasClientAttendedReceivedServices = dataClient.data().hasClientAttendedReceivedServices ? dataClient.data().hasClientAttendedReceivedServices : ''
                        this.legalSystem = dataClient.data().legalSystem ? dataClient.data().legalSystem : ''
                        this.notesAdmission = dataClient.data().notesAdmission
                        // discharge form
                        this.dateOfDischarge = dataClient.data().dateOfDischarge
                        this.dischargeStatus = dataClient.data().dischargeStatus
                        this.plannedDischargeReasons = dataClient.data().plannedDischargeReasons
                        this.unplannedDischargeReason = dataClient.data().unplannedDischargeReason
                        this.numberOfSessionsAttended = dataClient.data().numberOfSessionsAttended
                        this.dischargeDisposition = dataClient.data().dischargeDisposition ? dataClient.data().dischargeDisposition : ''
                        this.referralDisposition = dataClient.data().referralDisposition ? dataClient.data().referralDisposition : ''
                        this.diagnosticCriteriaMetAtAdmissionWasReduced = dataClient.data().diagnosticCriteriaMetAtAdmissionWasReduced
                        this.diagnosticCriteriaMetAtDischarge = dataClient.data().diagnosticCriteriaMetAtDischarge ? dataClient.data().diagnosticCriteriaMetAtDischarge : ''
                        this.goalsMet = dataClient.data().goalsMet
                        this.currentGamblingComparedToAdmission = dataClient.data().currentGamblingComparedToAdmission
                        this.clientInvolvedWithSelfHelpAtDischarge = dataClient.data().clientInvolvedWithSelfHelpAtDischarge
                        this.clientReferredForContinuedServices = dataClient.data().clientReferredForContinuedServices
                        this.ifSoWhatServicesWereRecommended = dataClient.data().ifSoWhatServicesWereRecommended
                        this.relatedProblemsGambling = dataClient.data().relatedProblemsGambling ? dataClient.data().relatedProblemsGambling : ''
                        this.relatedProblemsDrugUse = dataClient.data().relatedProblemsDrugUse ? dataClient.data().relatedProblemsDrugUse : ''
                        this.relatedProblemsAlcoholUse = dataClient.data().relatedProblemsAlcoholUse ? dataClient.data().relatedProblemsAlcoholUse : ''
                        this.relatedProblemsFinancial = dataClient.data().relatedProblemsFinancial ? dataClient.data().relatedProblemsFinancial : ''
                        this.relatedProblemsLegal = dataClient.data().relatedProblemsLegal ? dataClient.data().relatedProblemsLegal : ''
                        this.relatedProblemsVocationalEducational = dataClient.data().relatedProblemsVocationalEducational ? dataClient.data().relatedProblemsVocationalEducational : ''
                        this.relatedProblemsMedicalHealth = dataClient.data().relatedProblemsMedicalHealth ? dataClient.data().relatedProblemsMedicalHealth : ''
                        this.relatedProblemsLethalitySelf = dataClient.data().relatedProblemsLethalitySelf ? dataClient.data().relatedProblemsLethalitySelf : ''
                        this.relatedProblemsLethalityOther = dataClient.data().relatedProblemsLethalityOther ? dataClient.data().relatedProblemsLethalityOther : ''
                        this.relatedProblemsMentalHealth = dataClient.data().relatedProblemsMentalHealth ? dataClient.data().relatedProblemsMentalHealth : ''
                        this.relatedProblemsFamily = dataClient.data().relatedProblemsFamily ? dataClient.data().relatedProblemsFamily : ''
                        this.notesDischarge = dataClient.data().notesDischarge
                        // survey
                        this.isClientInsured = dataClient.data().isClientInsured ? dataClient.data().isClientInsured : ''
                        this.isClientMatchedToaClinicianWhoAcceptsTheirInsurance = dataClient.data().isClientMatchedToaClinicianWhoAcceptsTheirInsurance ? dataClient.data().isClientMatchedToaClinicianWhoAcceptsTheirInsurance : ''
                        this.isTheClinicianBillingTheClientsInsurance = dataClient.data().isTheClinicianBillingTheClientsInsurance ? dataClient.data().isTheClinicianBillingTheClientsInsurance : ''
                        this.whyInsuranceWasNotBilled = dataClient.data().whyInsuranceWasNotBilled ? dataClient.data().whyInsuranceWasNotBilled : ''
                        this.whyInsuranceWasNotBilledOther = dataClient.data().whyInsuranceWasNotBilledOther ? dataClient.data().whyInsuranceWasNotBilledOther : ''
                        this.reasonWhyClientWasReferredToClinician = dataClient.data().reasonWhyClientWasReferredToClinician ? dataClient.data().reasonWhyClientWasReferredToClinician : ''
                        this.reasonWhyClientWasReferredToClinicianOther = dataClient.data().reasonWhyClientWasReferredToClinicianOther ? dataClient.data().reasonWhyClientWasReferredToClinicianOther : ''
                        this.itemsFormRepeater = dataClient.data().paymentInsuranceUtilizationForm && dataClient.data().paymentInsuranceUtilizationForm.length ? dataClient.data().paymentInsuranceUtilizationForm : []
                        // content right
                        this.specialIssuesOrOtherComments = dataClient.data().specialIssuesOrOtherComments
                        this.callNotes = dataClient.data().callNotes
                        this.activityStatus = dataClient.data().activityStatus
                        this.
                        this.closeNotes = dataClient.data().closeNotes
                        this.client = objClient
                    // })
                        this.loadDataPage = true
                })
            })
        },
        activityStatus(val) {
            if(val.includes('Closed') || val === 'Engaged') {
                this.removeRecordAdmissionData =  false
                this.removeRecordDischargeData = false
                // this.activityStatus = 'Engaged'
            }

            // increment changes of activity status, no show alert when load component
            this.counterChangesActivityStatus += 1
            
            if(this.counterChangesActivityStatus >= 2) {
                if(val === 'Closed - Referred to OASAS Program' || val === 'External Referral' || val === 'Closed - Received information') {
                    // console.log('first')
                    // alert activity status
                    if(this.activityStatusAtomic !== 'Closed - Referred to OASAS Program' && this.activityStatusAtomic !== 'External Referral' && this.activityStatusAtomic !== 'Closed - Received information') {
                        this.$swal({
                            title: 'Are you sure?',
                            text: "Are you sure you want to change the status? The initial appointment date/time will be disabled.",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                                cancelButton: 'btn btn-outline-danger ml-1',
                            },
                            buttonsStyling: false,
                        }).then(result => {
                            if (result.value) {
                                this.activityStatusAtomic = val
                                this.clinician = []
                                this.initialAppointmentDate = null
                                this.time = ''
                                // this.$swal({
                                //     icon: 'success',
                                //     title: 'Activity Status changed',
                                //     text: 'The initial appointment date/time will be disabled',
                                //     customClass: {
                                //         confirmButton: 'btn btn-success',
                                //     },
                                // })
                            } else {
                                this.activityStatus = this.activityStatusAtomic
                            }
                        })
                    }
                }
            } else {
                this.activityStatusAtomic = val
            }
        },
        isClientInsured(val) {
            if(val === 'No') {
                this.logicisClientInsured = true
                this.isClientMatchedToaClinicianWhoAcceptsTheirInsurance = ''
                this.isTheClinicianBillingTheClientsInsurance = ''
                this.whyInsuranceWasNotBilled = ''
                this.whyInsuranceWasNotBilledOther = ''
                this.reasonWhyClientWasReferredToClinician = ''
                this.reasonWhyClientWasReferredToClinicianOther = ''
            } else {
                // answer: isClientMatchedToaClinicianWhoAcceptsTheirInsurance
                this.logicisClientInsured = false
            }
        },
        isClientMatchedToaClinicianWhoAcceptsTheirInsurance(val) {
            if(val === 'No') {
                // answer: reasonWhyClientWasReferredToClinician -> reasonWhyClientWasReferredToClinicianOther
                this.logicisClientMatchedToaClinicianWhoAcceptsTheirInsurance = true
                this.isTheClinicianBillingTheClientsInsurance = ''
                this.whyInsuranceWasNotBilled = ''
                this.whyInsuranceWasNotBilledOther = ''
                this.reasonWhyClientWasReferredToClinician = ''
                this.reasonWhyClientWasReferredToClinicianOther = ''
            } else {
                // answer: isTheClinicianBillingTheClientsInsurance
                this.logicisClientMatchedToaClinicianWhoAcceptsTheirInsurance = false
            }
        },
        isTheClinicianBillingTheClientsInsurance(val) {
            if(val === 'Yes') {
                this.logicisTheClinicianBillingTheClientsInsurance = false
                this.logicisTheClinicianBillingTheClientsInsuranceOptionYes = true
                this.whyInsuranceWasNotBilled = ''
                this.whyInsuranceWasNotBilledOther = ''
                this.reasonWhyClientWasReferredToClinician = ''
                this.reasonWhyClientWasReferredToClinicianOther = ''
            } else if(val === 'No') {
                // answer: whyInsuranceWasNotBilled -> whyInsuranceWasNotBilledOther
                this.logicisTheClinicianBillingTheClientsInsurance = true
                this.logicisTheClinicianBillingTheClientsInsuranceOptionYes = false
                this.reasonWhyClientWasReferredToClinician = ''
                this.reasonWhyClientWasReferredToClinicianOther = ''
            } else {
                this.logicisTheClinicianBillingTheClientsInsurance = false
                this.logicisTheClinicianBillingTheClientsInsuranceOptionYes = false
            }
        }
    },
    methods: {
        ...mapActions(['updateClientActivity']),
        filtChange(val) {
            // this.loading = true
            // var triggers = val.explicitOriginalTarget ? val.explicitOriginalTarget.value : val.target._value
            // console.log(val)
            this.dodListener += val.key
            setTimeout(() => {
                var changeInputSite = val.explicitOriginalTarget ? val.explicitOriginalTarget.value : val.target._value
                var ultimosTresCaracteres = this.dodListener.slice(-3);
                if(changeInputSite === 'n/a' || changeInputSite === 'N/A' || ultimosTresCaracteres === 'N/A' || ultimosTresCaracteres === 'n/a') {
                    this.dateOfDischarge = 'N/A'
                    this.dodListener = ''
                    // console.log(this.dodListener, changeInputSite)
                }
            }, 500)
        },
        onCloseDischargeDate() {
            this.popoverShowDischargeDate = false
        },
        onOkDischargeDate() {
            // console.log('ok')
            this.dateOfDischarge = 'N/A'
            this.onCloseDischargeDate()
        },
        onShowDischargeDate() {
            // console.log('asdf')
        },
        // onShown() {
        //     console.log('first')
        // },
        onHiddenDischargeDate() {
            // console.log('first')
        },
        onClose() {
            this.popoverShow = false
        },
        onOk() {
            // console.log('ok')
            this.numberOfSessionsAttended = 'N/A'
            this.onClose()
        },
        onShow() {
            // console.log('asdf')
        },
        // onShown() {
        //     console.log('first')
        // },
        onHidden() {
            // console.log('first')
        },
        submitFormClientActivity() {
            var clinicianUidArray = []
            this.clinician.forEach(clinicianUid => {
                clinicianUidArray.push(clinicianUid.uid)
            })

            var objClient = {
                // form
                clinician: clinicianUidArray,
                dateOfContact: this.dateOfContact,
                methodOfContact: this.methodOfContact,
                contactStatus: this.contactStatus,
                referredBy: this.referredBy,
                familyMemberSelf: this.familyMemberSelf,
                problemGamblingSelfHelpGroupMeetings: this.problemGamblingSelfHelpGroupMeetings,
                problemGamblingTreatmentServices: this.problemGamblingTreatmentServices,
                substanceUseTreatmentServices: this.substanceUseTreatmentServices,
                recoverySupportServices: this.recoverySupportServices,
                otherReferredBy: this.otherReferredBy,
                warmTransfer: this.warmTransfer,
                initialAppointmentDate: this.initialAppointmentDate,
                time: this.time,
                wasTheCallHelpful: this.wasTheCallHelpful,
                wasTheCallHelpfulIfNotWhy: this.wasTheCallHelpfulIfNotWhy,
                informationKitAccepted: this.informationKitAccepted,
                informationKitOption: this.informationKitOption,
                regionalCFEReceivingTheCall: this.regionalCFEReceivingTheCall,
                borough: this.borough,
                readmission: this.readmission,
                race: this.race,
                householdIncome: this.householdIncome,
                gamblingTypes: this.gamblingTypes,
                employmentStatus: this.employmentStatus,
                educationLevels: this.educationLevels,
                ethnicity: this.ethnicity,
                veteran: this.veteran,
                zipCode: this.zipCode,
                industryOfEmployment: this.industryOfEmployment,
                maritalStatus: this.maritalStatus,
                religiousPreference: this.religiousPreference,
                connectedToPeerAdvocate: this.connectedToPeerAdvocate,
                connectedToVSE: this.connectedToVSE,
                connectedToPeerEducationGroup: this.connectedToPeerEducationGroup,
                connectedToFamilyEducationGroup: this.connectedToFamilyEducationGroup,
                connectedToAsianServicesProgram: this.connectedToAsianServicesProgram,
                // enter adminission/discharge information
                enterAdmissionDischargeInformation: this.enterAdmissionDischargeInformation,
                enterAdmissionInformation: this.enterAdmissionInformation,
                enterDischargeInformation: this.enterDischargeInformation,
                enterSurveyInformation: this.enterSurveyInformation,
                // admission form
                dateOfAdmission: this.dateOfAdmission ? this.dateOfAdmission : null,
                newOrReturningClient: this.newOrReturningClient ? this.newOrReturningClient : '',
                clientStatusAdmission: this.clientStatusAdmission ? this.clientStatusAdmission : '',
                presentingProblemsAtAdmission: this.presentingProblemsAtAdmission ? this.presentingProblemsAtAdmission : '',
                diagnosticCriteriaMetAtAdmission: this.diagnosticCriteriaMetAtAdmission ? this.diagnosticCriteriaMetAtAdmission : '',
                otherProblemsAdmission: this.otherProblemsAdmission ? this.otherProblemsAdmission : '',
                clientInvolvedWithLegalSystem: this.clientInvolvedWithLegalSystem ? this.clientInvolvedWithLegalSystem : '',
                clientReleaseSigned: this.clientReleaseSigned ? this.clientReleaseSigned : '',
                referredByAdmission: this.referredByAdmission ? this.referredByAdmission : '',
                familyMemberSelfAdmission: this.familyMemberSelfAdmission ? this.familyMemberSelfAdmission : '',
                problemGamblingTreatmentServicesAdmission: this.problemGamblingTreatmentServicesAdmission ? this.problemGamblingTreatmentServicesAdmission : '',
                problemGamblingSelfHelpGroupMeetingsAdmission: this.problemGamblingSelfHelpGroupMeetingsAdmission ? this.problemGamblingSelfHelpGroupMeetingsAdmission : '',
                substanceUseTreatmentServicesAdmission: this.substanceUseTreatmentServicesAdmission ? this.substanceUseTreatmentServicesAdmission : '',
                recoverySupportServicesAdmission: this.recoverySupportServicesAdmission ? this.recoverySupportServicesAdmission : '',
                otherReferredByAdmission: this.otherReferredByAdmission ? this.otherReferredByAdmission : '',
                warmTransferAdmission: this.warmTransferAdmission ? this.warmTransferAdmission : '',
                primaryICD10: this.primaryICD10 ? this.primaryICD10 : '',
                Z72GamblingAndBetting: this.Z72GamblingAndBetting ? this.Z72GamblingAndBetting : '',
                numberofAssessmentVisits: this.numberofAssessmentVisits ? this.numberofAssessmentVisits : '',
                age: this.age ? this.age : '',
                sexualOrientation: this.sexualOrientation ? this.sexualOrientation : '',
                genderIdentity: this.genderIdentity ? this.genderIdentity : '',
                transgender: this.transgender ? this.transgender : '',
                asianOrigin: this.asianOrigin ? this.asianOrigin : '',
                pacificIslanderOrigin: this.pacificIslanderOrigin ? this.pacificIslanderOrigin : '',
                developmentalDisability: this.developmentalDisability ? this.developmentalDisability : '',
                coexistingPsychiatricDisorder: this.coexistingPsychiatricDisorder ? this.coexistingPsychiatricDisorder : '',
                treatedForMentalIllness: this.treatedForMentalIllness ? this.treatedForMentalIllness : '',
                hospitalizedForMentalIllness: this.hospitalizedForMentalIllness ? this.hospitalizedForMentalIllness : '',
                hospitalized30OrMoreDaysForMentalIllness: this.hospitalized30OrMoreDaysForMentalIllness ? this.hospitalized30OrMoreDaysForMentalIllness : '',
                smokedTobaccoInThePastWeek: this.smokedTobaccoInThePastWeek ? this.smokedTobaccoInThePastWeek : '',
                usedSmokelessTobaccoInThePastWeek: this.usedSmokelessTobaccoInThePastWeek ? this.usedSmokelessTobaccoInThePastWeek : '',
                substancesUsed: this.substancesUsed ? this.substancesUsed : '',
                substanceUseRouteOfAdministration: this.substanceUseRouteOfAdministration ? this.substanceUseRouteOfAdministration : '',
                frequencyOfSubstanceUse: this.frequencyOfSubstanceUse ? this.frequencyOfSubstanceUse : '',
                ageOfFirstSubstanceUse: this.ageOfFirstSubstanceUse ? this.ageOfFirstSubstanceUse : '',
                inThePastFewMonthsDrinks: this.inThePastFewMonthsDrinks ? this.inThePastFewMonthsDrinks : '',
                referralOptions : this.referralOptions ? this.referralOptions : '',
                OASASProgram : this.OASASProgram ? this.OASASProgram : '',
                OASASProgramOptInpatient : this.OASASProgramOptInpatient ? this.OASASProgramOptInpatient : '',
                OASASProgramOptResidential : this.OASASProgramOptResidential ? this.OASASProgramOptResidential : '',
                OASASProgramOptOutpatient : this.OASASProgramOptOutpatient ? this.OASASProgramOptOutpatient : '',
                privatePractitioner : this.privatePractitioner ? this.privatePractitioner : '',
                recoverySupportServices : this.recoverySupportServices ? this.recoverySupportServices : '',
                locadtrg: this.locadtrg ? this.locadtrg : '',
                LOCADTRAssessmentID: this.LOCADTRAssessmentID ? this.LOCADTRAssessmentID : '',
                dateOfBirthAdmission: this.dateOfBirthAdmission ? this.dateOfBirthAdmission : '',
                sexAtBirth: this.sexAtBirth ? this.sexAtBirth : '',
                last4SSN: this.last4SSN ? this.last4SSN : '',
                firstTwoLettersLastName: this.firstTwoLettersLastName ? this.firstTwoLettersLastName : '',
                frequencyofGambling: this.frequencyofGambling ? this.frequencyofGambling : '',
                ageFirstGambled: this.ageFirstGambled ? this.ageFirstGambled : '',
                past30DaysAmountOfMoney: this.past30DaysAmountOfMoney ? this.past30DaysAmountOfMoney : '',
                past30DaysHowMuchTime: this.past30DaysHowMuchTime ? this.past30DaysHowMuchTime : '',
                past30DaysHowManyDays: this.past30DaysHowManyDays ? this.past30DaysHowManyDays : '',
                gamblinglocationspast12months: this.gamblinglocationspast12months ? this.gamblinglocationspast12months : '',
                hasClientAttendedReceivedServices: this.hasClientAttendedReceivedServices ? this.hasClientAttendedReceivedServices : '',
                legalSystem: this.legalSystem ? this.legalSystem : '',
                notesAdmission: this.notesAdmission ? this.notesAdmission : '',
                // discharge form
                dateOfDischarge: this.dateOfDischarge ? this.dateOfDischarge : null,
                dischargeStatus: this.dischargeStatus ? this.dischargeStatus : '',
                plannedDischargeReasons: this.plannedDischargeReasons ? this.plannedDischargeReasons : '',
                unplannedDischargeReason: this.unplannedDischargeReason ? this.unplannedDischargeReason : '',
                numberOfSessionsAttended: this.numberOfSessionsAttended ? this.numberOfSessionsAttended : null,
                dischargeDisposition: this.dischargeDisposition ? this.dischargeDisposition : '',
                referralDisposition: this.referralDisposition ? this.referralDisposition : '',
                diagnosticCriteriaMetAtAdmissionWasReduced: this.diagnosticCriteriaMetAtAdmissionWasReduced ? this.diagnosticCriteriaMetAtAdmissionWasReduced : '',
                diagnosticCriteriaMetAtDischarge: this.diagnosticCriteriaMetAtDischarge ? this.diagnosticCriteriaMetAtDischarge : '',
                goalsMet: this.goalsMet ? this.goalsMet : '',
                currentGamblingComparedToAdmission: this.currentGamblingComparedToAdmission ? this.currentGamblingComparedToAdmission : '',
                clientInvolvedWithSelfHelpAtDischarge: this.clientInvolvedWithSelfHelpAtDischarge ? this.clientInvolvedWithSelfHelpAtDischarge : '',
                clientReferredForContinuedServices: this.clientReferredForContinuedServices ? this.clientReferredForContinuedServices : '',
                ifSoWhatServicesWereRecommended: this.ifSoWhatServicesWereRecommended ? this.ifSoWhatServicesWereRecommended : '',
                relatedProblemsGambling: this.relatedProblemsGambling ? this.relatedProblemsGambling : '',
                relatedProblemsDrugUse: this.relatedProblemsDrugUse ? this.relatedProblemsDrugUse : '',
                relatedProblemsAlcoholUse: this.relatedProblemsAlcoholUse ? this.relatedProblemsAlcoholUse : '',
                relatedProblemsFinancial: this.relatedProblemsFinancial ? this.relatedProblemsFinancial : '',
                relatedProblemsLegal: this.relatedProblemsLegal ? this.relatedProblemsLegal : '',
                relatedProblemsVocationalEducational: this.relatedProblemsVocationalEducational ? this.relatedProblemsVocationalEducational : '',
                relatedProblemsMedicalHealth: this.relatedProblemsMedicalHealth ? this.relatedProblemsMedicalHealth : '',
                relatedProblemsLethalitySelf: this.relatedProblemsLethalitySelf ? this.relatedProblemsLethalitySelf : '',
                relatedProblemsLethalityOther: this.relatedProblemsLethalityOther ? this.relatedProblemsLethalityOther : '',
                relatedProblemsMentalHealth: this.relatedProblemsMentalHealth ? this.relatedProblemsMentalHealth : '',
                relatedProblemsFamily: this.relatedProblemsFamily ? this.relatedProblemsFamily : '',
                notesDischarge: this.notesDischarge ? this.notesDischarge : '',
                // survey
                isClientInsured: this.isClientInsured,
                isClientMatchedToaClinicianWhoAcceptsTheirInsurance: this.isClientMatchedToaClinicianWhoAcceptsTheirInsurance,
                isTheClinicianBillingTheClientsInsurance: this.isTheClinicianBillingTheClientsInsurance,
                whyInsuranceWasNotBilled: this.whyInsuranceWasNotBilled,
                whyInsuranceWasNotBilledOther: this.whyInsuranceWasNotBilledOther,
                reasonWhyClientWasReferredToClinician: this.reasonWhyClientWasReferredToClinician,
                reasonWhyClientWasReferredToClinicianOther: this.reasonWhyClientWasReferredToClinicianOther,
                paymentInsuranceUtilizationForm: this.itemsFormRepeater && this.itemsFormRepeater.length ? this.itemsFormRepeater : [],
                // content right
                specialIssuesOrOtherComments: this.specialIssuesOrOtherComments,
                callNotes: this.callNotes,
                activityStatus: this.activityStatus,
                closeNotes: this.closeNotes,
                // client: this.idClientActivity,

                // configuration admission and discharge
                removeRecordAdmissionData: this.removeRecordAdmissionData,
                removeRecordDischargeData: this.removeRecordDischargeData,

                clinicianName: '',
                clinicianStatus: '',
                clinicianRegion: [],
                arrayClinicianStatus: [],
                arrayClinicianName: []
            }
            this.updateClientActivity({id: this.idClientActivity, data: objClient})
            this.statusEditClientActivity = false
            this.$emit('statusEditClientActivity',false)
            this.pushEditButton = true
            this.$emit('pushEditButton',true)
            //  reset form
            this.clinician = []
            this.dateOfContact = null
            this.methodOfContact = ''
            this.contactStatus = ''
            this.referredBy = ''
            this.familyMemberSelf = ''
            this.problemGamblingSelfHelpGroupMeetings = ''
            this.problemGamblingTreatmentServices = ''
            this.substanceUseTreatmentServices = ''
            this.recoverySupportServices = ''
            this.otherReferredBy = ''
            this.warmTransfer = ''
            this.initialAppointmentDate = null
            this.time = ''
            this.wasTheCallHelpful = ''
            this.wasTheCallHelpfulIfNotWhy = ''
            this.informationKitAccepted = ''
            this.informationKitOption = ''
            this.regionalCFEReceivingTheCall = ''
            this.borough = ''
            this.readmission = ''
            this.race = ''
            this.householdIncome = ''
            this.gamblingTypes = ''
            this.employmentStatus = ''
            this.educationLevels = ''
            this.ethnicity = ''
            this.veteran = ''
            this.zipCode = ''
            this.industryOfEmployment = ''
            this.maritalStatus = ''
            this.religiousPreference = ''
            this.connectedToPeerAdvocate = ''
            this.connectedToVSE = ''
            this.connectedToPeerEducationGroup = ''
            this.connectedToFamilyEducationGroup = ''
            this.connectedToAsianServicesProgram = ''
            // enter adminission/discharge information
            this.enterAdmissionDischargeInformation = false
            this.enterAdmissionInformation = false
            this.enterDischargeInformation = false
            this.enterSurveyInformation = false
            // admission form
            this.dateOfAdmission = null
            this.newOrReturningClient = ''
            this.clientStatusAdmission = ''
            this.presentingProblemsAtAdmission = ''
            this.diagnosticCriteriaMetAtAdmission = ''
            this.otherProblemsAdmission = ''
            this.clientInvolvedWithLegalSystem = ''
            this.clientReleaseSigned = ''
            this.referredByAdmission = ''
            this.familyMemberSelfAdmission = ''
            this.problemGamblingTreatmentServicesAdmission = ''
            this.problemGamblingSelfHelpGroupMeetingsAdmission = ''
            this.substanceUseTreatmentServicesAdmission = ''
            this.recoverySupportServicesAdmission = ''
            this.otherReferredByAdmission = ''
            this.warmTransferAdmission = ''
            this.primaryICD10 = ''
            this.Z72GamblingAndBetting = ''
            this.numberofAssessmentVisits = ''
            this.age = ''
            this.sexualOrientation = ''
            this.genderIdentity = ''
            this.transgender = ''
            this.asianOrigin = ''
            this.pacificIslanderOrigin = ''
            this.developmentalDisability = ''
            this.coexistingPsychiatricDisorder = ''
            this.treatedForMentalIllness = ''
            this.hospitalizedForMentalIllness = ''
            this.hospitalized30OrMoreDaysForMentalIllness = ''
            this.smokedTobaccoInThePastWeek = ''
            this.usedSmokelessTobaccoInThePastWeek = ''
            this.substancesUsed = ''
            this.substanceUseRouteOfAdministration = ''
            this.frequencyOfSubstanceUse = ''
            this.ageOfFirstSubstanceUse = ''
            this.inThePastFewMonthsDrinks = ''
            this.referralOptions = ''
            this.OASASProgram = ''
            this.OASASProgramOptInpatient = ''
            this.OASASProgramOptResidential = ''
            this.OASASProgramOptOutpatient = ''
            this.privatePractitioner = ''
            this.recoverySupportServices = ''
            this.locadtrg = ''
            this.LOCADTRAssessmentID = ''
            this.dateOfBirthAdmission = ''
            this.sexAtBirth = ''
            this.last4SSN = ''
            this.firstTwoLettersLastName = ''
            this.frequencyofGambling = ''
            this.ageFirstGambled = ''
            this.past30DaysAmountOfMoney = ''
            this.past30DaysHowMuchTime = ''
            this.past30DaysHowManyDays = ''
            this.gamblinglocationspast12months = ''
            this.hasClientAttendedReceivedServices = ''
            this.legalSystem = ''
            this.notesAdmission = ''
            // discharge form
            this.dateOfDischarge = null
            this.dischargeStatus = ''
            this.plannedDischargeReasons = ''
            this.unplannedDischargeReason = ''
            this.numberOfSessionsAttended = null
            this.dischargeDisposition = ''
            this.referralDisposition = ''
            this.diagnosticCriteriaMetAtAdmissionWasReduced = ''
            this.diagnosticCriteriaMetAtDischarge = ''
            this.goalsMet = ''
            this.currentGamblingComparedToAdmission = ''
            this.clientInvolvedWithSelfHelpAtDischarge = ''
            this.clientReferredForContinuedServices = ''
            this.ifSoWhatServicesWereRecommended = ''
            this.relatedProblemsGambling = ''
            this.relatedProblemsDrugUse = ''
            this.relatedProblemsAlcoholUse = ''
            this.relatedProblemsFinancial = ''
            this.relatedProblemsLegal = ''
            this.relatedProblemsVocationalEducational = ''
            this.relatedProblemsMedicalHealth = ''
            this.relatedProblemsLethalitySelf = ''
            this.relatedProblemsLethalityOther = ''
            this.relatedProblemsMentalHealth = ''
            this.relatedProblemsFamily = ''
            this.notesDischarge = ''
            // survey
            this.isClientInsured = ''
            this.isClientMatchedToaClinicianWhoAcceptsTheirInsurance = ''
            this.isTheClinicianBillingTheClientsInsurance = ''
            this.whyInsuranceWasNotBilled = ''
            this.whyInsuranceWasNotBilledOther = ''
            this.reasonWhyClientWasReferredToClinician = ''
            this.reasonWhyClientWasReferredToClinicianOther = ''
            this.itemsFormRepeater = []
            // content right
            this.specialIssuesOrOtherComments = ''
            this.callNotes = ''
            this.activityStatus = ''
            this.closeNotes = ''
        },
        validationForm() {
            this.$refs.editClinicianRules.validate().then(success => {
                if (success) {
                    // check validation form repeater
                    this.validFormRepeater = true
                    var validateFormRepeater = true
                    this.itemsFormRepeater.forEach(ele => {
                        if(!ele.paymentAssessmentDate) {
                            validateFormRepeater = false
                        }

                        if(!ele.paymentArrangement) {
                            validateFormRepeater = false
                        }

                        if(ele.theClientInsuranceIdonotAccept.length === 0 && ele.paymentArrangement.includes('The client has insurance that I do not accept. I was provided with this referral due to the circumstances selected below. NYCPG will provide reimbursement.')) {
                            validateFormRepeater = false
                        }

                        // if(ele.paymentArrangement === 'I will bill the client’s insurance and the client will provide the required copay. NYCPG will not provide reimbursement.' || ele.paymentArrangement === 'The client has insurance, but is not able to provide the required copay. NYCPG will provide reimbursement.' || ele.paymentArrangement === 'I will not bill the client’s insurance at the request of the client. NYCPG will provide reimbursement.' || ele.paymentArrangement === 'The client will utilize sliding scale for reasons not specified on this form. NYCPG will provide reimbursement.' || ele.theClientInsuranceIdonotAccept.includes('Other')) {
                        //     if(!ele.explanation) {
                        //         validateFormRepeater = false
                        //     }
                        // }
                    })
                    // console.log(validateFormRepeater)
                    
                    // eslint-disable-next-line
                    if(validateFormRepeater) {
                        // eslint-disable-next-line
                        this.submitFormClientActivity()
                    } else {
                        this.validFormRepeater = false
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `(*) One or more required field(s) are missing.`,
                                icon: 'CoffeeIcon',
                                variant: 'danger',
                            },
                        })
                    }
                }
            })
        },
        repeateAgain() {
            this.itemsFormRepeater.push({
                id: this.nextTodoId += this.itemsFormRepeater.length,
                paymentAssessmentDate: '',
                paymentArrangement: '',
                theClientInsuranceIdonotAccept: '',
                explanation: '',
                providingReimbursement: false
            })

            this.$nextTick(() => {
                this.trAddHeight(this.$refs.row[0].offsetHeight)
            })
        },
        removeItem(index) {
            this.itemsFormRepeater.splice(index, 1)
            // this.counterSession()
            this.trTrimHeight(this.$refs.row[0].offsetHeight)
        },
        initTrHeight() {
            this.trSetHeight(null)
            this.$nextTick(() => {
                this.trSetHeight(this.$refs.formRepeaterSessionsEng.scrollHeight)
            })
        },
        backList() {
            this.statusEditClientActivity = false
            this.$emit('statusEditClientActivity',false)
        },
        removeRecordAdmission() {
            this.$swal({
                title: 'Remove',
                text: "Are you sure you want to delete this Admission record?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1 cancelBtn-modal',
                },
                buttonsStyling: false,
            }).then(result => {
                if(result.value) {
                    // admission form
                    this.dateOfAdmission = null
                    this.newOrReturningClient = ''
                    this.clientStatusAdmission = ''
                    this.presentingProblemsAtAdmission = ''
                    this.otherProblemsAdmission = ''
                    this.diagnosticCriteriaMetAtAdmission = ''
                    this.clientInvolvedWithLegalSystem = ''
                    this.clientReleaseSigned = ''
                    this.referredByAdmission = ''
                    this.familyMemberSelfAdmission = ''
                    this.problemGamblingTreatmentServicesAdmission = ''
                    this.problemGamblingSelfHelpGroupMeetingsAdmission = ''
                    this.substanceUseTreatmentServicesAdmission = ''
                    this.recoverySupportServicesAdmission = ''
                    this.otherReferredByAdmission = ''
                    this.warmTransferAdmission = ''
                    this.race = ''
                    this.householdIncome = ''
                    this.gamblingTypes = ''
                    this.employmentStatus = ''
                    this.educationLevels = ''
                    this.ethnicity = ''
                    this.veteran = ''
                    this.zipCode = ''
                    this.industryOfEmployment = ''
                    this.maritalStatus = ''
                    this.religiousPreference = ''
                    this.primaryICD10 = ''
                    this.Z72GamblingAndBetting = ''
                    this.numberofAssessmentVisits = ''
                    this.age = ''
                    this.sexualOrientation = ''
                    this.genderIdentity = ''
                    this.transgender = ''
                    this.asianOrigin = ''
                    this.pacificIslanderOrigin = ''
                    this.developmentalDisability = ''
                    this.coexistingPsychiatricDisorder = ''
                    this.treatedForMentalIllness = ''
                    this.hospitalizedForMentalIllness = ''
                    this.hospitalized30OrMoreDaysForMentalIllness = ''
                    this.smokedTobaccoInThePastWeek = ''
                    this.usedSmokelessTobaccoInThePastWeek = ''
                    this.substancesUsed = ''
                    this.substanceUseRouteOfAdministration = ''
                    this.frequencyOfSubstanceUse = ''
                    this.ageOfFirstSubstanceUse = ''
                    this.locadtrg = ''
                    this.LOCADTRAssessmentID = ''
                    this.dateOfBirthAdmission = ''
                    this.sexAtBirth = ''
                    this.last4SSN = ''
                    this.firstTwoLettersLastName = ''
                    this.frequencyofGambling = ''
                    this.ageFirstGambled = ''
                    this.past30DaysAmountOfMoney = ''
                    this.past30DaysHowMuchTime = ''
                    this.past30DaysHowManyDays = ''
                    this.gamblinglocationspast12months = ''
                    this.hasClientAttendedReceivedServices = ''
                    this.legalSystem = ''
                    this.referralOptions = ''
                    this.OASASProgram = ''
                    this.OASASProgramOptInpatient = ''
                    this.OASASProgramOptResidential = ''
                    this.OASASProgramOptOutpatient = ''
                    this.privatePractitioner = ''
                    this.recoverySupportServices = ''
                    // this.inThePastFewMonthsDrinks = ''
                    // this.referredBy = ''
                    // this.familyMemberSelf = ''
                    // this.problemGamblingSelfHelpGroupMeetings = ''
                    // this.problemGamblingTreatmentServices = ''
                    // this.substanceUseTreatmentServices = ''
                    // this.recoverySupportServices = ''
                    // this.otherReferredBy = ''
                    // this.warmTransfer = ''
                    // this.notesAdmission = ''
                    // this.removeRecordAdmissionData = true

                    db.collection('clientsActivity').doc(this.idClientActivity).update({
                        removeRecordAdmissionData: true,
                        activityStatus: 'Engaged',
                        dateOfAdmission: null,
                        newOrReturningClient: '',
                        clientStatusAdmission: '',
                        presentingProblemsAtAdmission: '',
                        otherProblemsAdmission: '',
                        diagnosticCriteriaMetAtAdmission: '',
                        clientInvolvedWithLegalSystem: '',
                        clientReleaseSigned: '',
                        referredByAdmission: '',
                        familyMemberSelfAdmission: '',
                        problemGamblingTreatmentServicesAdmission: '',
                        problemGamblingSelfHelpGroupMeetingsAdmission: '',
                        substanceUseTreatmentServicesAdmission: '',
                        recoverySupportServicesAdmission: '',
                        otherReferredByAdmission: '',
                        warmTransferAdmission: '',
                        race: '',
                        householdIncome: '',
                        gamblingTypes: '',
                        employmentStatus: '',
                        educationLevels: '',
                        ethnicity: '',
                        veteran: '',
                        zipCode: '',
                        industryOfEmployment: '',
                        maritalStatus: '',
                        religiousPreference: '',
                        primaryICD10: '',
                        Z72GamblingAndBetting: '',
                        numberofAssessmentVisits: '',
                        age: '',
                        sexualOrientation: '',
                        genderIdentity: '',
                        transgender: '',
                        asianOrigin: '',
                        pacificIslanderOrigin: '',
                        developmentalDisability: '',
                        coexistingPsychiatricDisorder: '',
                        treatedForMentalIllness: '',
                        hospitalizedForMentalIllness: '',
                        hospitalized30OrMoreDaysForMentalIllness: '',
                        smokedTobaccoInThePastWeek: '',
                        usedSmokelessTobaccoInThePastWeek: '',
                        substancesUsed: '',
                        substanceUseRouteOfAdministration: '',
                        frequencyOfSubstanceUse: '',
                        ageOfFirstSubstanceUse: '',
                        referralOptions: '',
                        OASASProgram: '',
                        OASASProgramOptInpatient: '',
                        OASASProgramOptResidential: '',
                        OASASProgramOptOutpatient: '',
                        privatePractitioner: '',
                        recoverySupportServices: '',
                        // inThePastFewMonthsDrinks: '',
                        // referredBy: '',
                        // familyMemberSelf: '',
                        // problemGamblingSelfHelpGroupMeetings: '',
                        // problemGamblingTreatmentServices: '',
                        // substanceUseTreatmentServices: '',
                        // recoverySupportServices: '',
                        // otherReferredBy: '',
                        // warmTransfer: '',
                        locadtrg: '',
                        LOCADTRAssessmentID: '',
                        dateOfBirthAdmission: '',
                        sexAtBirth: '',
                        last4SSN: '',
                        firstTwoLettersLastName: '',
                        frequencyofGambling: '',
                        ageFirstGambled: '',
                        past30DaysAmountOfMoney: '',
                        past30DaysHowMuchTime: '',
                        past30DaysHowManyDays: '',
                        gamblinglocationspast12months: '',
                        hasClientAttendedReceivedServices: '',
                        legalSystem: '',
                        notesAdmission: '',
                        enterAdmissionInformation: false,
                    })
                    .then(() => {
                        this.$swal({
                            icon: 'success',
                            title: 'Deleted!',
                            text: 'The record has been deleted.',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                        if(!this.dateOfDischarge) {
                            this.enterAdmissionInformation = false
                        }

                        if(this.activityStatus.includes('Closed')) {
                            this.activityStatus = 'Engaged'
                        }
                        this.removeRecordAdmissionData =  false
                        this.removeRecordDischargeData = false
                    })
                }
            })
        },
        removeRecordDischarge() {
            this.$swal({
                title: 'Remove',
                text: "Are you sure you want to delete this Discharge record?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1 cancelBtn-modal',
                },
                buttonsStyling: false,
            }).then(result => {
                if(result.value) {
                    // discharge form
                    this.dateOfDischarge = null
                    this.dischargeStatus = ''
                    this.plannedDischargeReasons = ''
                    this.unplannedDischargeReason = ''
                    this.numberOfSessionsAttended = null
                    this.dischargeDisposition = ''
                    this.referralDisposition = ''
                    this.diagnosticCriteriaMetAtAdmissionWasReduced = ''
                    this.diagnosticCriteriaMetAtDischarge = ''
                    this.goalsMet = ''
                    this.currentGamblingComparedToAdmission = ''
                    this.clientInvolvedWithSelfHelpAtDischarge = ''
                    this.clientReferredForContinuedServices = ''
                    this.ifSoWhatServicesWereRecommended = ''
                    this.relatedProblemsGambling = ''
                    this.relatedProblemsDrugUse = ''
                    this.relatedProblemsAlcoholUse = ''
                    this.relatedProblemsFinancial = ''
                    this.relatedProblemsLegal = ''
                    this.relatedProblemsVocationalEducational = ''
                    this.relatedProblemsMedicalHealth = ''
                    this.relatedProblemsLethalitySelf = ''
                    this.relatedProblemsLethalityOther = ''
                    this.relatedProblemsMentalHealth = ''
                    this.relatedProblemsFamily = ''
                    this.notesDischarge = ''
                    // this.removeRecordDischargeData = true

                    db.collection('clientsActivity').doc(this.idClientActivity).update({
                        dateOfDischarge: null,
                        dischargeStatus: '',
                        plannedDischargeReasons: '',
                        unplannedDischargeReason: '',
                        numberOfSessionsAttended: null,
                        dischargeDisposition: '',
                        referralDisposition: '',
                        diagnosticCriteriaMetAtAdmissionWasReduced: '',
                        diagnosticCriteriaMetAtDischarge: '',
                        goalsMet: '',
                        currentGamblingComparedToAdmission: '',
                        clientInvolvedWithSelfHelpAtDischarge: '',
                        clientReferredForContinuedServices: '',
                        ifSoWhatServicesWereRecommended: '',
                        relatedProblemsGambling: '',
                        relatedProblemsDrugUse: '',
                        relatedProblemsAlcoholUse: '',
                        relatedProblemsFinancial: '',
                        relatedProblemsLegal: '',
                        relatedProblemsVocationalEducational: '',
                        relatedProblemsMedicalHealth: '',
                        relatedProblemsLethalitySelf: '',
                        relatedProblemsLethalityOther: '',
                        relatedProblemsMentalHealth: '',
                        relatedProblemsFamily: '',
                        notesDischarge: '',
                        removeRecordDischargeData: true,
                        activityStatus: 'Engaged',
                        enterDischargeInformation: false
                    })
                    .then(() => {
                        this.$swal({
                            icon: 'success',
                            title: 'Deleted!',
                            text: 'The record has been deleted.',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                        if(!this.dateOfAdmission) {
                            this.enterDischargeInformation = false
                        }

                        if(this.activityStatus.includes('Closed')) {
                            this.activityStatus = 'Engaged'
                        }
                    })
                }
            })
        }
    },
    created() {
        window.addEventListener('resize', this.initTrHeight)
        this.requiredDischargeForm = false
        this.isClinicianStaff = false
        this.logicisClientInsured = false
        this.logicisClientMatchedToaClinicianWhoAcceptsTheirInsurance = false
        this.logicisTheClinicianBillingTheClientsInsurance = false
        this.logicisTheClinicianBillingTheClientsInsuranceOptionYes = false
        this.counterChangesActivityStatus = 0
        var user = auth.currentUser
        db.collection('usuarios').doc(user.uid).get()
        .then(userDoc => {
            // this.isDataSpecialist = false
            // if(userDoc.data().role === 'staff') {
            //     if(userDoc.data().permissions.includes('data specialist')) {
            //         this.isDataSpecialist = true
            //     }
            // }
            

            if(userDoc.data().role === 'admin') {
                this.adminOrManageUsers = true
            } else if(userDoc.data().role === 'staff') {
                this.staffOrClinician = true
                if(userDoc.data().permissions.includes('manage users')) {
                    this.adminOrManageUsers = true
                } else if(userDoc.data().permissions.includes('data specialist')) {
                    this.dataSpecialist = true
                } else if(userDoc.data().permissions.includes('clinician viewer')) {
                    this.clinicianOrClinicianViewer = true
                } else {
                    this.clinicianOrClinicianViewer = true
                }
            } else if(userDoc.data().role === 'clinician' || userDoc.data().role === 'clinician-staff') {
                userDoc.data().role === 'clinician-staff' ? this.staffOrClinician = true : null
                // this.staffOrClinician = true
                this.clinicianUsers = true
            }

            if(userDoc.data().role === 'clinician-staff') {
                this.isClinicianStaff = true
                // db.collection('usuarios').doc(userDoc.data().dependency).get()
                // .then(doc => {
                    this.userData = userDoc.data()
                // })
            } else {
                this.userData = userDoc.data()
            }
        })

        db.collection('taxonomies').doc('client').get()
        .then(docTaxonomia => {
            this.optionsRace = docTaxonomia.data().race
            this.optionsHouseholdIncome = docTaxonomia.data().householdIncome
            this.optionsGamblingTypes = docTaxonomia.data().gamblingTypes
            this.optionsEmploymentStatus = docTaxonomia.data().employmentStatus
            this.optionsEducationLevels = docTaxonomia.data().educationLevels
            this.optionsReferralOptions = docTaxonomia.data().referralOptions
            this.optionsRecoverySupportServices = docTaxonomia.data().recoverySupportServices

            this.optionsPaymentArrangement = docTaxonomia.data().paymentArrangement && docTaxonomia.data().paymentArrangement.length ? docTaxonomia.data().paymentArrangement : []
            var AtomConditionsPArrangement = docTaxonomia.data().conditionsPArrangement && docTaxonomia.data().conditionsPArrangement.length ? docTaxonomia.data().conditionsPArrangement : []
            AtomConditionsPArrangement.forEach(ele => {
                if(ele.cond && ele.label) {
                    this.conditionsPArrangement.push(ele)
                }
            })
        })

        db.collection('taxonomies').doc('clinician').get()
        .then(docTaxonomia => {
            // profile fields
            this.optionsRegionalCFEReceivingTheCall = docTaxonomia.data().region
        })
        // console.log(this.idClientActivity)
        
        db.collection('clientsActivity').doc(this.idClientActivity).get()
        .then(dataClient => {
            db.collection('usuarios').get()
            .then(docsClinicians => {
                // db.collection('clients').get()
                // .then(docsClients => {
                    // get clinician
                    var objClinician = []
                    var concatClinician = ''
                    // console.log(dataClient.data().clinician)
                    if(dataClient.data().clinician.length >= 1) {
                        dataClient.data().clinician.forEach(activityClinician => {
                            docsClinicians.forEach(item => {
                                if(activityClinician === item.id) {
                                    concatClinician = concatClinician.concat(' - ', item.id)
                                    var obj = item.data()
                                    obj.agencyAndName = item.data().firstName + ' ' + item.data().lastName + ' · ' + item.data().agency  + ' (#' + item.data().codigo + ')'
                                    objClinician.push(obj)
                                }
                            })
                        })
                    }
                    // console.log('second')

                    // get client
                    var objClient = dataClient.data().client
                    // var objClient = dataClient.data().client
                        // console.log(dataClient.data().client)
                    // if(dataClient.data().client) {
                    //     docsClients.forEach(item => {
                    //         if(dataClient.data().client === item.id) {
                    //             objClient = item.data()
                    //         }
                    //     })
                    // }

                    // get data
                    this.clinician = objClinician
                    this.dateOfContact = dataClient.data().dateOfContact
                    this.methodOfContact = dataClient.data().methodOfContact
                    this.contactStatus = dataClient.data().contactStatus
                    this.referredBy = dataClient.data().referredBy
                    this.familyMemberSelf = dataClient.data().familyMemberSelf ? dataClient.data().familyMemberSelf : ''
                    this.problemGamblingSelfHelpGroupMeetings = dataClient.data().problemGamblingSelfHelpGroupMeetings ? dataClient.data().problemGamblingSelfHelpGroupMeetings : ''
                    this.problemGamblingTreatmentServices = dataClient.data().problemGamblingTreatmentServices ? dataClient.data().problemGamblingTreatmentServices : ''
                    this.substanceUseTreatmentServices = dataClient.data().substanceUseTreatmentServices ? dataClient.data().substanceUseTreatmentServices : ''
                    this.recoverySupportServices = dataClient.data().recoverySupportServices ? dataClient.data().recoverySupportServices : ''
                    this.otherReferredBy = dataClient.data().otherReferredBy
                    this.warmTransfer = dataClient.data().warmTransfer ? dataClient.data().warmTransfer : ''
                    this.initialAppointmentDate = dataClient.data().initialAppointmentDate
                    this.time = dataClient.data().time
                    this.wasTheCallHelpful = dataClient.data().wasTheCallHelpful
                    this.wasTheCallHelpfulIfNotWhy = dataClient.data().wasTheCallHelpfulIfNotWhy ? dataClient.data().wasTheCallHelpfulIfNotWhy : ''
                    this.informationKitAccepted = dataClient.data().informationKitAccepted
                    this.informationKitOption = dataClient.data().informationKitOption ? dataClient.data().informationKitOption : ''
                    this.regionalCFEReceivingTheCall = dataClient.data().regionalCFEReceivingTheCall
                    this.borough = dataClient.data().borough ? dataClient.data().borough : ''
                    this.readmission = dataClient.data().readmission
                    this.race = dataClient.data().race ? dataClient.data().race : ''
                    this.householdIncome = dataClient.data().householdIncome ? dataClient.data().householdIncome : ''
                    this.gamblingTypes = dataClient.data().gamblingTypes ? dataClient.data().gamblingTypes : ''
                    this.employmentStatus = dataClient.data().employmentStatus ? dataClient.data().employmentStatus : ''
                    this.educationLevels = dataClient.data().educationLevels ? dataClient.data().educationLevels : ''
                    this.ethnicity = dataClient.data().ethnicity ? dataClient.data().ethnicity : ''
                    this.veteran = dataClient.data().veteran ? dataClient.data().veteran : ''
                    this.zipCode = dataClient.data().zipCode ? dataClient.data().zipCode : ''
                    this.industryOfEmployment = dataClient.data().industryOfEmployment ? dataClient.data().industryOfEmployment : ''
                    this.maritalStatus = dataClient.data().maritalStatus ? dataClient.data().maritalStatus : ''
                    this.religiousPreference = dataClient.data().religiousPreference ? dataClient.data().religiousPreference : ''
                    this.connectedToPeerAdvocate = dataClient.data().connectedToPeerAdvocate ? dataClient.data().connectedToPeerAdvocate : ''
                    this.connectedToVSE = dataClient.data().connectedToVSE ? dataClient.data().connectedToVSE : ''
                    this.connectedToPeerEducationGroup = dataClient.data().connectedToPeerEducationGroup ? dataClient.data().connectedToPeerEducationGroup : ''
                    this.connectedToFamilyEducationGroup = dataClient.data().connectedToFamilyEducationGroup ? dataClient.data().connectedToFamilyEducationGroup : ''
                    this.connectedToAsianServicesProgram = dataClient.data().connectedToAsianServicesProgram ? dataClient.data().connectedToAsianServicesProgram : ''
                    // enter adminission/discharge information
                    this.enterAdmissionDischargeInformation = dataClient.data().enterAdmissionDischargeInformation
                    this.enterAdmissionInformation = dataClient.data().enterAdmissionInformation === undefined ? dataClient.data().enterAdmissionDischargeInformation : dataClient.data().enterAdmissionInformation ? dataClient.data().enterAdmissionInformation : false
                    this.enterDischargeInformation = dataClient.data().enterDischargeInformation === undefined ? dataClient.data().enterAdmissionDischargeInformation : dataClient.data().enterDischargeInformation ? dataClient.data().enterDischargeInformation : false
                    this.enterSurveyInformation = dataClient.data().enterSurveyInformation ? dataClient.data().enterSurveyInformation : false
                    // admission form
                    this.dateOfAdmission = dataClient.data().dateOfAdmission
                    this.newOrReturningClient = dataClient.data().newOrReturningClient
                    this.clientStatusAdmission = dataClient.data().clientStatusAdmission ? dataClient.data().clientStatusAdmission : ''
                    this.presentingProblemsAtAdmission = dataClient.data().presentingProblemsAtAdmission
                    this.diagnosticCriteriaMetAtAdmission = dataClient.data().diagnosticCriteriaMetAtAdmission
                    this.otherProblemsAdmission = dataClient.data().otherProblemsAdmission
                    this.clientInvolvedWithLegalSystem = dataClient.data().clientInvolvedWithLegalSystem
                    this.clientReleaseSigned = dataClient.data().clientReleaseSigned
                    this.referredByAdmission = dataClient.data().referredByAdmission ? dataClient.data().referredByAdmission : ''
                    this.familyMemberSelfAdmission = dataClient.data().familyMemberSelfAdmission ? dataClient.data().familyMemberSelfAdmission : ''
                    this.problemGamblingTreatmentServicesAdmission = dataClient.data().problemGamblingTreatmentServicesAdmission ? dataClient.data().problemGamblingTreatmentServicesAdmission : ''
                    this.problemGamblingSelfHelpGroupMeetingsAdmission = dataClient.data().problemGamblingSelfHelpGroupMeetingsAdmission ? dataClient.data().problemGamblingSelfHelpGroupMeetingsAdmission : ''
                    this.substanceUseTreatmentServicesAdmission = dataClient.data().substanceUseTreatmentServicesAdmission ? dataClient.data().substanceUseTreatmentServicesAdmission : ''
                    this.recoverySupportServicesAdmission = dataClient.data().recoverySupportServicesAdmission ? dataClient.data().recoverySupportServicesAdmission : ''
                    this.otherReferredByAdmission = dataClient.data().otherReferredByAdmission ? dataClient.data().otherReferredByAdmission : ''
                    this.warmTransferAdmission = dataClient.data().warmTransferAdmission ? dataClient.data().warmTransferAdmission : ''
                    this.primaryICD10 = dataClient.data().primaryICD10 ? dataClient.data().primaryICD10 : ''
                    this.Z72GamblingAndBetting = dataClient.data().Z72GamblingAndBetting ? dataClient.data().Z72GamblingAndBetting : ''
                    this.numberofAssessmentVisits = dataClient.data().numberofAssessmentVisits ? dataClient.data().numberofAssessmentVisits : ''
                    this.age = dataClient.data().age ? dataClient.data().age : ''
                    this.sexualOrientation = dataClient.data().sexualOrientation ? dataClient.data().sexualOrientation : ''
                    this.genderIdentity = dataClient.data().genderIdentity ? dataClient.data().genderIdentity : ''
                    this.transgender = dataClient.data().transgender ? dataClient.data().transgender : ''
                    this.asianOrigin = dataClient.data().asianOrigin ? dataClient.data().asianOrigin : ''
                    this.pacificIslanderOrigin = dataClient.data().pacificIslanderOrigin ? dataClient.data().pacificIslanderOrigin : ''
                    this.developmentalDisability = dataClient.data().developmentalDisability ? dataClient.data().developmentalDisability : ''
                    this.coexistingPsychiatricDisorder = dataClient.data().coexistingPsychiatricDisorder ? dataClient.data().coexistingPsychiatricDisorder : ''
                    this.treatedForMentalIllness = dataClient.data().treatedForMentalIllness ? dataClient.data().treatedForMentalIllness : ''
                    this.hospitalizedForMentalIllness = dataClient.data().hospitalizedForMentalIllness ? dataClient.data().hospitalizedForMentalIllness : ''
                    this.hospitalized30OrMoreDaysForMentalIllness = dataClient.data().hospitalized30OrMoreDaysForMentalIllness ? dataClient.data().hospitalized30OrMoreDaysForMentalIllness : ''
                    this.smokedTobaccoInThePastWeek = dataClient.data().smokedTobaccoInThePastWeek ? dataClient.data().smokedTobaccoInThePastWeek : ''
                    this.usedSmokelessTobaccoInThePastWeek = dataClient.data().usedSmokelessTobaccoInThePastWeek ? dataClient.data().usedSmokelessTobaccoInThePastWeek : ''
                    this.substancesUsed = dataClient.data().substancesUsed ? dataClient.data().substancesUsed : ''
                    this.substanceUseRouteOfAdministration = dataClient.data().substanceUseRouteOfAdministration ? dataClient.data().substanceUseRouteOfAdministration : ''
                    this.frequencyOfSubstanceUse = dataClient.data().frequencyOfSubstanceUse ? dataClient.data().frequencyOfSubstanceUse : ''
                    this.ageOfFirstSubstanceUse = dataClient.data().ageOfFirstSubstanceUse ? dataClient.data().ageOfFirstSubstanceUse : ''
                    this.inThePastFewMonthsDrinks = dataClient.data().inThePastFewMonthsDrinks ? dataClient.data().inThePastFewMonthsDrinks : ''
                    this.referralOptions = dataClient.data().referralOptions ? dataClient.data().referralOptions : ''
                    this.OASASProgram = dataClient.data().OASASProgram ? dataClient.data().OASASProgram : ''
                    this.OASASProgramOptInpatient = dataClient.data().OASASProgramOptInpatient ? dataClient.data().OASASProgramOptInpatient : ''
                    this.OASASProgramOptResidential = dataClient.data().OASASProgramOptResidential ? dataClient.data().OASASProgramOptResidential : ''
                    this.OASASProgramOptOutpatient = dataClient.data().OASASProgramOptOutpatient ? dataClient.data().OASASProgramOptOutpatient : ''
                    this.privatePractitioner = dataClient.data().privatePractitioner ? dataClient.data().privatePractitioner : ''
                    this.recoverySupportServices = dataClient.data().recoverySupportServices ? dataClient.data().recoverySupportServices : ''
                    this.locadtrg = dataClient.data().locadtrg ? dataClient.data().locadtrg : ''
                    this.LOCADTRAssessmentID = dataClient.data().LOCADTRAssessmentID ? dataClient.data().LOCADTRAssessmentID : ''
                    this.dateOfBirthAdmission = dataClient.data().dateOfBirthAdmission ? dataClient.data().dateOfBirthAdmission : ''
                    this.sexAtBirth = dataClient.data().sexAtBirth ? dataClient.data().sexAtBirth : ''
                    this.last4SSN = dataClient.data().last4SSN ? dataClient.data().last4SSN : ''
                    this.firstTwoLettersLastName = dataClient.data().firstTwoLettersLastName ? dataClient.data().firstTwoLettersLastName : ''
                    this.frequencyofGambling = dataClient.data().frequencyofGambling ? dataClient.data().frequencyofGambling : ''
                    this.ageFirstGambled = dataClient.data().ageFirstGambled ? dataClient.data().ageFirstGambled : ''
                    this.past30DaysAmountOfMoney = dataClient.data().past30DaysAmountOfMoney ? dataClient.data().past30DaysAmountOfMoney : ''
                    this.past30DaysHowMuchTime = dataClient.data().past30DaysHowMuchTime ? dataClient.data().past30DaysHowMuchTime : ''
                    this.past30DaysHowManyDays = dataClient.data().past30DaysHowManyDays ? dataClient.data().past30DaysHowManyDays : ''
                    this.gamblinglocationspast12months = dataClient.data().gamblinglocationspast12months ? dataClient.data().gamblinglocationspast12months : ''
                    this.hasClientAttendedReceivedServices = dataClient.data().hasClientAttendedReceivedServices ? dataClient.data().hasClientAttendedReceivedServices : ''
                    this.legalSystem = dataClient.data().legalSystem ? dataClient.data().legalSystem : ''
                    this.notesAdmission = dataClient.data().notesAdmission
                    // discharge form
                    this.dateOfDischarge = dataClient.data().dateOfDischarge
                    this.dischargeStatus = dataClient.data().dischargeStatus
                    this.plannedDischargeReasons = dataClient.data().plannedDischargeReasons
                    this.unplannedDischargeReason = dataClient.data().unplannedDischargeReason
                    this.numberOfSessionsAttended = dataClient.data().numberOfSessionsAttended
                    this.dischargeDisposition = dataClient.data().dischargeDisposition ? dataClient.data().dischargeDisposition : ''
                    this.referralDisposition = dataClient.data().referralDisposition ? dataClient.data().referralDisposition : ''
                    this.diagnosticCriteriaMetAtAdmissionWasReduced = dataClient.data().diagnosticCriteriaMetAtAdmissionWasReduced
                    this.diagnosticCriteriaMetAtDischarge = dataClient.data().diagnosticCriteriaMetAtDischarge ? dataClient.data().diagnosticCriteriaMetAtDischarge : ''
                    this.goalsMet = dataClient.data().goalsMet
                    this.currentGamblingComparedToAdmission = dataClient.data().currentGamblingComparedToAdmission
                    this.clientInvolvedWithSelfHelpAtDischarge = dataClient.data().clientInvolvedWithSelfHelpAtDischarge
                    this.clientReferredForContinuedServices = dataClient.data().clientReferredForContinuedServices
                    this.ifSoWhatServicesWereRecommended = dataClient.data().ifSoWhatServicesWereRecommended
                    this.relatedProblemsGambling = dataClient.data().relatedProblemsGambling ? dataClient.data().relatedProblemsGambling : ''
                    this.relatedProblemsDrugUse = dataClient.data().relatedProblemsDrugUse ? dataClient.data().relatedProblemsDrugUse : ''
                    this.relatedProblemsAlcoholUse = dataClient.data().relatedProblemsAlcoholUse ? dataClient.data().relatedProblemsAlcoholUse : ''
                    this.relatedProblemsFinancial = dataClient.data().relatedProblemsFinancial ? dataClient.data().relatedProblemsFinancial : ''
                    this.relatedProblemsLegal = dataClient.data().relatedProblemsLegal ? dataClient.data().relatedProblemsLegal : ''
                    this.relatedProblemsVocationalEducational = dataClient.data().relatedProblemsVocationalEducational ? dataClient.data().relatedProblemsVocationalEducational : ''
                    this.relatedProblemsMedicalHealth = dataClient.data().relatedProblemsMedicalHealth ? dataClient.data().relatedProblemsMedicalHealth : ''
                    this.relatedProblemsLethalitySelf = dataClient.data().relatedProblemsLethalitySelf ? dataClient.data().relatedProblemsLethalitySelf : ''
                    this.relatedProblemsLethalityOther = dataClient.data().relatedProblemsLethalityOther ? dataClient.data().relatedProblemsLethalityOther : ''
                    this.relatedProblemsMentalHealth = dataClient.data().relatedProblemsMentalHealth ? dataClient.data().relatedProblemsMentalHealth : ''
                    this.relatedProblemsFamily = dataClient.data().relatedProblemsFamily ? dataClient.data().relatedProblemsFamily : ''
                    this.notesDischarge = dataClient.data().notesDischarge
                    // survey
                    this.isClientInsured = dataClient.data().isClientInsured ? dataClient.data().isClientInsured : ''
                    this.isClientMatchedToaClinicianWhoAcceptsTheirInsurance = dataClient.data().isClientMatchedToaClinicianWhoAcceptsTheirInsurance ? dataClient.data().isClientMatchedToaClinicianWhoAcceptsTheirInsurance : ''
                    this.isTheClinicianBillingTheClientsInsurance = dataClient.data().isTheClinicianBillingTheClientsInsurance ? dataClient.data().isTheClinicianBillingTheClientsInsurance : ''
                    this.whyInsuranceWasNotBilled = dataClient.data().whyInsuranceWasNotBilled ? dataClient.data().whyInsuranceWasNotBilled : ''
                    this.whyInsuranceWasNotBilledOther = dataClient.data().whyInsuranceWasNotBilledOther ? dataClient.data().whyInsuranceWasNotBilledOther : ''
                    this.reasonWhyClientWasReferredToClinician = dataClient.data().reasonWhyClientWasReferredToClinician ? dataClient.data().reasonWhyClientWasReferredToClinician : ''
                    this.reasonWhyClientWasReferredToClinicianOther = dataClient.data().reasonWhyClientWasReferredToClinicianOther ? dataClient.data().reasonWhyClientWasReferredToClinicianOther : ''
                    this.itemsFormRepeater = dataClient.data().paymentInsuranceUtilizationForm && dataClient.data().paymentInsuranceUtilizationForm.length ? dataClient.data().paymentInsuranceUtilizationForm : []
                    // content right
                    this.specialIssuesOrOtherComments = dataClient.data().specialIssuesOrOtherComments
                    this.callNotes = dataClient.data().callNotes
                    this.activityStatus = dataClient.data().activityStatus
                    this.closeNotes = dataClient.data().closeNotes
                    this.client = objClient

                    this.removeRecordAdmissionData = dataClient.data().removeRecordAdmissionData ? dataClient.data().removeRecordAdmissionData : false
                    this.removeRecordDischargeData = dataClient.data().removeRecordDischargeData ? dataClient.data().removeRecordDischargeData : false

                    if(this.dateOfAdmission && this.removeRecordDischargeData) {
                        db.collection('clientsActivity').doc(this.idClientActivity).update({
                            activityStatusLogical: 'Admitted'
                        })
                        .catch(error => console.log(error))

                        // update data client clientActivity
                        db.collection('clientsActivity').doc(this.idClientActivity).get()
                        .then(dataCA => {
                            var statusLogicOnHold = ''
                            if(dataCA.data().dateOfAdmission && dataCA.data().removeRecordDischargeData) {
                                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
                            } else if(dataCA.data().dateOfAdmission && (!dataCA.data().dateOfDischarge || dataCA.data().dateOfDischarge === 'N/A')) {
                                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
                            } else if(dataCA.data().dateOfDischarge && dataCA.data().dateOfDischarge !== 'N/A') {
                                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Discharged (On Hold)' : 'Discharged'
                            } else {
                                statusLogicOnHold = dataCA.data().activityStatus
                            }

                            db.collection('clients').doc(dataCA.data().client).update({
                                statusAct: 'Admitted',
                                statusLogicOnHold: statusLogicOnHold
                            })
                        })
                    } else if(this.dateOfAdmission && (!this.dateOfDischarge || this.dateOfDischarge === 'N/A')) {
                        db.collection('clientsActivity').doc(this.idClientActivity).update({
                            activityStatusLogical: 'Admitted'
                        })
                        .catch(error => console.log(error))

                        // update data client clientActivity
                        db.collection('clientsActivity').doc(this.idClientActivity).get()
                        .then(dataCA => {
                            var statusLogicOnHold = ''
                            if(dataCA.data().dateOfAdmission && dataCA.data().removeRecordDischargeData) {
                                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
                            } else if(dataCA.data().dateOfAdmission && (!dataCA.data().dateOfDischarge || dataCA.data().dateOfDischarge === 'N/A')) {
                                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
                            } else if(dataCA.data().dateOfDischarge && dataCA.data().dateOfDischarge !== 'N/A') {
                                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Discharged (On Hold)' : 'Discharged'
                            } else {
                                statusLogicOnHold = dataCA.data().activityStatus
                            }

                            // update statusAct client
                            db.collection('clients').doc(dataCA.data().client).update({
                                statusAct: 'Admitted',
                                statusLogicOnHold: statusLogicOnHold
                            })
                        })
                    } else if(this.dateOfDischarge && this.dateOfDischarge !== 'N/A') {
                        db.collection('clientsActivity').doc(this.idClientActivity).update({
                            activityStatusLogical: 'Discharged'  
                        })
                        .catch(error => console.log(error))

                        // update data client clientActivity
                        db.collection('clientsActivity').doc(this.idClientActivity).get()
                        .then(dataCA => {
                            var statusLogicOnHold = ''
                            if(dataCA.data().dateOfAdmission && dataCA.data().removeRecordDischargeData) {
                                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
                            } else if(dataCA.data().dateOfAdmission && (!dataCA.data().dateOfDischarge || dataCA.data().dateOfDischarge === 'N/A')) {
                                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
                            } else if(dataCA.data().dateOfDischarge && dataCA.data().dateOfDischarge !== 'N/A') {
                                statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Discharged (On Hold)' : 'Discharged'
                            } else {
                                statusLogicOnHold = dataCA.data().activityStatus
                            }
                            
                            db.collection('clients').doc(dataCA.data().client).update({
                                statusAct: 'Discharged',
                                statusLogicOnHold: statusLogicOnHold
                            })
                        })
                    }

                    this.json_data = [
                        {
                            'contactDate': this.dateOfContact,
                            'admissionDate': this.dateOfAdmission,
                            'dischargeDate': this.dateOfDischarge,
                            'clientId': dataClient.data().client,
                            'clinicianId': concatClinician ? concatClinician : '',
                            'clinicianEngagementId': '',
                            'callWasHelpful': this.wasTheCallHelpful,
                            'callWasNotHelpfulReason': this.wasTheCallHelpfulIfNotWhy,
                            'comments': this.specialIssuesOrOtherComments,
                            'dateCreated': '',
                            'eligibleForServices': '',
                            'externalReferenceLocation': '',
                            'informationKitAccepted': this.informationKitAccepted,
                            'informationKitOption': this.informationKitOption,
                            'initialAppointmentDate': this.initialAppointmentDate,
                            'notes': this.callNotes,
                            'referencedExternally': this.referredBy,
                            'familyMemberSelf': this.familyMemberSelf,
                            'problemGamblingSelfHelpGroupMeetings': this.problemGamblingSelfHelpGroupMeetings,
                            'problemGamblingTreatmentServices': this.problemGamblingTreatmentServices,
                            'substanceUseTreatmentServices': this.substanceUseTreatmentServices,
                            'recoverySupportServices': this.recoverySupportServices,
                            'referredByOther': this.otherReferredBy,
                            'warmTransfer': this.warmTransfer,
                            'clientStatusCloseNotes': this.closeNotes,
                            'readmission': this.readmission,
                            'clientStatusId': '',
                            'clientStatus': '',
                            'contactMethodId': this.methodOfContact,
                            'callerTypeId': '',
                            'referredByTypeId': '',
                            'regionId': '',
                        }
                    ]
                    this.loadDataPage = true
                // })
            })
        })

        db.collection('usuarios').where('role', 'in', ['clinician','clinician-staff']).get()
        .then(docsClinicians => {
            this.optionsClinician = []
            docsClinicians.forEach(dataClinician => {
                // db.collection('usuarios').doc(dataClinician.id).collection('details').doc('trackingDetail').get()
                // .then(docTracking => {
                //     var statusReferral = ''
                //     if(docTracking.data()) {
                //         if(docTracking.data().datePickers) {
                //             statusReferral = docTracking.data().datePickers[8].value ? 'active' : 'inactive'
                //         } else {
                //             statusReferral = 'inactive'
                //         }
                //     }

                    // if(dataClinician.data().status === 'active' && statusReferral === 'active') {
                        var obj = dataClinician.data()
                        obj.agencyAndName = dataClinician.data().firstName + ' ' + dataClinician.data().lastName + ' · ' + dataClinician.data().agency  + ' (#' + dataClinician.data().codigo + ')'
                        this.optionsClinician.push(obj)
                    // }
                    // console.log('first')
                // })
            })
        })
    },
    mounted() {
        if(window.location.pathname.includes('users/edit/clinician')) {
            this.clinicianOrClinicianViewer = true
            this.viewClinicianCLienActivity = true
        }
        // window.location.hash="client-activity";
        // window.location.hash="client-activity";
        // window.onhashchange = function() {
        //     window.location.hash="client-activity";
        //     goBack()
        // }

        // var goBack = () => {
            // history.pushState("", document.title, window.location.pathname + window.location.search);
            // console.log(this.statusEditClientActivity);
            // if(this.statusEditClientActivity) {
                // this.statusEditClientActivity = false
                // this.$emit('statusEditClientActivity',false)
            // }
        // }
        this.initTrHeight()

        if (window.history && window.history.pushState) {
            window.history.pushState('forward', null, '');

            window.addEventListener('popstate', () => {
                // alert('Back button was pressed.');
                this.statusEditClientActivity = false
                this.$emit('statusEditClientActivity',false)
            });
        }

        this.pushEditButton = false
        this.$emit('statusEditClientActivity',true)
    },
    destroyed() {
        window.removeEventListener('resize', this.initTrHeight)
    },
}
</script>

<style>
.cancelBtn-modal {
    border-radius: 13px !important;
}
.vs--disabled .vs__selected {
    color: #656b85 !important;
}
/* .btn-primary:hover:focus:active:visited {
    box-shadow: none !important;
}
.btn-primary:hover:focus:active:visited {
    box-shadow: none !important;
} */
.btn-primary:hover {
    box-shadow: none !important;
}
.btn-primary:focus {
    box-shadow: none !important;
}
.btn-primary:active {
    box-shadow: none !important;
}
.btn-primary:visited {
    box-shadow: none !important;
}
.select-utilization-form .vs__dropdown-menu {
    max-height: 185px !important;
    /* position: fixed;
    top: 50px !important;
    left: 50px !important;
    width: 200px !important;
    height: 400px !important;
    background-color: yellow !important;
    z-index: 9999 !important; */
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>