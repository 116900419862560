<template>
    <div v-if="allDataLoad">
        <h1 class="mb-1">All Client Activity</h1>
        <b-card>
            <div v-if="!statusEdit">
                <div class="mx-1">
                    <h4 style="font-size: 18px;" class="mb-0 pb-0 mt-1">Filters</h4>
                    <!-- Table Top -->
                    <b-row class="mt-1 d-flex align-items-center justify-content-start mb-1 mb-md-0" cols="1" cols-sm="12" cols-md="4" cols-lg="4">
                        <b-col class="mb-25">
                            <!-- <b-form-select
                                v-model="clientName"
                                inline
                                :options="optionsClientName"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="d-inline-block mr-50"
                            /> -->
                            <v-select
                                v-model="clientName"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                placeholder="Client Name"
                                label="value"
                                :dense="true"
                                :options="optionsClientName"
                            />
                        </b-col>
                        <b-col class="mb-25">
                            <!-- <b-form-select
                                v-model="clinicianName"
                                inline
                                :options="optionsClinicianName"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="d-inline-block mr-50"
                            /> -->
                            <v-select
                                v-model="clinicianName"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                placeholder="Clinician Name"
                                label="full"
                                :dense="true"
                                :options="optionsClinicianName"
                            />
                        </b-col>
                        <b-col class="mb-25">
                            <b-form-select
                                v-model="clinicianStatus"
                                inline
                                :options="optionsClinicianStatus"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="d-inline-block mr-50"
                            />
                        </b-col>
                        <b-col class="mb-25">
                            <b-form-select
                                v-model="clinicianRegion"
                                inline
                                :options="optionsClinicianRegion"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="d-inline-block mr-50"
                            />
                        </b-col>
                    </b-row> 
                    <b-row class="mt-1 d-flex align-items-center justify-content-start mb-1 mb-md-0" cols="1" cols-sm="12" cols-md="4" cols-lg="4">
                        <b-col class="mb-25" v-if="clinicianRegion === 'NYC'">
                            <b-form-select
                                v-model="clinicianBorough"
                                inline
                                :options="optionsBorough"
                                placeholder="Borough"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="d-inline-block mr-50"
                            />
                        </b-col>
                        <b-col class="mb-25">
                            <b-form-select
                                v-model="clientStatus"
                                inline
                                :options="optionsClientStatus"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="d-inline-block mr-50"
                            />
                        </b-col>
                        <b-col class="mb-25">
                            <b-form-input
                                v-model="clientID"
                                class="d-inline-block mr-50"
                                placeholder="Client ID"
                            />
                        </b-col>
                        <b-col class="mb-25">
                            <flat-pickr
                                v-model="startDate"
                                class="d-inline-block mr-50"
                                placeholder="Start Date"
                                :config="{ altInput: true, altFormat: 'm/d/Y', dateFormat: 'd/m/Y',}"
                            />
                        </b-col>
                        <b-col class="mb-25">
                            <flat-pickr
                                v-model="endDate"
                                class="d-inline-block mr-50"
                                placeholder="End Date"
                                :config="{ altInput: true, altFormat: 'm/d/Y', dateFormat: 'd/m/Y',}"
                            />
                        </b-col>
                        <b-col class="mb-25 mt-1">
                            <b-form-select
                                v-model="clinicianProgram"
                                inline
                                :options="optionsProgram"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="d-inline-block mr-50"
                            />
                        </b-col>
                    </b-row>

                    <b-row class="my-1">
                        <b-col
                            cols="12"
                            md="6"
                        >
                            <h2>{{totalRows.toLocaleString('en-US')}} <span style="font-size: 14px; color: #000;"> results found</span></h2>
                        </b-col>
                    </b-row>
                </div>
                <b-overlay
                    :show="loadChangesData"
                    rounded="sm"
                >
                    <b-table
                        :items="arrayFiltradoClientActivity"
                        :fields="fieldsTable"
                        responsive
                        :per-page="perPage"
                        :current-page="currentPage"
                        @row-clicked="onRowSelected"
                        hover
                        :busy="apiLoaded"
                    >
                        <template #table-busy>
                            <div class="text-center text-primary my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong class="ml-1">Loading...</strong>
                            </div>
                        </template>
                        <template #cell(clinicianName)="data">
                            <span>
                                {{ data.item.clinicianName ? data.item.clinicianName : '' }}
                            </span>
                        </template>
                        <template #cell(statusLogicOnHold)="data">
                            <span>
                                {{ data.item.statusLogicOnHold ? data.item.statusLogicOnHold : '' }}
                            </span>
                        </template>
                        <template #cell(contactDate)="data">
                            <span>
                                {{ data.item.contactDate ? data.item.contactDate : '' }}
                            </span>
                        </template>
                        <template #cell(admissionDate)="data">
                            <span>
                                {{ data.item.admissionDate ? data.item.admissionDate : '' }}
                            </span>
                        </template>
                        <template #cell(dischargeDate)="data">
                            <span>
                                {{ data.item.dischargeDate ? data.item.dischargeDate : '' }}
                            </span>
                        </template>
                    </b-table>
                </b-overlay>
            </div>

            <div v-else>
                <edit-client-activity :idClientActivity="idClientActivity" @statusEditClientActivity="statusEdit = $event" @pushEditButton="pushEditButton = $event" />
            </div>
        </b-card>

    </div>
</template>

<script>
import { 
    BForm, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BTable, BFormInvalidFeedback, BFormSelect, BCard, BAvatar, BCardBody,
    BPagination, BSpinner, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import EditClientActivity from '../user/users-edit/components/EditClientActivity.vue'
import flatPickr from 'vue-flatpickr-component'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { db } from '@/firebase'

export default {
    data() {
        return {
            // validation
            required,
            email,
            // totalRows: 0,
            idClientActivity: '',
            statusEdit: false,
            pushEditButton: false,
            statusEditClient: false,
            allDataLoad: true,
            clinicianProgram: null,
            optionsProgram: [],

            // filters
            startDate: null,
            endDate: null,
            region: '',
            language: '',
            progress: '',
            insurance: '',

            // options
            optionsRegion: [
                'Bronx',
                'Brooklyn',
                'Central',
                'Finger Lakes',
                'Long Island',
                'Manhattan',
                'Mid-Hudson',
                'Northeast',
                'Queens',
                'Staten Island',
                'Western'
            ],
            optionsLanguage: [],
            optionsClientProgress: [
                'Any Client Status',
                'Engaged',
                'Closed - Attended First Session',
                'Closed - Did not attend first session',
                'Closed – Received information',
                'Closed – Referred to OASAS program',
                'External Referral',
                'On Hold'
            ],
            optionsInsurance: [],

            // settings table and edit view
            // statusEdit: false,
            itemsActivity: [],
            fieldsTable: [
                { key: 'clientID', sortable: false },
                { key: 'clientName', sortable: false },
                { key: 'clinicianName', sortable: false },
                { key: 'statusLogicOnHold', label: 'status', sortable: false },
                { key: 'contactDate', sortable: false },
                { key: 'admissionDate', sortable: false },
                { key: 'dischargeDate', sortable: false },
                // { key: 'actions', sortable: false },
            ],
            currentPage: 1,
            perPage: 30,
            pageOptions: [5, 10, 25, 50, 100, 150],
            totalRows: 0,

            // get data from db
            optionsClient: [],
            optionsClinician: [],

            // filter
            clientActivityName: null,
            clientActivityStatus: null,

            // reports cards
            totalGroupSessions: 0,
            totalIndividualSessions: 0,
            totalRemoteSessions: 0,
            totalNonEnglishClients: 0,
            clientSatisfaction: 0,
            improved: 0,

            // filter
            clinicianName: null,
            optionsClinicianName: [],
            clientName: null,
            optionsClientName: [],
            clinicianRegion: null,
            optionsClinicianRegion: [],
            clinicianBorough: null,
            optionsBorough: [
                {value: null, text: 'Any Bourough'},
                {value: 'Bronx', text: 'Bronx'},
                {value: 'Brooklyn', text: 'Brooklyn'},
                {value: 'Manhattan', text: 'Manhattan'},
                {value: 'Queens', text: 'Queens'},
                {value: 'Staten Island', text: 'Staten Island'},
            ],
            claimStatus: null,
            optionsClaimStatus: [
                {value: null, text: 'Any claim status'},
                {value: 'paid', text: 'Paid'},
                {value: 'approved', text: 'Approved'},
                {value: 'denied', text: 'Denied'},
                {value: 'pending dm approval', text: 'Pending DM Approval'},
                {value: 'pending pm approval', text: 'Pending PM Approval'},
            ],
            clientStatus: null,
            optionsClientStatus: [
                {value: null, text: 'Any Client Status'},
                {value: 'discharged', text: 'Discharged'},
                {value: 'admitted', text: 'Admitted'},
                {value: 'engaged', text: 'Engaged'},
                {value: 'closed - attended first session', text: 'Closed – Attended First Session'},
                {value: 'closed - did not attend first session', text: 'Closed – Did not attend first session'},
                {value: 'closed – received information', text: 'Closed – Received information'},
                {value: 'closed – referred to oasas program', text: 'Closed – Referred to OASAS program'},
                {value: 'external referral', text: 'External Referral'},
                {value: 'on hold', text: 'On Hold'},
                // {value: 'other', text: 'Other'},
            ],
            clinicianStatus: null,
            optionsClinicianStatus: [
                {value: null, text: 'Any Clinician Status'},
                {value: 'active', text: 'Active Clinicians'},
                {value: 'inactive', text: 'Inactive Clinicians'},
            ],
            clientID: null,

            // overlay
            latestDocActivity: null,
            loadingInfiniteScroll: false,
            apiLoaded: true,
            firstLoad: false,
            loadChangesData: false,
        }
    },
    components: {
        BForm,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BTable,
        BFormInvalidFeedback,
        BFormSelect,
        BCard,
        BAvatar,
        BCardBody,
        BPagination,
        BSpinner,
        BOverlay,
        
        // select
        vSelect,

        // date
        flatPickr,

        // Form Validation
        ValidationProvider,
        ValidationObserver,

        EditClientActivity,
    },
    directives: {
        Ripple,
    },
    computed: {
        ...mapGetters(['arrayFiltradoClientActivity']),
    },
    props: ['changeStatusEditClient'],
    watch: {
        clinicianName(val) {
            this.perPage = 30
            val ? this.perPage += 20 : this.perPage = 30
            this.apiLoaded = true
            if(val) {
                if(val.value === 'All Clinicians') {
                    this.clinicianName = null
                    this.apiLoaded = false
                } else {
                    this.searchClientActivityClinicianName(val.value)
                }
            } else {
                this.apiLoaded = false
                this.searchClientActivityClinicianName('')
            }
            // this.searchClientActivityClinicianName(val)
        },
        clientName(val) {
            this.perPage = 30
            val ? this.perPage += 20 : this.perPage = 30
            this.apiLoaded = true
            // if(val) {
            //     // console.log(val.value)
            //     this.searchClientActivityName(val.value)
            // } else {
            //     this.searchClientActivityName('')
            // }
            if(val) {
                if(val.value === 'All Clients') {
                    this.clientName = null
                    this.apiLoaded = false
                } else {
                    this.searchClientActivityName(val.value)
                }
            } else {
                this.apiLoaded = false
                this.searchClientActivityName('')
            }
            // this.searchClientActivityName(val)
        },
        clientStatus(val) {
            this.perPage = 30
            val ? this.perPage += 20 : this.perPage = 30
            this.apiLoaded = true
            // console.log(val)
            this.searchClientActivityStatus(val)
        },
        clinicianRegion(val) {
            this.perPage = 30
            val ? this.perPage += 20 : this.perPage = 30
            // val !== 'NYC' ? this.apiLoaded = true : ''
            this.apiLoaded = true
            // console.log(val)
            // val !== 'NYC' ? this.searchClientActivityClinicianRegion(val) : ''
            this.searchClientActivityClinicianRegion(val)
        },
        clinicianBorough(val) {
            this.perPage = 30
            val ? this.perPage += 20 : this.perPage = 30
            this.apiLoaded = true
            // console.log(val)
            if(val) {
                this.searchClientActivityClinicianRegion(val)
            } else {
                if(this.clinicianRegion) {
                    this.searchClientActivityClinicianRegion(this.clinicianRegion)
                }
            }
        },
        clinicianStatus(val) {
            this.perPage = 30
            val ? this.perPage += 20 : this.perPage = 30
            this.apiLoaded = true
            // console.log(val)
            this.searchClientActivityClinicianStatus(val)
        },
        clientID(val) {
            this.perPage = 30
            val ? this.perPage += 20 : this.perPage = 30
            this.apiLoaded = true
            // console.log(val)
            val ? this.searchClientActivityClientID(val.includes('#') ? val : '#'+val) : this.searchClientActivityClientID('')
            // val.includes('#') ? this.searchClientActivityClientID(val) : this.searchClientActivityClientID('#'+val)
        },
        startDate(val) {
            this.perPage = 30
            val ? this.perPage += 20 : this.perPage = 30
            this.apiLoaded = true
            // console.log(val)
            this.searchClientActivityStartDate(val)
        },
        endDate(val) {
            this.perPage = 30
            val ? this.perPage += 20 : this.perPage = 30
            this.apiLoaded = true
            // console.log(val)
            this.searchClientActivityEndDate(val)
        },
        clinicianProgram(val) {
            this.perPage = 30
            val ? this.perPage += 20 : this.perPage = 30
            this.apiLoaded = true
            // console.log(val)
            this.searchClientActivityClinicianProgram(val)
        },
        arrayFiltradoClientActivity(val) {
            this.perPage = 30
            val ? this.perPage += 20 : this.perPage = 30
            this.apiLoaded = false
            this.totalRows = val.length
            if(parseInt(val.length, 10) > 2994) {
                db.collection('clientsActivity').doc('counter').update({
                    active: val.length
                })
            }
        },
        statusEdit(val) {
            this.perPage = 30
            val ? this.perPage += 20 : this.perPage = 30
            if(val) {
                this.statusEditClient = true
                this.$emit('statusEditClient',true)
                this.pushEditButton = false
                this.$emit('pushEditButton',false)
            } else {
                this.statusEditClient = false
                this.$emit('statusEditClient',false)
            }
        },
        pushEditButton(val) {
            // console.log(val)
            if(val) {
                if(this.firstLoad) {
                    this.loadChangesData = true
                    setTimeout(() => {
                        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/active/clientsActivity')
                        .then(res => {
                            // console.log(res.data)
                            this.apiLoaded = true
                            this.loadChangesData = false
                            this.pushEditButton = false
                            this.setSnapshotClientActivity(res.data)
                            // window.removeEventListener('scroll', this.handleScroll)
                        })
                    }, 1000)
                }
            }
        },
        changeStatusEditClient(val) {
            this.perPage = 30
            val ? this.perPage += 20 : this.perPage = 30
            if(val) {
                // if(this.statusCreate) {
                    // this.statusCreate = true
                // } else {
                    this.statusEditClient = true
                    this.statusEdit = true
                    this.$emit('statusEditClient',true)
                // }
            } else {
                this.statusEditClient = false
                this.statusEdit = false
                // this.statusCreate = false
                this.$emit('statusEditClient',false)
            }
        },
        perPage(val) {
            if(this.activeFilters) this.loadingInfiniteScroll = false
        },
    },
    methods: {
        ...mapActions(['searchClientActivityName','searchClientActivityStatus','searchClientActivityClinicianName','searchClientActivityClinicianRegion','searchClientActivityClinicianStatus','searchClientActivityClientID','searchClientActivityStartDate','searchClientActivityEndDate','searchClientActivityClinicianProgram','setSnapshotClientActivity']),
        onRowSelected(items) {
            // console.log(items.id)
            this.idClientActivity = items.id
            this.statusEdit = true
        },
        async getActivityLocal() {
            // const ref = db.collection('clients').where('status', '>=', 'active').orderBy('status').startAfter(this.latestDocActivity || 0).limit(25)
            // const data = await ref.get()
            // data.docs.forEach(doc => {
            //     // itemClients.forEach(doc => {
            //     this.optionsClientName.push({value: doc.data().firstName + ' ' + doc.data().lastName, text: doc.data().firstName + ' ' + doc.data().lastName})
            // })
            this.allDataLoad = true
            const ref = db.collection('clientsActivity').orderBy('dateOfContact').startAfter(this.latestDocActivity || 0).limit(40)
            const data = await ref.get()
            // .onSnapshot(docsClients => {
                
                // this.itemsActivity = []
                // db.collection('usuarios').get()
                // .then(docsUsers => {
                    data.docs.forEach(dataClientActivity => {
                        // console.log(dataClientActivity.id)
                        var clinicianName = ''
                        var clinicianStatus = ''
                        var clinicianNameConcat = ''
                        var clinicianStatusStatus = ''
                        var arrayClinicianName = []
                        var arrayClinicianStatus = []
                        // docsUsers.forEach(docUser => {
                        //     if(dataClientActivity.data().clinician.includes(docUser.id)) {
                        //         clinicianName = docUser.data().nombre
                        //         clinicianStatus = docUser.data().status ? docUser.data().status : 'inactive'
                        //         clinicianNameConcat = clinicianNameConcat.concat(clinicianName, ', ')
                        //         clinicianStatusStatus = clinicianStatusStatus.concat(clinicianStatus, ', ')
                        //         arrayClinicianName.push(clinicianName.toLowerCase())
                        //         arrayClinicianStatus.push(clinicianStatus.toLowerCase())
                        //     }
                        // })
                        // clinicianNameConcat = clinicianNameConcat.replace(/,\s*$/, "")
                        // clinicianStatusStatus = clinicianStatusStatus.replace(/,\s*$/, "")

                        // db.collection('clients').doc(dataClientActivity.data().client).get()
                        // .then(itemClient => {
                        var statusLogical = ''
                        var statusLogicOnHold = ''
                        // if(dataClientActivity.data().activityStatus !== 'On Hold') {
                        if(dataClientActivity.data().activityStatus) {
                            if(dataClientActivity.data().dateOfAdmission && dataClientActivity.data().removeRecordDischargeData) {
                                statusLogical = 'Admitted'
                                statusLogicOnHold = dataClientActivity.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
                            } else if(dataClientActivity.data().dateOfAdmission && (!dataClientActivity.data().dateOfDischarge || dataClientActivity.data().dateOfDischarge === 'N/A')) {
                                statusLogical = 'Admitted'
                                statusLogicOnHold = dataClientActivity.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
                            } else if(dataClientActivity.data().dateOfDischarge && dataClientActivity.data().dateOfDischarge !== 'N/A') {
                                statusLogical = 'Discharged'
                                statusLogicOnHold = dataClientActivity.data().activityStatus === 'On Hold' ? 'Discharged (On Hold)' : 'Discharged'
                            } else {
                                statusLogical = dataClientActivity.data().activityStatus
                                statusLogicOnHold = dataClientActivity.data().activityStatus
                            }
                        } else {
                            statusLogical = dataClientActivity.data().activityStatus
                            statusLogicOnHold = dataClientActivity.data().activityStatus
                        }

                        var obj = {
                            id: dataClientActivity.id,
                            status: statusLogical,
                            statusLogical: dataClientActivity.data().activityStatusLogical ? dataClientActivity.data().activityStatusLogical : '',
                            statusLogicOnHold: statusLogicOnHold,
                            contactDate: dataClientActivity.data().dateOfContact,
                            admissionDate: dataClientActivity.data().dateOfAdmission ? dataClientActivity.data().dateOfAdmission : '',
                            dischargeDate: dataClientActivity.data().dateOfDischarge ? dataClientActivity.data().dateOfDischarge : '',
                            region: dataClientActivity.data().regionalCFEReceivingTheCall,
                            clinicianRegion: dataClientActivity.data().clinicianRegion ? dataClientActivity.data().clinicianRegion : [],

                            // clientID: '#' + itemClient.data().id,
                            // clientName: itemClient.data().firstName + ' ' + itemClient.data().lastName,
                            clientID: '#',
                            clientName: '',
                            clinicianName: clinicianNameConcat,
                            clinicianStatus: clinicianStatusStatus,
                            arrayClinicianName,
                            arrayClinicianStatus,
                        }
                        this.itemsActivity.push(obj)
                            // this.setSnapshotClientActivity([obj])
                        // })
                    })
                    this.allDataLoad = true
                    this.setSnapshotClientActivity(this.itemsActivity)
                // })
            // })

            this.latestDocActivity = data.docs[data.docs.length - 1]
            if(data.empty) {
                window.removeEventListener('scroll', this.handleScroll)
            }
            this.loadingInfiniteScroll = false
        },
        // handleScroll(event) {
        //     if(!this.loadingInfiniteScroll) {
        //         let triggerHeight = document.documentElement.scrollTop + window.pageYOffset
        //         if(triggerHeight >= document.documentElement.scrollHeight) {
        //             // console.log(1)
        //             // this.getActivityLocal()
        //             this.loadingInfiniteScroll = true
        //         }
        //     }
        // },
        handleScroll(event) {
            // console.log(document.documentElement.scrollTop+window.pageYOffset,document.documentElement.scrollHeight)
            if(!this.loadingInfiniteScroll) {
                let triggerHeight = document.documentElement.scrollTop + window.pageYOffset
                if(triggerHeight >= (document.documentElement.scrollHeight)-300) {
                    // if(!this.activeFilters) this.getUsers()
                    this.loadingInfiniteScroll = true
                    
                    this.perPage += 10
                    this.loadingInfiniteScroll = false
                }
            }
        },
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    mounted() {
        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/active/clientsActivity')
        .then(res => {
            // console.log(res.data)
            this.firstLoad = true
            this.apiLoaded = true
            this.setSnapshotClientActivity(res.data)
        })
        window.addEventListener('scroll', this.handleScroll)
    },
    created() {
        this.clinicianProgram = null
        this.loadChangesData = false
        this.pushEditButton = false

        this.itemsActivity = []
        db.collection('clientsActivity').doc('counter').get()
        .then(counter => {
            this.totalRows = counter.data().active
        })

        db.collection('taxonomies').doc('clinician').get()
        .then(docTaxonomia => {
            this.optionsClinicianRegion = [{value: null, text: 'Any Region'}]
            docTaxonomia.data().region.forEach(item => {
                this.optionsClinicianRegion.push({value: item, text: item})
            })

            var arrayProgram = [{value: null, text: 'Any Program'}]
            docTaxonomia.data().program.forEach(item => {
                arrayProgram.push({value: item, text: item})
            })
            this.optionsProgram = arrayProgram
        })

        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/filter/clinicians')
        .then(res => {
            this.optionsClinicianName = [{id: 0, uid: 0, value: 'All Clinicians', full: 'All Clinicians'}]
            res.data.forEach(ele => {
                this.optionsClinicianName.push(ele)
            })
        })
        

        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/filter/clients')
        .then(res => { 
            this.optionsClientName = [{id: 0, uid: 0, value: 'All Clients'}]
            res.data.forEach(ele => {
                this.optionsClientName.push(ele)
            })
            // console.log(res.data)
        })

        this.searchClientActivityName('')
        this.searchClientActivityStatus('')

        this.searchClientActivityClinicianName('')
        this.searchClientActivityClinicianRegion('')
        this.searchClientActivityClinicianStatus('')
        this.searchClientActivityClientID('')
        this.searchClientActivityStartDate('')
        this.searchClientActivityEndDate('')
        this.searchClientActivityClinicianProgram('')
    }
}
</script>

<style>
.form-control {
    background: #fff !important;
    background-color: #fff !important;
}
.vs__dropdown-option {
  white-space: normal !important;
}
.vs__dropdown-toggle {
  max-height: 38px;
}
.vs__selected-options {
  overflow: auto;
}
.vs__selected-options {
    overflow-x: hidden;
    overflow-y: hidden;
}
/* ::placeholder {
  color: #000 !important;
  opacity: 1;
}

:-ms-input-placeholder {
 color: #000 !important;
}

::-ms-input-placeholder {
 color: #000 !important;
} */
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>